@use "sass:math";

[data-am-sidenavigation] {
  .side-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-top: $gutter-half;
    padding-bottom: $gutter-half;
  }

  .nav-item {
    &.is-active {
      > .nav-link {
        @include font-medium;
        color: var(--c-text-main);
      }
    }

    .side-nav {
      position: relative;
      padding-top: $gutter-half;
      padding-bottom: $gutter-half;

      &:before {
        content: '';
        position: absolute;
        top: $gutter-half + math.div($gutter-half, 2);
        bottom: $gutter-half + math.div($gutter-half, 2);
        left: 0;
        width: 1px;
        background-color: var(--c-border-main);
      }

      .nav-item {
        &.is-active {
          > .nav-link {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              top: math.div($gutter-half, 2);
              bottom: math.div($gutter-half, 2);
              left: 0;
              width: 2px;
              background-color: $c-primary;
            }

            &:hover {
              color: var(--c-text-main);
            }
          }
        }
      }

      .nav-link {
        padding-left: $gutter;
        color: var(--c-text-main);
        font-size: 0.875em;

        &:hover {
          color: $c-primary;
        }
      }
    }
  }

  .nav-link {
    display: block;
    padding-top: $gutter-half;
    padding-bottom: $gutter-half;
    color: var(--c-text-main);
    transition: color 200ms ease;

    &:hover {
      color: $c-primary;
    }
  }
}
