[data-wx-inputsearch] {
  .input-search-foldout-main {
    border-radius: 0;
    padding: 0 $grid-container-gutter-lt-md;
    box-shadow: $g-box-shadow;
  }

  .input-search-query-suggestion-action {
    padding-left: 0;
    padding-right: 0;

    &.is-tags {
      padding-bottom: 0;

      [data-am-tag~='overflow'] {
        margin-left: -($grid-container-gutter-lt-md);
        margin-right: -($grid-container-gutter-lt-md);

        .tag-container {
          padding-left: $grid-container-gutter-lt-md;
          padding-right: $grid-container-gutter-lt-md;
        }
      }
    }
  }

  .input-search-query-suggestion-heading {
    margin-left: -($grid-container-gutter-lt-md);
    margin-right: -($grid-container-gutter-lt-md);
    padding: gutter(0.5) $grid-container-gutter-lt-md;
    background-color: $c-accent;
    margin-top: 0;
    margin-bottom: 0;
  }

  .input-search-query-suggestion-list {
    padding-top: gutter(0.5);
    padding-bottom: gutter(0.5);
  }

  // Count

  .input-search-count {
    display: none;
  }
}
