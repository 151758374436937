[data-wx-infocard] {
  @include flex(1 1 auto);
  @include flex-display;
  @include flex-direction(column);
  position: relative;
  height: 100%;
  padding: gutter(1.5);
  background-color: var(--c-bg-main);
  border-radius: 8px;
  box-shadow: $g-x-box-shadow;

  .info-card-header {
    @include flex-shrink(0);
    @include flex-display;
    @include align-items(center);
  }

  .info-card-title {
    @include flex(1 1 auto);
    @include font-bold;
    font-size: 1.25rem;
    line-height: 1.2;
  }

  .info-card-logo {
    @include flex-shrink(0);
    padding-left: gutter(1);
  }

  .info-card-logo-img {
    display: block;
    width: 100%;
    max-width: 110px;
  }

  .info-card-header-content {
    @include flex(1 1 auto);
    text-align: center;
  }

  .info-card-body {
    @include flex(1 1 auto);
    @include flex-display;
    @include flex-direction(column);
    margin-top: gutter(1);

    &.align-center-bottom {
      @include flex-display;
      @include flex-direction(column);
      @include align-items(center);
      @include justify-content(flex-end);
    }
  }

  .info-card-description {
    @include flex(1 1 auto);
    position: relative;
    height: 145px;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: gutter(2);
      background-image: linear-gradient($c-fade-light-00, var(--c-bg-main));
      pointer-events: none;
    }
  }

  .info-card-link {
    @include flex-shrink(0);
    margin-top: auto;
    padding-top: gutter(1);
  }

  .info-card-icon {
    display: block;
    width: gutter(3.75);
  }
}
