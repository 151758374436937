[data-am-mapbutton] {
  .map-button {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 46.875%; // Static map proportions
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .map-button-text {
    @include transform(translate(-50%, -50%));
    @include font-medium;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: var(--c-bg-main);
    color: $c-action;
    padding: gutter(0.75) gutter(1);
    border-radius: 999px;
    box-shadow: $g-x-box-shadow;
    text-align: center;
    font-size: 0.875rem;
    }
}
