[data-wx-actiongroupcontainer] {
  @include flex-display;

  .action-group-container-alt {
    @include flex-display;
  }

  .action-group-container-alt-btn {
    @include flex(1 1 0);

    & + .action-group-container-alt-btn {
      padding-left: gutter(0.75);
    }
  }
}
