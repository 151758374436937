@use "sass:math";

[data-wx-button~='thin'] {
  $button-height-thin-lt-md: 40px;

  min-height: $button-height-thin-lt-md;
  padding: gutter(0.75) gutter(1);
  border-radius: math.div($button-height-thin-lt-md, 2);
}

[data-wx-button~='thin-wide'] {
  $button-height-thin-lt-md: 40px;

  min-height: $button-height-thin-lt-md;
  padding: gutter(0.75) gutter(2);
  border-radius: math.div($button-height-thin-lt-md, 2);
}