@use "sass:math";

[data-wx-filtermap] {
  @include flex-display;
  @include flex-direction(column);
  height: calc(100vh - 200px); // Magic number (filter-panel header height + footer-height + spacing)
  max-height: 600px;

  .filter-map-container {
    @include flex(1 0 auto);
    position: relative;
    background-color: $c-accent;
    overflow: hidden;

    &:before {
      $center-size: 12px;

      @include transform(translate(-50%, -50%));
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: $center-size;
      height: $center-size;
      border-radius: 50%;
      background-color: $c-action;
      border: 2px solid $c-text-on-action;
      pointer-events: none;
      z-index: 1;
    }

    &.is-placeholder {
      $placeholder-color-land: #dee3cc;
      $placeholder-color-water: #c5d7ed;

      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-image: linear-gradient(-45deg, $placeholder-color-land, $placeholder-color-water, $placeholder-color-land);

      &:before {
        content: none;
      }

      .filter-map,
      .filter-map-overlay {
        display: none;
      }
    }
  }

  .filter-map {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .filter-map-tooltip {
    @include transform(translateX(-50%));
    @include font-medium;
    @include unselectable;
    position: absolute;
    bottom: $gutter;
    left: 50%;
    max-width: 100%;
    padding: math.div($gutter-half, 2) $gutter-half;
    background-color: $c-complement;
    color: $c-text-on-complement;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.75rem;
    line-height: 1;
    pointer-events: none;
    border-radius: $g-border-radius;
  }

  .filter-map-overlay {
    @include transform(translate(-50%, -50%));
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: var(--c-primary-bg);
    opacity: 0.15;
    border: 1px solid $c-action;
    pointer-events: none;
  }

  // Tools

  .filter-map-tools {
    @include flex-display;
    @include align-items(center);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: gutter(1) gutter(1.5);
    padding-bottom: 0;
  }

  .filter-map-tools-search {
    @include flex(1 1 auto);
  }

  .filter-map-tools-location {
    @include flex-shrink(0);
    padding-left: gutter(0.5);
  }

  .filter-map-current-location {
    $current-location-size: 30px;

    @include transform-origin(50% 50%);
    display: block;
    width: $current-location-size;
    height: $current-location-size;
    border-radius: 50%;
    background-color: var(--c-bg-main);
    color: $c-primary;
    box-shadow: $g-box-shadow-big;
    -webkit-tap-highlight-color: $c-fade-dark-00;
    transition: transform 200ms ease;

    &:active {
      @include transform(scale(0.95));
    }
  }

  .filter-map-range {
    display: table;
    width: 100%;
    padding: gutter(1) gutter(1.5);
  }

  .filter-map-range-slider,
  .filter-map-range-value {
    display: table-cell;
    vertical-align: middle;
  }

  .filter-map-range-value {
    width: 100px;
    text-align: center;
    white-space: nowrap;
    padding-left: $gutter;
  }
}
