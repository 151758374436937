[data-wx-filterchecklist] {
  // Single

  .filter-check-list-option-single {
    // sass-lint:disable-block nesting-depth
    &:not(.is-checked) {
      &:hover {
        .filter-check-list-option-single-checkbox {
          @include transform(scale(1.3));
        }
      }
    }
  }

  .filter-check-list-option-single-checkbox {
    @include transform-origin(50% 50%);
    transition: transform 300ms cubic-bezier(.47, 1.64, 0.41, 0.8);
  }

  // Multi

  .filter-check-list-option-multi-btn {
    @include transform-origin(50% 50%);
    transition: transform 300ms cubic-bezier(.47, 1.64, 0.41, 0.8);

    &:hover {
      @include transform(scale(1.2));
    }
  }

  // Extend

  .filter-check-list-extend-btn {
    &:hover {
      text-decoration: underline;
    }
  }
}
