[data-wx-actionbutton] {
  transition: box-shadow 200ms ease, transform 200ms ease;

  &:hover {
    box-shadow: $g-box-shadow-big-hover;
  }
}

[data-wx-actionbutton~='huge'] {
  $action-button-size-huge: 80px;

  width: $action-button-size-huge;
  height: $action-button-size-huge;
  font-size: 1.75rem;
}
