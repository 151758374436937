[data-wx-inlinebutton] {
  &:hover {
    .inline-button-text {
      color: $c-action;
    }
  }

  .inline-button-text {
    font-size: 0.875rem;
  }
}
