[data-wx-conversationpreview] {
  display: block;
  width: 100%;
  padding: gutter(1);
  background: var(--c-bg-main);
  box-shadow: 0px 8px 10px 1px rgba(0, 0, 0, 0.03),
  0px 2px 14px 1px rgba(0, 0, 0, 0.03), 0px 2px 5px rgba(0, 0, 0, 0.09);
  text-align: left;

  .conversation-preview-icon {
    @include flex-shrink(0);
    color: $c-action;
    padding-right: gutter(0.5);
  }

  .conversation-preview-content {
    @include flex-display;
  }
}
