@use "sass:math";

[data-am-content] {
  $content-line-height: 1.5;
  $content-gutter: 1em;

  font-size: var(--font-size-regular);
  line-height: $content-line-height;
  word-break: break-word;
  text-wrap: pretty;

  > * {
    margin-bottom: $content-gutter;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: $content-gutter * 1.5;
    margin-bottom: $content-gutter;
    line-height: 1.2;
  }

  h1 {
    font-size: var(--font-size-h1);
    line-height: var(--line-height-h1);
  }

  h2 {
    font-size: var(--font-size-h2);
    line-height: var(--line-height-h2);
  }

  h3 {
    font-size: var(--font-size-h3);
    line-height: var(--line-height-h3);
  }

  h4 {
    font-size: var(--font-size-h4);
    line-height: var(--line-height-h4);
  }

  h5 {
    font-size: var(--font-size-h5);
    line-height: var(--line-height-h5);
  }

  h6 {
    font-size: var(--font-size-h6);
    line-height: var(--line-height-h6);
  }

  p {
    margin-block: $content-gutter;
  }

  a {
    @include font-regular;
    color: $c-primary;

    &:hover {
        text-decoration: underline;
    }
  }

  > img {
    display: block;
    max-width: 100%;
    margin-bottom: $content-gutter;

    + .caption {
      margin-top: calc((#{$content-gutter} - #{$gutter-half}) * -1);
    }
  }

  img {
    display: block;
    max-width: 100%;
  }

  blockquote {
    @include font-regular;
    position: relative;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-left: $gutter;
    font-size: 1.125rem;
    font-style: italic;
    line-height: 1.4;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 2px;
      background-color: $c-action;
    }
  }

  iframe {
    display: block;
    max-width: 100%;
  }

  .caption {
    color: var(--c-text-muted);
  }

  > p {
    > img {
      display: block;
      max-width: 100%;
      margin-bottom: $content-gutter;
    }
  }

  ul,
  ol {
    margin-top: $content-gutter;
    margin-bottom: $content-gutter;
    padding-left: $content-gutter * 2;
  }

  ol {
    ::marker {
        font-weight: var(--font-weight-medium);
    }
  }

  li {
    margin-bottom: math.div($content-gutter, 2);

    ul,
    ol {
      margin-top: math.div($content-gutter, 2);
    }
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
  }

  table,
  th,
  td {
    width: 100%;
    border: 1px solid;
    padding: 12px;
  }

  td {
    vertical-align: top;
  }

  .intro {
    @include font-medium;
    font-size: 1.125em;
  }

  .ignore-container {
    @include transform(translateX(-50%));
    position: relative;
    width: 100vw;
    max-width: $container-max-width;
    left: 50%;
    padding-left: $gutter;
    padding-right: $gutter;
    margin-top: $gutter-double;
    margin-bottom: $gutter-double;
  }
}

[data-am-content~="limit-width"] {
    max-width: 75ch;
}

[data-am-content~="font-weight-regular"] {
    font-weight: var(--font-weight-regular);
}

// Text Size

[data-am-content~='text-smaller'] {
  font-size: 0.875rem;

  p {
    margin-bottom: $gutter-half;
  }
}

[data-am-content~='text-tiny'] {
  font-size: 0.75rem;

  p {
    margin-bottom: $gutter-half;
  }
}

[data-am-content~='ignore-headings'] {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1em;
  }
}

// Type

[data-am-content~='story'] {
  %ignore-container-img {
    @include transform(translateX(-50%));
    position: relative;
    width: 100vw;
    max-width: $container-max-width;
    left: 50%;
    padding-left: $gutter;
    padding-right: $gutter;
    margin-top: $gutter-double;
    margin-bottom: $gutter-double;
  }

  > img {
    @extend %ignore-container-img;

    + .caption {
      margin-top: -($gutter-double - $gutter-half);
    }
  }

  > p {
    > img {
      @extend %ignore-container-img;

      + .caption {
        margin-top: -($gutter-double - $gutter-half);
      }
    }
  }
}
