[data-wx-overflowgrid] {
  .overflow-grid-item {
    width: 100%;
  }
}

[data-wx-overflowgrid~='overflow-small'] {
  .overflow-grid-item {
    width: 50%;
  }
}
