[data-am-content] {
  .ignore-container {
    padding-left: $gutter-triple;
    padding-right: $gutter-triple;
  }
}

[data-am-content~='text-smaller'] {
  font-size: 0.875rem;
}

[data-am-content~='text-smaller-md'] {
  font-size: 0.875rem;
}

[data-am-content~='ignore-headings'] {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1em;
  }
}

[data-am-content~='story'] {
  %ignore-container-img-md {
    padding-left: $gutter-triple;
    padding-right: $gutter-triple;
  }

  > img {
    @extend %ignore-container-img-md;
  }

  > p {
    > img {
      @extend %ignore-container-img-md;
    }
  }
}
