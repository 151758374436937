[data-wx-mediacontent] {
  @include flex-display;
  @include align-items(center);

  .media-content-media {
    & + .media-content-text {
      padding-left: gutter(1);
    }
  }

  .media-content-text {
    @include flex(1 1 auto);
  }
}

[data-wx-mediacontent~='small'] {
  .media-content-media {
    & + .media-content-text {
      padding-left: gutter(0.5);
    }
  }
}
