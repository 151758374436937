[data-wx-floatingbar] {
  @include animation(fadeInToTop 200ms ease);

  .floating-bar {
    @include flex-display;
  }

  .floating-bar-action {
    padding: 0 gutter(0.25);

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}
