[data-wx-profilepicture] {
  $profile-picutre-size: 80px;
  $upload-btn-size: 36px;

  position: relative;
  display: block;
  width: $profile-picutre-size;
  height: $profile-picutre-size;
  cursor: pointer;

  .profile-picutre-input {
    position: fixed;
    left: -9999px;
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    z-index: -1;
  }

  .profile-picture {
    display: block;
    width: $profile-picutre-size;
    height: $profile-picutre-size;
    padding-right: gutter(0.625);
    padding-bottom: gutter(0.625);
  }

  .profile-picture-img {
    display: block;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $c-accent;
    border-radius: 50%;
  }

  .profile-picture-upload-btn {
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    width: $upload-btn-size;
    height: $upload-btn-size;
    background-color: $c-primary-light;
    color: $c-primary;
    border-radius: 50%;
    border: 2px solid var(--c-bg-main);
    font-size: 1rem;

    [class^='icon-'],
    [class*=' icon-'] {
      @include transform(translate(-50%, -50%));
      position: absolute;
      top: calc(50% - 1px);
      left: calc(50% - 1px);

      &:before {
        display: block;
        line-height: 1;
      }
    }
  }
}
