@use "sass:math";

[data-wx-rangeslider] {
    --bar-height: 4px;
    --handle-size: 25px;

    position: relative;

    .range-slider {
        padding-left: calc(var(--handle-size) / 2);
        padding-right: calc(var(--handle-size) / 2);
        padding-bottom: calc((var(--handle-size) - var(--bar-height)) / 2);
    }

    // RC Slider

    .rc-slider {
        position: relative;
        height: var(--bar-height);
    }

    .rc-slider-rail {
        position: absolute;
        left: calc(var(--handle-size) / -2);
        width: calc(100% + var(--handle-size));
        background-color: #e2e2e2;
        height: var(--bar-height);
        border-radius: 100vmax;
    }

    .rc-slider-track {
        position: absolute;
        left: 0;
        transform: translateX(calc(var(--handle-size) / -2));
        height: var(--bar-height);
        border-radius: 100vmax;
        background-color: $c-primary;
    }

    .rc-slider-handle {
        @include unselectable;
        @include transform(translateX(0) !important); // Overwrite RC slider inline translateX
        @include transform-origin(50% 50%);
        position: absolute;
        top: 0;
        width: var(--handle-size);
        height: var(--handle-size);
        border-radius: 50%;
        margin-top: calc((var(--handle-size) - var(--bar-height)) / -2);
        margin-left: calc(var(--handle-size) / -2);
        background-color: var(--c-bg-main);
        border: 1px solid $c-primary;
        box-shadow: $g-x-box-shadow;
        cursor: move; // sass-lint:disable-line no-duplicate-properties
        cursor: ew-resize; // sass-lint:disable-line no-duplicate-properties
        cursor: -webkit-grab; // sass-lint:disable-line no-duplicate-properties
        cursor: grab; // sass-lint:disable-line no-duplicate-properties
        transition: transform 200ms ease;

        &:before {
            $outline-size: 8px;

            content: "";
            position: absolute;
            top: -($outline-size + 1px);
            bottom: -($outline-size + 1px);
            left: -($outline-size + 1px);
            right: -($outline-size + 1px);
            border-radius: 50%;
            border: $outline-size solid var(--c-accent-bg);
            opacity: 0;
            transition: opacity 200ms ease;
        }

        &:focus {
            outline: none;
        }

        &:active {
            @include transform(scale(1.3) translateX(0) !important); // Overwrite RC slider inline translateX

            &:before {
                opacity: 0.5;
            }
        }
    }

    .rc-slider-step,
    .rc-slider-mark {
        display: block;
    }

    // Histogram

    $histogram-spacing: 1px;

    .histogram-container {
        margin-left: -($histogram-spacing);
        margin-right: -($histogram-spacing);
        margin-bottom: $histogram-spacing;
        overflow: hidden;

        + .bar,
        ~ .bar {
            margin-top: 0;
        }
    }

    .histogram-chart {
        display: table;
        table-layout: fixed;
        border-collapse: separate;
        border-spacing: $histogram-spacing 0;
        width: 100%;
        height: 70px;
        overflow: hidden;
        margin-bottom: -1px; // Prevents half-pixel browser bugs to be visible
    }

    .histogram-column {
        display: table-cell;
        vertical-align: bottom;
        height: 100%;
    }

    .column-fill {
        position: relative;
        height: 0;
        background-color: $c-accent;

        &.is-active {
            background-color: $c-primary-light;
        }
    }

    // Range Input

    .range-input-section {
        @include flex-display;
        @include align-items(center);
        width: 100%;
        margin-bottom: gutter(2);
    }

    .range-input-container {
        @include flex(1 1 auto);
    }

    .range-input-separator {
        @include flex-shrink(0);
        color: var(--c-text-muted);
        font-size: 1.375rem;
        padding-left: gutter(0.5);
        padding-right: gutter(0.5);

        &:before {
            content: "–";
        }
    }

    // Range Span

    .range-span {
        @include clearfix;
        margin-top: gutter(0.5);
    }

    .range-span-low,
    .range-span-high {
        @include font-light;
        @include unselectable;
        font-size: 0.875rem;
        color: $c-fade-dark-50;
        cursor: default;
    }

    .range-span-low {
        float: left;
    }

    .range-span-high {
        float: right;
    }
}

[data-wx-rangeslider~="simple"] {
    .range-slider {
        padding-top: calc((var(--handle-size) - var(--bar-height)) / 2);
    }
}
