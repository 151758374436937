[data-am-gallerycontrols] {
  margin: gutter(-0.5) 0;
  
  .gallery-controls-btn {
    height: auto;
    padding: gutter(0.5);

    &.is-fullscreen {
      display: block;
      margin-left: auto;
    }
  }
}
