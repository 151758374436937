[data-wx-filterlist] {
  .filter-list-btn {
    &:hover {
      .filter-list-btn-label {
        @include transform(translateX(gutter(0.5)));
      }
    }
  }

  .filter-list-btn-label {
    transition: transform 300ms cubic-bezier(.47, 1.64, 0.41, 0.8);
  }
}
