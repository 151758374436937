[data-wx-messagepreview~='boxed'] {
  padding: gutter(1.5);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: $g-x-box-shadow;
    opacity: 0;
    transition: opacity 200ms ease;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }
}
