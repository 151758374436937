// This files includes CSS for included fonts

// Font Mixins

@mixin font-light {
    font-weight: var(--font-weight-light);
}

@mixin font-regular {
    font-weight: var(--font-weight-regular);
}

@mixin font-medium {
    font-weight: var(--font-weight-medium);
}

@mixin font-bold {
    font-weight: var(--font-weight-bold);
}

// Font Styles

@mixin font-label {
  @include font-regular;
  font-size: 0.875rem;
}
