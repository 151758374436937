@use "sass:math";

[data-wx-notificationbadge] {
  $notification-size: 20px;

  display: inline-block;
  vertical-align: middle;
  min-width: $notification-size;
  height: $notification-size;
  line-height: $notification-size;
  border-radius: math.div($notification-size, 2);
  background-color: $c-action;
  color: $c-text-on-action;
  text-align: center;
  font-size: 0.75rem;
  white-space: nowrap;
  font-family: sans-serif; // Use other font for guarnateed readability
  font-weight: bold;
  font-style: normal;
  padding-left: gutter(0.25);
  padding-right: gutter(0.25);
  z-index: 10;

  &:after {
    content: attr(data-count);
  }
}

[data-wx-notificationbadge~='large'] {
  $notification-size: 32px;

  @include font-medium;
  min-width: $notification-size;
  height: $notification-size;
  line-height: $notification-size;
  border-radius: math.div($notification-size, 2);
  padding-right: gutter(0.5);
  padding-left: gutter(0.5);
  font-size: 1rem;

  &:after {
    font-size: 1rem;
    line-height: math.div($notification-size, 2);
  }
}

[data-wx-notificationbadge~='absolute'] {
  @include transform(translate(35%, -35%));
  position: absolute;
  top: 0;
  right: 0;
}