[data-wx-listcard] {
  $list-card-avatar-size: 40px;

  position: relative;
  height: 0;
  padding-bottom: 130%;
  box-shadow: $g-x-box-shadow;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    box-shadow: $g-x-box-shadow;
    transition: opacity 200ms ease;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }

  &.is-disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  .list-card-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: var(--c-skeleton);
  }

  .list-card {
    @include flex-display;
    @include flex-direction(column);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .list-card-body,
  .list-card-footer {
    padding: gutter(1.5);
  }

  .list-card-body {
    @include flex-display;
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(center);
    @include flex(1 1 auto);

    &.no-padding-bottom {
      padding-bottom: 0;
    }

    & + .list-card-footer {
      padding-top: 0;
    }
  }

  // Header

  .list-card-header {
    position: relative;
    padding: gutter(0.75);
    font-size: 0;

    > * {
      font-size: 1rem;
    }
  }

  .list-card-badge {
    $list-card-badge-size: 32px;

    position: absolute;
    top: gutter(0.75);
    right: gutter(0.75);
    display: inline-block;
    vertical-align: middle;
    width: $list-card-badge-size;
    height: $list-card-badge-size;
    line-height: $list-card-badge-size;
    background-color: $c-action;
    color: $c-fade-light-80;
    text-align: center;
    border-radius: 50%;
    box-shadow: $g-x-box-shadow;
  }

  .list-card-avatars {
    position: absolute;
    top: gutter(1);
    right: gutter(1);
  }

  .list-card-utility {
    position: absolute;
    top: gutter(0.75);
    left: gutter(0.75);
    z-index: 1;
  }

  .list-card-utility-btn {
    $list-card-utility-btn-size: 24px;

    position: relative;
    display: block;
    width: $list-card-utility-btn-size;
    height: $list-card-utility-btn-size;
    border-radius: $g-border-radius-small;
    border: 2px solid $c-fade-light-10;
    background-clip: padding-box;
    box-shadow: 0 8px 10px 1px $c-fade-dark-03, 0 2px 14px 1px $c-fade-dark-03, 0 2px 5px 0 $c-fade-dark-10;
    background-color: $c-fade-dark-60;
    color: var(--c-text-media);

    &:hover {
      background-color: $c-fade-dark-80;
    }

    [class^='icon-'],
    [class*=' icon-'] {
      @include transform(translate(-50%, -50%));
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 0.75rem;

      &:before {
        display: block;
        line-height: 1;
        padding-bottom: 0; // Unique to the meatball icon
      }
    }
  }

  // Body

  .list-card-logo {
    @include flex-shrink(0);
    max-width: 60%;
    max-height: 88px;
  }

  // Footer

  .list-card-footer {
    position: relative;
    color: var(--c-text-media);
    margin-top: auto;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: gutter(-2);
      background-image: linear-gradient($c-fade-dark-00, $c-fade-dark-80);
      z-index: -1;
    }
  }

  .list-card-heading {
    @include font-bold;
    max-height: 1.75rem;
    font-size: 1.1875rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .list-card-sub-heading {
    @include font-medium;
    margin-top: gutter(0.375);
    font-size: 0.875rem;
    line-height: 1;
    color: $c-fade-light-50;

    &.is-highlighted {
      $highlight-dot-size: gutter(0.5);

      position: relative;
      color: $c-action;
      padding-left: ($highlight-dot-size + gutter(0.25));

      &:before {
        content: '';
        position: absolute;
        top: calc((1em - #{gutter(0.5)}) / 2);
        left: 0;
        display: block;
        width: $highlight-dot-size;
        height: $highlight-dot-size;
        border-radius: 50%;
        background-color: currentColor;
        margin-right: gutter(0.25);
      }
    }
  }
}

[data-wx-listcard~='light'] {
  .list-card-footer {
    color: var(--c-text-main);

    &:before {
      content: none;
    }
  }

  .list-card-sub-heading {
    color: $c-fade-dark-50;
  }
}

[data-wx-listcard~='thin'] {
  padding-bottom: 56.25%;
}

[data-wx-listcard~='padding-small'] {
  .list-card-body,
  .list-card-footer {
    padding: gutter(1);
  }
}
