@use "sass:math";

[data-wx-overflowgrid] {
    // Ignore container padding
    margin-left: -($grid-container-gutter-lt-md);
    margin-right: -($grid-container-gutter-lt-md);

    .overflow-grid {
        padding-left: $grid-container-gutter-lt-md;
        padding-right: math.div($grid-container-gutter-lt-md, 2);
        padding-bottom: gutter(1);
        margin-bottom: gutter(-1);
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        margin-right: 0;
        scroll-snap-type: x mandatory;
        scroll-padding-left: $grid-container-gutter-lt-md;

        // Add extra spacing after last grid item
        &:after {
            // sass-lint:disable-block mixins-before-declarations
            //content: '';
            display: inline-block;
            @include flex-display;
            @include flex-direction(column);
            @include flex(0 0 auto);
            vertical-align: top;
            width: $grid-container-gutter-lt-md;
            visibility: hidden;
        }
    }

    .overflow-grid-content {
        padding-right: $grid-container-gutter-lt-md;
    }

    .offset-btn {
        display: none;
    }
}
