[data-wx-filtercontainer] {
  $animation-speed: $g-filter-container-animation-speed;
  $navigation-width: 430px;

  .filter-container {
    @include flex-display;
    position: relative;
    height: 100%;
    z-index: 1;
  }

  .filter-container-section-nav {
    @include animation(filterContainerNavSm $animation-speed ease);
    width: $navigation-width;
    z-index: 1;
  }

  .filter-container-section-content {
    @include flex(1 1 auto);
    @include flex-display;
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    padding: gutter(2);
  }

  .filter-container-content {
    @include animation(filterContainerModalSm $animation-speed ease);
    @include flex-display; // Required to stay inside parent in Safari
    @include flex-direction(column); // Required to stay inside parent in Safari
    width: $navigation-width;
    max-height: 100%;
  }

  .ui-block {
    left: -($navigation-width);
  }

  // Custom breakpoint just for this component
  @media (max-width: 800px) {
    $filter-container-width-lt-md: 320px;

    .filter-container-section-nav,
    .filter-container-content {
      width: $filter-container-width-lt-md;
    }

    .ui-block {
      left: -($filter-container-width-lt-md);
    }
  }
}
