[data-wx-infobutton] {
  $info-button-size: 20px;
  $info-button-border-size: 2px;

  position: relative;
  display: block;
  color: $c-primary;
  width: $info-button-size;
  height: $info-button-size;
  border-radius: 50%;
  border: $info-button-border-size solid;
  font-size: ($info-button-size - ($info-button-border-size * 2));

  [class^='icon-'],
  [class*=' icon-'] {
    @include transform(translate(-50%, -50%));
    position: absolute;
    top: 50%;
    left: 50%;

    &:before {
      display: block;
      line-height: 1;
    }
  }
}

[data-wx-infobutton~='inline'] {
  $info-button-size: 16px;
  $info-button-border-size: 2px;

  display: inline-block;
  vertical-align: middle;
  width: $info-button-size;
  height: $info-button-size;
  border: $info-button-border-size solid;
  font-size: ($info-button-size - ($info-button-border-size * 2));
}
