[data-wx-actioncontainer=""] {
  @include flex-display;
  @include flex-direction(row);
  margin: gutter(-0.5);
  
  .action {
    @include flex(1 1 auto);
    padding: gutter(0.5);
    width: 50%;
    max-width: 50%;
  }
}

//Temporary modifier until we use both buttons
[data-wx-actioncontainer="center"] {
  @include justify-content(center);
};
