[data-wx-label] {
  @include font-bold;
  font-size: 0.625rem;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  color: $c-fade-dark-50;
}

[data-wx-label~='light'] {
  color: $c-fade-light-80;
}
