[data-wx-overflowgrid] {
  .overflow-grid {
    @include flex-display;
    @include flex-direction(row);
    font-size: 0;
    padding-left: 0;
    margin-right: gutter(-1);

    > * {
      font-size: 1rem;
      white-space: normal;
    }
  }

  .overflow-grid-item {
    // sass-lint:disable-block mixins-before-declarations
    display: inline-block;
    @include flex-display;
    @include flex-direction(column);
    @include flex(0 0 auto);
    vertical-align: top;
    width: 75%;
    scroll-snap-align: start;
  }

  .overflow-grid-content {
    @include flex-display;
    @include flex-direction(column);
    @include flex(1 0 auto);
    padding-right: gutter(1);
  }
}

// Small
// This modifiers displays smaller cards on smaller screens.

[data-wx-overflowgrid~='overflow-small'] {
  .overflow-grid-item {
    width: 50%;
  }
}

[data-wx-overflowgrid~='full-width-mobile'] {
  .overflow-grid-item {
    width: auto;
  }
}
