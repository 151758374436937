@use "sass:math";

[data-am-footer] {
  background-color: var(--c-bg-main);
  color: var(--c-text-main);
  margin-top: auto;
  z-index: 0; // Required to place below filter container

  .footer-nav {
    padding-top: $gutter-double;
    padding-bottom: $gutter;
    border-top: 1px solid var(--c-border-main);
  }

  .info-container {
    margin-top: $gutter-double;
  }

  .footer-logo {
    display: block;
    height: 40px;
    fill: var(--c-text-main);
    pointer-events: none; // Required due to a bug in IE11 where the svg disappear when clicking on it

    [data-theme="drive"] & {
        height: 30px;
    }
  }

  .footer-heading {
    @include font-medium;
    margin-bottom: math.div($gutter-half, 2);
  }

  .footer-link {
    font-size: 0.875rem;

    &:hover {
      text-decoration: underline;
    }
  }

  .footer-link-small {
    @include font-medium;
    font-size: 0.75rem;

    &:hover {
      color: $c-primary;
    }

    & + .footer-link-small {
      margin-left: $gutter;
    }
  }

  .footer-text-small {
    color: var(--c-text-muted);
    font-size: 0.75rem;
  }

  .footer-action {
    font-size: 1.125em;

    &:hover {
      color: $c-action;
    }

    & + .footer-action {
      margin-left: math.div($gutter-half, 2);
    }
  }
}
