@use "sass:math";

[data-wx-uploadmedia] {
  $preview-width: 25%;

  &.has-upload {
    .upload-btn-container {
      display: inline-block;
      vertical-align: top;
      width: $preview-width;
    }

    .upload-btn {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      padding-bottom: 100%;
    }

    .upload-btn-icon {
      margin-right: 0;
    }

    .upload-btn-text {
      display: none;
    }

    .upload-btn-content {
      @include transform(translate(-50%, -50%));
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      padding-left: gutter(0.5);
      padding-right: gutter(0.5);
    }
  }

  .upload-media {
    margin: math.div(gutter(0.5), -4);
    font-size: 0;

    > * {
      font-size: 1rem;
    }
  }

  // Upload Button

  .upload-btn-item {
    padding: math.div(gutter(0.5), 4);
  }

  .upload-btn {
    @include transform-origin(50% 50%);
    display: block;
    width: 100%;
    border: 1px dashed var(--c-border-main);
    padding: 24px 16px;
    background-color: var(--c-bg-main);
    border-radius: $g-border-radius-small;
    font-size: 0.875rem;
    transition: transform 200ms ease, background-color 200ms ease;

    &:active {
      @include transform(scale(0.97));
    }
  }

  .upload-btn-icon,
  .upload-btn-text {
    display: inline-block;
    vertical-align: middle;
  }

  .upload-btn-icon {
    margin-right: gutter(0.5);
    color: $c-accent-dark;
    font-size: 1.875rem;
  }

  .upload-btn-text {
    display: none;
  }

  .upload-btn-text-action {
    color: $c-primary;
    display: block;
  }

  // Upload Preview

  .upload-preview-container {
    display: inline;
    vertical-align: top;
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 0;

    > * {
      font-size: 1rem;
    }
  }

  .upload-preview-item {
    width: $preview-width;
    padding: math.div(gutter(0.5), 4);

    &.react-sortable-hoc-ghost-uploadmedia {
      z-index: 1;

      .upload-preview {
        box-shadow: none;
      }
    }
  }

  .upload-preview {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: $c-fade-dark-40;
      transition: opacity 400ms ease;
    }

    @include keyframes(previewProgressBar) {
      50% {
        width: 100%;
        height: gutter(0.5);
      }

      100% {
        height: 0;
        width: 100%;
      }
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 20%;
      height: gutter(0.5);
      background-color: $c-primary;
    }

    &.is-uploaded {
      &:before {
        opacity: 0;
      }

      &:after {
        @include animation(previewProgressBar 500ms ease forwards);
      }
    }

    &.has-error {
      box-shadow: inset 0 0 0 2px $c-ui-danger;
    }
  }

  .upload-preview-remove-btn {
    $remove-btn-size: 25px;

    @include unselectable;
    position: absolute;
    top: math.div(gutter(0.5), 2);
    right: math.div(gutter(0.5), 2);
    width: $remove-btn-size;
    height: $remove-btn-size;
    border-radius: 50%;
    background-color: $c-action;
    color: $c-text-on-action;
    font-size: 0.65rem;
  }

  .upload-preview-retry-btn {
    @include unselectable;
    @include transform(translateX(-50%));
    position: absolute;
    left: 50%;
    bottom: math.div(gutter(0.5), 2);
    padding: math.div(gutter(0.5), 2);
    background-color: $c-fade-dark-50;
    color: var(--c-text-media);
    font-size: 0.65rem;
    width: calc(100% - #{gutter(0.5)});
    border-radius: $g-border-radius;
  }

  // This class is applied to `.upload-preview-item` since React Sortable (HOC)
  // places the item outside of it's wrapper `[data-am-uploadmedia]` while being dragged.
  @at-root {
    .react-sortable-hoc-ghost-uploadmedia {
      display: inline-block;
      vertical-align: top;
      width: 150px; // Default width will be overwritten by React Sortable (HOC)
      z-index: 100;

      .upload-preview {
        position: relative;
        height: 0;
        padding-bottom: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: $c-accent;
        box-shadow: $g-box-shadow;
      }

      .upload-preview-remove-btn,
      .upload-preview-retry-btn {
        display: none;
      }

      [data-wx-uploadmedia] & {
        .upload-preview-remove-btn,
        .upload-preview-retry-btn {
          display: block;
        }
      }
    }
  }
}
