[data-wx-articlecard] {
  .article-card-image {
    height: 0;
    padding-bottom: 56.25%;
    margin-bottom: gutter(1);
  }

  .article-card-pre-heading {
    display: none;
  }
}

[data-wx-articlecard~='video'] {
  position: relative;
  background-color: $c-complement-dark;

  .container & {
    width: 100vw;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  .article-card-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: gutter(16.5);
    padding-bottom: 0;
    margin-bottom: 0;

    &:before {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 40%;
      background-image: linear-gradient(to bottom, $c-fade-dark-00, $c-complement-dark);
    }
  }

  .article-card-image-action {
    display: none;
  }

  .article-card-action {
    position: absolute;
    top: gutter(7.5);
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .article-card-body {
    position: relative;
    padding-top: gutter(13.5);
    text-align: center;
  }

  .article-card-pre-heading {
    display: block;
    color: $c-fade-light-80;
  }

  .article-card-heading {
    font-size: 1.5714285714rem;
    color: var(--c-text-media);
  }

  .article-card-content {
    position: relative;
    color: var(--c-text-media);
    margin-top: gutter(1.5);
  }
}


[data-wx-articlecard~='standalone'] {
  position: relative;

  .container & {
    margin-left: -($grid-container-gutter-lt-md);
    margin-right: -($grid-container-gutter-lt-md);
  }

  .article-card-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: gutter(16.5);
    padding-bottom: 0;
    margin-bottom: 0;

    &:before {
      content: '';
      position: absolute;
      bottom: -1px; // Solves 1 pixel bug
      left: 0;
      right: 0;
      height: 70%;
      background-image: linear-gradient(to bottom, $c-fade-dark-00, $c-complement-dark);
    }
  }

  .article-card-image-action {
    display: none;
  }

  .article-card-body {
    position: relative;
    padding: gutter(1.5);
    padding-top: gutter(8.26);
  }

  .article-card-pre-heading {
    display: block;
  }

  .article-card-heading {
    font-size: 1.5714285714rem;
  }

  .article-card-preamble {
    margin-top: gutter(1);
  }
}
