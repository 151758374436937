[data-am-mapbutton] {
  .map-button {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $c-fade-light-20;
      opacity: 0;
      transition: opacity 200ms ease;
    }

    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }
}
