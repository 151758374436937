[data-am-chat] {
  $chat-bubble-width: 75%;

  .chat-container {
    min-height: initial;
  }

  .chat-bubble {
    width: $chat-bubble-width;
    font-size: 1rem;

    p {
      margin-bottom: $gutter-half;
    }
  }

  .chat-bubble-sent {
    margin-left: calc(100% - #{$chat-bubble-width});
  }

  .chat-bubble-location {
    width: $chat-bubble-width;
  }

  .time-stamp {
    font-size: 0.875em;
    margin-top: $gutter;
  }

  .received-name {
    font-size: 0.875rem;
  }

  .chat-time-stamp {
    font-size: 0.75rem;
  }
}
