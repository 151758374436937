@use "sass:math";

[data-am-switch] {
  $switch-width: 45px;
  $switch-height: 25px;
  $thumb-margin: 2px;

  display: inline-block;
  vertical-align: middle;

  .checkbox {
    position: fixed;
    left: -9999px;
    opacity: 0;

    &:checked {
      // sass-lint:disable-block nesting-depth
      + .switch {
        background-color: $c-primary;

        &:before {
          @include transform(translate3d(calc(100% - #{math.div($thumb-margin, 2)}), 0, 0.00001px));
        }
      }
    }
  }

  .switch {
    position: relative;
    display: block;
    width: $switch-width;
    height: $switch-height;
    background-color: $c-accent-dark;
    border-radius: math.div($switch-height, 2);
    cursor: pointer;
    transition: background-color 200ms ease;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: ($switch-height - ($thumb-margin * 2));
      height: ($switch-height - ($thumb-margin * 2));
      border-radius: 50%;
      background-color: var(--c-bg-main);
      box-shadow: $g-box-shadow;
      margin: 2px;
      transition: transform 200ms ease, box-shadow 200ms ease;
    }
  }
}

[data-am-switch~='block'] {
  display: block;
}
