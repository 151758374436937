[data-am-sidenavigation] {
  // Remove container's gutter
  .container & {
    margin-left: -($grid-gutter);
    margin-right: -($grid-gutter);
  }

  .side-nav {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: $grid-gutter - $gutter-half;
    padding-right: $grid-gutter - $gutter-half;
    white-space: nowrap;
    background-color: $c-primary-light;
    overflow: auto;
    -webkit-overflow-scrolling: touch; // sass-lint:disable-line no-misspelled-properties
    font-size: 0;

    > * {
      font-size: 1rem;
    }
  }

  .nav-item {
    display: inline-block;

    &.is-active {
      > .nav-link {
        color: $c-primary;
      }
    }

    .side-nav {
      display: none;
    }
  }

  .nav-link {
    padding: ($gutter-half * 1.5) $gutter-half;

    &:hover {
      color: var(--c-text-main);
    }
  }
}
