[data-am-avatar] {
  $avatar-size: 100px;

  .avatar-container {
    width: $avatar-size;
    height: $avatar-size;
  }
}

[data-am-avatar~='transparent'] {
  $avatar-size: 80px;

  .avatar-container {
    width: auto;
    height: auto;
  }

  .avatar {
    width: $avatar-size;
  }
}
