[data-wx-listsection] {
  @include grid-display;
  @include grid-template-columns(1fr auto);

  .list-section-heading {
    @include grid-column-span(1, 1);
    margin-bottom: gutter();
  }

  .list-section-content {
    @include grid-column-span(1, 2);
    @include grid-row-span(2, 1);
    margin-top: gutter(1.5);
    min-width: 0; // Fixes oveflow bug in Firefox
  }

  .list-section-action {
    @include grid-column-span(2, 1);
    margin-bottom: gutter();
    margin-left: gutter(1.5);
  }

  .list-section-heading,
  .list-section-action {
    @include grid-align-self(end);
  }
}
