[data-wx-togglelist] {
  border-bottom: 1px solid var(--c-border-main);
  overflow: hidden;

  .container & {
    margin-left: -($grid-container-gutter-lt-md);
    margin-right: -($grid-container-gutter-lt-md);
  }

  .toggle-list-overflow {
    overflow: auto;
  }

  .toggle-list {
    padding-left: $grid-container-gutter-lt-md;
    padding-right: $grid-container-gutter-lt-md;
    white-space: nowrap;

    &:after {
      content: '';
      width: $grid-container-gutter-lt-md;
      display: inline-block;
    }
  }

  .toggle-list-action {
    padding-top: 0;
    padding-bottom: gutter(0.75);
  }
}
