@use "sass:math";

[data-wx-inputsearch] {
  $input-search-height: $g-input-search-md;
  $input-seach-spacing: 5px;

  @include flex-display;
  background-color: var(--c-bg-main);
  color: var(--c-text-main);
  border-radius: math.div($input-search-height,2);
  box-shadow: $g-x-box-shadow;

  %input-search-util-base {
    $input-search-util-size: ($input-search-height - ($input-seach-spacing * 2));
    display: block;
    width: $input-search-util-size;
    height: $input-search-util-size;
    line-height: $input-search-util-size;
    border-radius: 50%;
    text-align: center;
  }

  // Icon

  .input-search-icon-section {
    padding: $input-seach-spacing;
    cursor: text;
  }

  .input-search-icon {
    @extend %input-search-util-base;
    background-color: $c-action;
    color: $c-text-on-action;
  }

  // Input

  .input-search-input-section {
    @include flex(1 1 auto);
    position: relative;

    &:first-child {
      .input-search-input {
        padding-left: gutter(1.5);
      }
    }

    &:last-child {
      .input-search-input {
        padding-right: ($input-seach-spacing * 2);
      }
    }
  }

  .input-search-input {
    display: block;
    width: 100%;
    height: $input-search-height;
    line-height: $input-search-height;
    margin: 0;
    padding: 0 $input-seach-spacing;
    border: 0;
    border-radius: 0;
    background-color: $c-fade-light-00;
    box-shadow: none; // Removes red border from [required] in Firefox
    font-size: 16px; // Set font size in px to prevent zoom in on iOS

    &:focus {
      outline: none;
    }

    @mixin input-search-placeholder-style {
      font-size: 0.875rem;
    }

    &::-webkit-input-placeholder {
      @include input-search-placeholder-style;
    }

    &:-moz-placeholder {
      @include input-search-placeholder-style;
    }

    &::-moz-placeholder {
      @include input-search-placeholder-style;
    }

    &:-ms-input-placeholder {
      @include input-search-placeholder-style;
    }
  }

  // Action

  .input-search-action-section {
    padding: $input-seach-spacing;
    white-space: nowrap;
    font-size: 0;

    > * {
      font-size: 1rem;
    }
  }

  .input-search-action {
    @extend %input-search-util-base;
    @include transform-origin(50% 50%);
    display: inline-block;
    vertical-align: middle;
    background-color: var(--c-bg-main);
    color: $c-primary;
    box-shadow: $g-box-shadow; // TODO: Change
    -webkit-tap-highlight-color: $c-fade-dark-00;
    transition: transform 200ms ease;

    &.is-action {
      background-color: $c-action;
      color: $c-text-on-action;
    }

    &:active {
      @include transform(scale(0.97));
    }
  }

  .input-search-clear {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: $input-search-height;
    height: $input-search-height;
    background-color: var(--c-bg-main);

    &:before {
      $clear-btn-size: 18px;

      @include transform(translate(-50%, -50%));
      content: '\e816';
      font-family: 'icons';
      position: absolute;
      top: 50%;
      left: 50%;
      width: $clear-btn-size;
      height: $clear-btn-size;
      line-height: $clear-btn-size;
      border-radius: 50%;
      background-color: $c-accent;
      color: var(--c-text-muted);
      font-size: 0.5rem;
      text-align: center;
    }
  }

  // Count

  .input-search-count {
    display: inline-block;
    vertical-align: middle;
    padding-right: ($input-seach-spacing * 2);
    color: var(--c-input-placeholder);
    font-size: 0.875rem;
  }

  // Foldout

  .input-search-foldout {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;

    .ui-block {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
    }
  }

  .input-search-foldout-main {
    padding: gutter(1);
    background-color: var(--c-bg-main);
    border-bottom-left-radius: math.div($input-search-height,2);
    border-bottom-right-radius: math.div($input-search-height,2);
    border-top: 1px solid var(--c-border-main);
    box-shadow: 3px 3px 8px -1px $c-fade-dark-03, -3px 3px 8px -1px $c-fade-dark-10;
  }

  .input-search-query-suggestion-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .input-search-query-suggestion-item {
    &.has-focus {
      .input-search-query-suggestion-action {
        background-color: $c-accent;
      }
    }
  }

  .input-search-query-suggestion-action {
    @include font-medium;
    display: block;
    width: 100%;
    text-align: left;
    padding: gutter(0.5);
    font-size: 0.875rem;
    border-radius: $g-border-radius;
  }

  .input-search-query-suggestion-label {
    font-size: 0;

    > * {
      display: inline-block;
      vertical-align: middle;
      font-size: 1rem;
    }
  }

  .input-search-query-suggestion-label-icon {
    font-size: 1rem;
    color: $c-action;
    margin-right: gutter(0.5);

    [class^='icon-'],
    [class*=' icon-'] {
      &:before {
        display: block;
        line-height: 1;
      }
    }
  }

  .input-search-query-suggestion-label-text {
    font-size: 0.875rem;
  }

  .input-search-query-suggestion-count {
    @include font-regular;
    color: var(--c-text-muted);
    font-size: 0.75rem;
  }

  .input-search-query-suggestion-heading {
    @include font-regular;
    font-size: 0.75rem;
    color: var(--c-text-muted);
    margin-top: gutter(0.75);
    margin-bottom: gutter(0.25);

    &:first-child {
      margin-top: 0;
    }
  }
}
