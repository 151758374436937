[data-wx-subnavigation] {
  position: relative;
  background-color: $c-accent;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: gutter(2.5);
    background-image: linear-gradient(90deg, transparent, $c-accent);
    pointer-events: none;
  }

  .container & {
    margin-left: -($grid-container-gutter-lt-md);
    margin-right: -($grid-container-gutter-lt-md);
  }

  .sub-navigation {
    overflow: auto;
  }

  .sub-navigation-list {
    padding: gutter(1) $grid-container-gutter-lt-md;
    white-space: nowrap;
    font-size: 0;

    > * {
      font-size: 0.875rem;
    }
  }

  .sub-navigation-item {
    display: inline-block;
    vertical-align: middle;

    &:last-child {
      &:after {
        content: '';
        display: inline-block;
        width: $grid-container-gutter-lt-md;
      }
    }

    & + .sub-navigation-item {
      margin-left: gutter(1.5);
    }
  }
}
