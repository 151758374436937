[data-wx-chatterbox=""] {
  background-color: var(--c-bg-main);
  border-radius: gutter(1.5);
  padding: gutter(2.5) gutter(1);
  position: relative;
  margin-top: 28px;

  &::before {
    content: "";
    position: absolute;
    top: -20px;
    left: 50%;
    border: 20px solid transparent;
    border-bottom-color: var(--c-bg-main);
    transform: translate(-50%, -50%);
  }
}
