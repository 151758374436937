[data-am-togglebar] {
  @include flex-display;
  @include align-items(center);

  .toggle-bar-header {
    @include flex-shrink(0);
    font-size: 0;

    > * {
      font-size: 1rem;
    }
  }

  .toggle-bar-header-section {
    display: inline-block;
    vertical-align: middle;

    & + .toggle-bar-header-section {
      margin-left: $gutter;
    }
  }

  .toggle-bar-body {
    @include flex-display;
    @include justify-content(space-between);
    @include align-items(center);
    @include flex(1 1 auto);
    padding-left: $gutter;
    font-size: 0.875rem;
    min-width: 0; // Required for ellipsis
  }

  .toggle-bar-link {
    padding-left: ($gutter-half * 1.5);
  }
}
