@use "sass:math";

// This file contains CSS that overwrites pannellum's default styling

[data-am-pannellum] {
  $pannellum-compass-size: 32px;
  $pannellum-control-button-size: 32px;

  .pnlm-container {
    background-image: none;
    background-color: var(--c-contrast-bg);
    width: 100%;
    height: 0 !important;
    padding-bottom: 66.666% !important; // 3:2
  }

  // Compass

  .pnlm-compass {
    width: $pannellum-compass-size;
    height: $pannellum-compass-size;
    border-radius: 50%;
    bottom: ($gutter-half * 1.5);
    left: $gutter;
    right: auto;
    margin-top: 0;
    border: 5px solid transparent;
    border-top-color: $c-primary;
    background-color: $c-fade-light-30;
    background-image: none;
    box-sizing: border-box;

    &:hover {
      background-color: $c-fade-light-30;
    }

    &:before {
      @include transform(translate(-50%, -50%));
      content: '\e856';
      font-family: 'icons';
      position: absolute;
      top: 50%;
      left: 50%;
      color: var(--c-text-media);
      font-size: 8px;
    }
  }

  // Controls (zoom & fullscreen button)

  .pnlm-grab,
  .pnlm-grabbing {
    .pnlm-controls-container {
      display: block;
    }
  }

  .pnlm-controls-container {
    display: none;
    top: ($gutter-half * 1.5);
    left: $gutter;
  }

  .pnlm-controls {
    margin-top: 0;

    & + .pnlm-controls {
      margin-top: $gutter-half;
    }
  }

  // Fullscreen button

  .pnlm-fullscreen-toggle-button {
    display: none;
    position: relative;
    width: $pannellum-control-button-size;
    height: $pannellum-control-button-size;
    background-image: none;
    background-color: $c-primary;
    color: $c-text-on-primary;
    border: 0;
    border-radius: 50%;

    &:hover {
      background-color: $c-primary;
    }

    &:before {
      @include transform(translate(-50%, -50%));
      position: absolute;
      top: 50%;
      left: 50%;
      font-family: 'icons';
      font-size: 14px;
    }
  }

  .pnlm-fullscreen-toggle-button-inactive {
    &:before {
      content: '\e85a';
    }

    &.pnlm-fullscreen-toggle-button-active {
      &:before {
        content: '\e85b';
      }
    }
  }

  // Gyroscope (Orientation)

  .pnlm-orientation-button {
    background-image: none;
    width: $pannellum-control-button-size;
    height: $pannellum-control-button-size;
    background-color: $c-primary;
    color: $c-text-on-primary;
    border: 0;
    border-radius: 50%;

    &:hover {
      background-color: $c-primary;
    }

    &:before {
      @include transform(translate(-50%, -50%));
      content: '\e855';
      position: absolute;
      top: 50%;
      left: 50%;
      font-family: 'icons';
      font-size: 14px;
    }

    &.pnlm-orientation-button-active {
      background-color: $c-action;

      &:hover {
        background-color: $c-action;
      }
    }
  }

  // Zoom controls

  .pnlm-zoom-controls {
    display: none;
    height: auto;
    width: auto;
    background-color: transparent;
    border: 0;

    > * {
      &:first-child {
        border-top-left-radius: math.div($pannellum-control-button-size, 2);
        border-top-right-radius: math.div($pannellum-control-button-size, 2);
      }

      &:last-child {
        border-bottom-left-radius: math.div($pannellum-control-button-size, 2);
        border-bottom-right-radius: math.div($pannellum-control-button-size, 2);
      }
    }
  }

  .pnlm-zoom-in,
  .pnlm-zoom-out {
    position: relative;
    display: block;
    width: $pannellum-control-button-size;
    height: $pannellum-control-button-size;
    background-image: none;
    background-color: $c-primary;
    color: $c-text-on-primary;
    border-radius: 0;

    &:before {
      @include transform(translate(-50%, -50%));
      @include font-medium;
      position: absolute;
      top: 50%;
      left: 50%;
    }

    &:hover {
      background-color: $c-primary;
    }
  }

  .pnlm-zoom-in {
    top: auto;

    &:before {
      content: '+';
    }
  }

  .pnlm-zoom-out {
    bottom: auto;
    border-top: 1px solid $c-fade-light-30;

    &:before {
      content: '-';
    }
  }

  // Load Button

  .pnlm-load-button {
    @include transform(translateX(-50%));
    @include unselectable;
    @include font-medium;
    top: auto;
    bottom: gutter(1.5);
    width: auto;
    height: auto;
    padding: gutter(0.75) gutter(1.5);
    border-radius: 999px;
    margin: 0;
    background-color: $c-complement;
    color: $c-text-on-complement;
    line-height: 1.1;

    &:hover {
      background-color: $c-complement;
    }

    p {
      margin: 0;
      font-size: 0.875rem;

      &:before {
        content: '\e855';
        font-family: 'icons';
        color: $c-primary;
        margin-right: gutter(0.5);
      }
    }
  }

  // Load box

  .pnlm-load-box {
    @include transform(translate(-50%, -50%));
    background-color: transparent;
    height: auto;
    margin: 0;
    width: 150px;

    > p {
      display: none;
    }
  }

  // Load bar

  .pnlm-lbar {
    width: 100%;
    background-color: $c-fade-light-30;
    border: 0;
  }

  .pnlm-lbar-fill {
    background-color: $c-primary;
  }
}

[data-am-pannellum~='parent-height'] {
  height: 100% !important;
  padding-bottom: 0 !important;

  .pnlm-container {
    height: 100% !important;
    padding-bottom: 0 !important;
  }
}
