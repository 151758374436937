[data-wx-uspgrid] {
  $usp-grid-icon-size: gutter(2.5);

  @include flex-display;
  @include align-items(flex-start);

  .usp-grid-icon {
    @include flex-display;
    @include align-items(center);
    @include justify-content(center);
    width: $usp-grid-icon-size;
    height: $usp-grid-icon-size;
    background-color: $c-action-light;
    color: $c-action;
    border-radius: 50%;
  }

  .usp-grid-label {
    @include font-medium;
    font-size: var(--font-size-small);
  }
}
