[data-wx-tabs] {
  $gradient-extension: gutter(2);

  position: relative;
  margin-left: -($grid-container-gutter-lt-md);
  margin-right: -($grid-container-gutter-lt-md);
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: $grid-container-gutter-lt-md + $gradient-extension;
    background-image: linear-gradient(90deg, rgba(var(--c-bg-main), 0), var(--c-bg-main));
  }

  .tabs-nav-prev,
  .tabs-nav-next {
    display: none;
  }

  .tabs {
    padding-left: $grid-container-gutter-lt-md;
    overflow: auto;
    -webkit-overflow-scrolling: touch; // sass-lint:disable-line no-misspelled-properties
  }

  .tab-list {
    &:after {
      content: "";
      flex-shrink: 0;
      display: block;
      width: $grid-container-gutter-lt-md + $gradient-extension;
      height: 1px;
    }
  }

  .tab-action-indicator {
    font-size: 0.75rem;
  }
}

[data-wx-tabs~="boxed"] {
  &:before {
    left: gutter(1);
    right: gutter(1);
  }

  &:after {
    width: $grid-container-gutter-lt-md;
  }

  .tab-list {
    &:after {
      width: $grid-container-gutter-lt-md;
    }
  }
}
