// sass-lint:disable space-around-operator

// Clearfix

@mixin clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

// Keyframes

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }

  @keyframes #{$animation-name} {
    @content;
  }
}

// Sticky

@mixin position-sticky {
  // sass-lint:disable-block no-duplicate-properties
  position: -webkit-sticky;
  position: sticky;
}

// Mask

@mixin mask-image($url) {
  -webkit-mask-image: $url;
  mask-image: $url;
}

@mixin mask-composite($composite: add) {
  -webkit-mask-composite: $composite;
  mask-composite: $composite;
}

@mixin mask-position($position: center) {
  -webkit-mask-position: $position;
  mask-position: $position;
}

@mixin mask-size($size: auto) {
  -webkit-mask-size: $size;
  mask-size: $size;
}

@mixin mask-repeat($repeat: no-repeat) {
  -webkit-mask-repeat: $repeat;
  mask-repeat: $repeat;
}

// Transform

@mixin transform($value...) {
  -webkit-transform: $value;
  -ms-transform: $value;
  transform: $value;
}

// Transform origin

@mixin transform-origin($value) {
  -webkit-transform-origin: $value;
  -ms-transform-origin: $value;
  transform-origin: $value;
}

// Animation

@mixin animation($value...) {
  -webkit-animation: $value;
  animation: $value;
}

@mixin animation-delay($delay: 0s) {
  -webkit-animation-delay: $delay;
  animation-delay: $delay;
}

@mixin animation-direction($direction: normal) {
  -webkit-animation-direction: $direction;
  animation-direction: $direction;
}

@mixin animation-duration($duration: 0s) {
  -webkit-animation-duration: $duration;
  animation-duration: $duration;
}

@mixin animation-fill-mode($fill-mode: none) {
  -webkit-animation-fill-mode: $fill-mode;
  animation-fill-mode: $fill-mode;
}

@mixin animation-iteration-count($iteration-count: 1) {
  -webkit-animation-iteration-count: $iteration-count;
  animation-iteration-count: $iteration-count;
}

@mixin animation-name($name) {
  -webkit-animation-name: $name;
  animation-name: $name;
}

@mixin animation-play-state($play-state: running) {
  -webkit-animation-play-state: $play-state;
  animation-play-state: $play-state;
}

@mixin animation-timing-function($timing-function: ease) {
  -webkit-animation-timing-function: $timing-function;
  animation-timing-function: $timing-function;
}

// Font Size Fallback
// Fallback for browsers where rem does not work

@mixin font-size($size) {
  // sass-lint:disable-block no-duplicate-properties
  font-size: $size;
  font-size: calculate-rem($size);
}

// Opacity
// This mixin adds opacity fallback to work in IE5+

@mixin opacity($opacity) {
  $opacity-ie: $opacity * 100;
  opacity: $opacity;
  filter: alpha(opacity=$opacity-ie);
}

// Unselectable

@mixin unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// User select

@mixin user-select($value) {
  -webkit-user-select: $value;
  -moz-user-select: $value;
  -ms-user-select: $value;
  user-select: $value;
}

// Columns

@mixin columns($value...) {
  -webkit-columns: $value;
  -moz-columns: $value;
  columns: $value;
}

@mixin column-gap($value) {
  -webkit-column-gap: $value;
  -moz-column-gap: $value;
  column-gap: $value;
}

// Flexbox
// Unique value mixins are used with prefix `_` and should only be used within this file.
// Available flexbox related mixins (default value in parentheses):

// flex-display
// inline-flex-display
// flex-direction(row)
// justify-content(flex-start)
// flex-wrap(nowrap)
// flex-basis(auto)
// align-self(stretch)
// align-items(stretch)
// flex(1)
// flex-grow(0)
// flex-shrink(1)

@mixin flex-display {
  // sass-lint:disable-block no-duplicate-properties
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@mixin inline-flex-display {
  // sass-lint:disable-block no-duplicate-properties
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

@mixin flex-order($order: 0) {
  -webkit-box-ordinal-group: ($order + 1);

  -ms-flex-order: $order;
  order: $order;
}

@mixin _flex-direction-prefixes($direction) {
  @if $direction == row {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
  } @else if $direction == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
  } @else if $direction == column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  } @else if $direction == column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
  } @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: $direction;
  }
}

@mixin flex-direction($direction: row) {
  @include _flex-direction-prefixes($direction);
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

@mixin _justify-content-prefixes($justify) {
  @if $justify == center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
  } @else if $justify == flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
  } @else if $justify == flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
  } @else if $justify == space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  } @else if $justify == space-around {
    -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $justify;
    -ms-flex-pack: $justify;
  }
}

@mixin justify-content($justify: flex-start) {
  @include _justify-content-prefixes($justify);
  justify-content: $justify;
}

@mixin flex-wrap($wrap: nowrap) {
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

@mixin flex-basis($width: auto) {
  -ms-flex-preferred-size: 50%;
  flex-basis: $width;
}

@mixin _flex-align-self-prefixes($align) {
  @if $align == flex-start {
    -ms-flex-item-align: start;
  } @else if $align == flex-end {
    -ms-flex-item-align: end;
  } @else {
    -ms-flex-item-align: $align;
  }
}

@mixin align-self($align: stretch) {
  @include _flex-align-self-prefixes($align);
  align-self: $align;
}

@mixin _flex-align-items-prefixes($align) {
  @if $align == flex-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
  } @else if $align == flex-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $align;
    -ms-flex-align: $align;
  }
}

@mixin align-items($align: stretch) {
  @include _flex-align-items-prefixes($align);
  align-items: $align;
}

@mixin flex($flex: 1) {
  -webkit-flex: $flex;
  -ms-flex: $flex;
  flex: $flex;
}

@mixin flex-grow($grow: 0) {
  -ms-flex-positive: $grow;
  -webkit-box-flex: $grow;
  flex-grow: $grow;
}

@mixin flex-shrink($shrink: 1) {
  -ms-flex-negative: $shrink;
  flex-shrink: $shrink;
}

// CSS Grid
// These mixins are used to support CSS Grid in IE10+

@mixin grid-display($display: grid) {
  // sass-lint:disable-block no-duplicate-properties
  display: -ms-#{$display};
  display: #{$display};
}

@mixin grid-template-columns($columns...) {
  -ms-grid-columns: $columns;
  grid-template-columns: $columns;
}

@mixin grid-template-rows($rows...) {
  -ms-grid-rows: $rows;
  grid-template-rows: $rows;
}

@mixin grid-column-span($start, $span) {
  -ms-grid-column: $start;
  -ms-grid-column-span: $span;
  grid-column: $start / span $span;
}

@mixin grid-row-span($start, $span) {
  -ms-grid-row: $start;
  -ms-grid-row-span: $span;
  grid-row: $start / span $span;
}

@mixin grid-align-self($align: auto) {
  -ms-grid-row-align: $align;
  align-self: $align;
}

@mixin grid-justify-self($justify: auto) {
  -ms-grid-column-align: $justify;
  justify-self: $justify;
}
