@use "sass:math";

[data-am-extendgroup] {
  position: relative;
  min-height: 30px; // Height of .extend-button icon
  max-height: 350px;
  overflow: hidden;

  &.is-extended {
    max-height: none;

    .extend-button-container {
      display: none;
    }
  }

  .extend-button-container {
    height: 80px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--c-bg-main);
    -webkit-mask-image: linear-gradient(to top, #000 var(--extend-group-gradient-offset, 60%), transparent);
    mask-image: linear-gradient(to top, #000 var(--extend-group-gradient-offset, 60%), transparent);
    pointer-events: none;
  }

  .extend-button {
    @include transform(translateX(-50%));
    @include font-medium;
    position: absolute;
    bottom: 0;
    left: 50%;
    color: $c-primary;
    font-size: var(--font-size-regular);
    white-space: nowrap;
    pointer-events: auto;

    &:hover {
      text-decoration: underline
    }
  }
}

[data-am-extendgroup~='small'] {
  max-height: 240px;
}

[data-am-extendgroup~='extra-small'] {
  max-height: 140px;
}
