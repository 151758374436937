[data-wx-uspgrid] {
  @include justify-content(space-between);

  .usp-grid-item {
    @include flex-display;
    @include flex-direction(column);
    @include align-items(center);
    padding: 0 gutter(0.25);

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  .usp-grid-label {
    margin-top: gutter(0.5);
    text-align: center;
  }

  .usp-grid-meta {
    margin-top: gutter(0.5);
  }
}
