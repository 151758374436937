[data-wx-avatar] {
  $avatar-size: 40px;

  position: relative;
  width: $avatar-size;
  height: $avatar-size;
  border-radius: 50%;
  background-color: $c-accent;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: $c-primary;

  .avatar-content {
    @include transform(translate(-50%, -50%));
    @include font-medium;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 0.875rem;
    text-align: center;

    [class^='icon-'],
    [class*=' icon-'] {
      &:before {
        display: block;
        line-height: 1;
      }
    }
  }
}

[data-wx-avatar*='placeholder'] {
  position: relative;

  .avatar-content {
    position: relative;
    z-index: 2;
    font-size: 0.75rem;
    color: var(--c-text-media);

    &:after {
      content: attr(data-content);
    }

    + .avatar-icon {
      background-color: $c-fade-dark-30;
    }
  }

  .avatar-icon {
    @include flex-display;
    @include align-items(center);
    @include justify-content(center);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    width: inherit;
    height: inherit;
    font-size: 1rem;
  }
}

[data-wx-avatar~='placeholder'] {
  background-color: $c-primary-light;
}

[data-wx-avatar~='placeholder-secondary'] {
  background-color: $c-action-light;

  .avatar-icon {
    color: $c-action;
  }
}

[data-wx-avatar~='placeholder-transparent'] {
  background-color: $c-fade-light-00;

  .avatar-icon {
    color: $c-ui-success;
  }
}

[data-wx-avatar~='success'] {
  background-color: $c-ui-success-light;
  color: $c-ui-success;
}

[data-wx-avatar~='danger'] {
  background-color: $c-ui-danger-light;
  color: $c-ui-danger;
}

[data-wx-avatar~='small'] {
  $avatar-size-small: 24px;

  width: $avatar-size-small;
  height: $avatar-size-small;

  .avatar-content {
    font-size: 0.65rem;
  }

  &[data-wx-avatar~='placeholder'] {
    .avatar-content {
      font-size: 0.65rem;
    }
  }
}

[data-wx-avatar~='medium'] {
  $avatar-size-medium: 32px;

  width: $avatar-size-medium;
  height: $avatar-size-medium;
}

[data-wx-avatar~='clipped-corner'] {
  @include mask-image(url('../images/masks/notification-mask.svg'));
  @include mask-composite(subtract);
  @include mask-position(0 0);
  @include mask-size(inherit);
  @include mask-repeat(no-repeat);
}

[data-wx-avatar~='clipped-side'] {
  @include mask-image(url('../images/masks/avatar-mask.svg'));
  @include mask-composite(subtract);
  @include mask-position(0 0);
  @include mask-size(inherit);
  @include mask-repeat(no-repeat);
}

[data-wx-avatar~='large'] {
  $avatar-size-large: 64px;

  width: $avatar-size-large;
  height: $avatar-size-large;
}

[data-wx-avatar~='xlarge'] {
  $avatar-size-large: 200px;

  width: $avatar-size-large;
  height: $avatar-size-large;
}

[data-wx-avatar~='center'] {
  margin: 0 auto;
}
