[data-am-link] {
  &:hover {
    text-decoration: underline;
  }
}

[data-am-link~='hover-text-dark-lighten'] {
  &:hover {
    color: var(--c-text-muted);
  }
}
