[data-wx-testimonial] {
  .testimonial-quote {
    @include font-medium;
    margin: 0 auto;
    margin-top: gutter(1.5);
    max-width: 271px;
    text-align: center;
  }

  .testimonial-cite {
    display: block;
    text-align: center;
    font-style: normal;
    margin-top: gutter(0.5);
    font-size: 0.875rem;
  }
}
