// General

@use '../mixins/mixins' as *;

@keyframes no-transform {
  100% {
    @include transform(none);
  }
}

@include keyframes(fadeIn) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@include keyframes(fadeInToTop) {
  0% {
    @include transform(translateY(#{$gutter-half}));
    opacity: 0;
  }

  100% {
    @include transform(none);
    opacity: 1;
  }
}

// Add delay to not run twice in React components
@include keyframes(fadeInToTopDelay) {
  0%,
  50% {
    @include transform(translateY(#{$gutter-half}));
    opacity: 0;
  }

  100% {
    @include transform(none);
    opacity: 1;
  }
}

@include keyframes(fadeInToBottom) {
  0% {
    @include transform(translateY(-#{$gutter-half}));
    opacity: 0;
  }

  100% {
    @include transform(none);
    opacity: 1;
  }
}

// Bounce

@include keyframes(bounceLeft) {
  50% {
    @include transform(translateX(gutter(-0.5)));
  }

  0%,
  100% {
    @include transform(none);
  }
}

// Rotate

@include keyframes(rotate360) {
  100% {
    @include transform(rotate(360deg));
  }
}

// Scale

@include keyframes(scaleUp) {
  0% {
    @include transform(scale(0));
  }

  100% {
    @include transform(scale(1));
  }
}

// Modal

@include keyframes(modalInMobile) {
  0% {
    @include transform(translateY(100%));
  }

  100% {
    @include transform(translateY(0));
  }
}

@include keyframes(modalFadeInBg) {
  0% {
    background-color: $c-fade-dark-00;
  }

  100% {
    background-color: $c-fade-dark-40;
  }
}

// Filter Container

@include keyframes(filterContainerNavSm) {
  0% {
    @include transform(translateX(-100%));
  }

  100% {
    @include transform(translateX(0));
  }
}

@include keyframes(filterContainerModalSm) {
  0% {
    @include transform(translateY(gutter(1)));
  }

  100% {
    @include transform(translateY(0));
  }
}

// Filter Modal

@include keyframes(filterModalInMobile) {
  0% {
    @include transform(translateY(100%));
  }

  100% {
    @include transform(translateY(0));
  }
}
