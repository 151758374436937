[data-wx-filterlist] {
  .filter-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .filter-list-item {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: gutter(1.5);
      right: gutter(1.5);
      height: 1px;
      background-color: var(--c-border-main);
    }

    &:last-child {
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: gutter(1.5);
        right: gutter(1.5);
        height: 1px;
        background-color: var(--c-border-main);
      }
    }

    &.is-active {
      background-color: $c-accent;
    }

    &.is-set {
      .filter-list-btn-label {
        @include font-medium;
      }
    }
  }

  .filter-list-btn {
    @include flex-display;
    @include align-items(center);
    @include justify-content(space-between);
    width: 100%;
    text-align: left;
    padding: gutter(1) gutter(1.5);
  }

  .filter-list-btn-label {
    @include flex-shrink(0);
    font-size: 1rem;
  }

  .filter-list-btn-meta {
    @include flex-display;
    @include align-items(center);
    padding-left: gutter(1);
    min-width: 0; // Required for truncated text with flexbox
    font-size: 0;

    > * {
      font-size: 1rem;
    }
  }

  .filter-list-btn-meta-value {
    display: inline-block;
    vertical-align: middle;
    font-size: 0.875rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .filter-list-btn-meta-icon {
    display: inline-block;
    vertical-align: middle;
    padding-left: gutter(0.5);
  }
}
