[data-wx-stagecard] {
  counter-reset: stageCardCounter;

  .stage-card-list {
    @include flex-display;
    @include flex-wrap(wrap);
    @include align-items(stretch);
    list-style: none;
    padding: 0;
    margin: gutter(-0.5);
  }

  .stage-card-item {
    @include flex(1 1 auto);
    width: 100%;
    max-width: 100%;
    counter-increment: stageCardCounter;
    padding: gutter(0.5);
  }

  .stage-card-wrapper {
    height: 0;
    position: relative;
    padding-bottom: 66.666%;
  }

  .stage-card-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .stage-card-text-wrapper {
    @include flex-display;
    margin-top: 16px;
  }

  .stage-card-info {
    @include flex(1 1 auto);
    padding-top: 8px;
    padding-left: 8px;
  }

  .stage-card-dot {
    @include flex-shrink(0);
    background: $c-action-light;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    flex: 1 0 auto;
    position: relative;

    &:before {
      @include transform(translate(-50%, -50%));
      @include font-bold;
      content: counter(stageCardCounter) ".";
      color: $c-action;
      position: absolute;
      left: 50%;
      top: 50%;
      font-size: 1.125rem;
    }
  }
}
