[data-wx-iconlist] {
  list-style: none;
  padding: 0;
  margin: 0;

  .icon-list-item {
    @include flex-display;

    & + .icon-list-item {
      margin-top: gutter(0.5);
    }
  }

  .icon-list-icon {
    @include flex-shrink(0);
    padding-right: gutter(1);
  }
  .icon-list-text {
    @include flex(1 1 auto);
  }
}
