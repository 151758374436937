[data-wx-locationpicker] {
  .input-labels {
    display: none;
  }

  .location-inputs {
    display: block;
    border-bottom: 0;
  }

  .location-input {
    display: block;

    & + .location-input {
      border-top: 1px solid var(--c-border-main);
      border-left: 0;
    }
  }

  .input {
    font-size: 16px; // Set font size in px to prevent zoom in on iOS
  }

  .location-map {
    padding-bottom: 80%;
  }
}
