[data-wx-hero] {
  @include flex-display;
  @include flex-direction(column);
  background-color: var(--c-contrast-bg);
  color: var(--c-text-media);
  overflow: hidden;

  .hero {
    @include flex(1 1 auto);
    @include flex-display;
    @include flex-direction(column);
    position: relative;
    min-height: 375px;
  }

  .hero-media {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }

  .hero-background-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .hero-video {
    @include transform(translate(-50%, -50%));
    position: absolute;
    display: block;
    top: 50%;
    bottom: 0;
    left: 50%;
    right: 0;
    min-width: 100%;
    min-height: 100%;
  }

  .hero-content {
    @include flex-display;
    @include flex-direction(column);
    @include justify-content(center);
    @include flex(1 0 auto);
    position: relative;
    z-index: 2;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: $container-max-width;
    padding-left: $grid-container-gutter;
    padding-right: $grid-container-gutter;
  }

  .hero-content-header,
  .hero-content-body,
  .hero-content-footer {
    width: 100%;
    max-width: 755px;
  }

  .hero-content-body {
    > * {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .hero-label {
    margin-bottom: gutter(0.5);
  }
}

// Places text in the center
[data-wx-hero~='text-center'] {
  .hero-content-header,
  .hero-content-body,
  .hero-content-footer {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .hero-content {
    text-align: center;
  }
}

// Removes min-height from hero and let the content decide height
[data-wx-hero~='collapse'] {
  .hero {
    min-height: 0;
  }
}

// Thin version of the hero
[data-wx-hero~='thin'] {
  .hero {
    min-height: 300px;
  }
}

// Stretches hero content to the heroes full width
[data-wx-hero~='full-width'] {
  .hero-content-body {
    max-width: none;
  }
}

// Adds a dark transparent layer over the background media
[data-wx-hero~='darken'] {
  .hero-media {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $c-fade-dark-50;
    }
  }
}

// Adds a vertical gradient from top to the background media
[data-wx-hero~='fade-to-bottom'] {
  .hero-media {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: linear-gradient($c-complement-dark, $c-fade-dark-00);
    }
  }
}

// Adds a vertical horizontal from left to the background media
[data-wx-hero~='fade-to-right'] {
  .hero-media {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: linear-gradient(90deg, $c-complement-dark, $c-fade-dark-30);
      opacity: 0.3;
    }
  }
}

// Positions the background image at bottom of the hero
[data-wx-hero~='bg-bottom'] {
  .hero-background-image {
    background-position: bottom;
  }
}

// Moves content below the hero instead of inside it
[data-wx-hero~='content-below'] {
  .hero-media {
    @include flex(1 1 auto);
    position: relative;
  }

  .hero-content {
    @include flex(0 1 auto);
    padding-top: 0;
    padding-bottom: gutter(0.75);
  }

  .hero-background-image {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 33.333%;
      background-image: linear-gradient(rgba(var(--c-overlay-rgb), 0), rgba(var(--c-overlay-rgb), 1));
    }
  }
}

// Adds a light theme to the hero, usally combined with "content-below"
[data-wx-hero~='light'] {
  background-color: var(--c-bg-main);
  color: var(--c-text-main);

  &[data-wx-hero~='content-below'] {
    .hero-content {
      padding-top: gutter(1.5);
      padding-bottom: 0;
    }
  }
}

// Removes min-height from hero when the media has a height
[data-wx-hero~='media-block'] {
  .hero {
    min-height: 0;
  }
}
