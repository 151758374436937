[data-wx-flexorderlayout] {
  @include flex-display;
  margin: gutter(-1);

  .flex-order-layout-box,
  .flex-order-layout-content {
      padding: gutter(1);
  }

  .flex-order-layout-box {
    &-center {
        @include flex-display;
        @include justify-content(center);
    }
  }
}
