[data-wx-messagepreview] {
  position: relative;
  display: block; // Reset default styling if button
  width: 100%; // Reset default styling if button
  font-size: 1rem; // Reset default styling if button
  text-align: left; // Reset default styling if button

  &.is-new {
    .message-header-timestamp {
      @include font-medium;
      color: $c-action;

      &:before {
        $dot-size: 0.5em;

        content: '';
        display: inline-block;
        vertical-align: middle;
        width: $dot-size;
        height: $dot-size;
        border-radius: 50%;
        background-color: currentColor;
        margin-right: gutter(0.25);
      }
    }
  }

  .message-header {
    @include flex-display;
    font-size: 0.875rem;
    margin-bottom: gutter(0.5);
  }

  .message-header-title {
    @include flex(1 1 auto);
    @include font-medium;
  }

  .message-body {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .message-footer {
    margin-top: gutter(1);
    color: var(--c-text-muted);
    font-size: 0.875rem;
  }
}

[data-wx-messagepreview~='boxed'] {
  padding: gutter(1);
  background-color: var(--c-bg-main);
  box-shadow: $g-x-box-shadow;

  .message-header-timestamp {
    &:after {
      content: '\f105';
      font-family: 'icons';
      margin-left: gutter(0.25);
      color: var(--c-text-main);
    }
  }
}
