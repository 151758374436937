[data-wx-filter] {
  .container & {
    margin-left: -($grid-container-gutter-lt-md);
    margin-right: -($grid-container-gutter-lt-md);

    .filter-list {
      padding-left: $grid-container-gutter-lt-md;
      padding-right: $grid-container-gutter-lt-md;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .filter-list {
    padding: gutter(0.75);
    margin: gutter(-0.75);
    overflow: auto;
    -webkit-overflow-scrolling: touch; // sass-lint:disable-line no-misspelled-properties
    white-space: nowrap;
  }

  .filter-utilities,
  .filter-items {
    display: inline-block;
    vertical-align: middle;
  }

  .filter-utilities {
    margin-right: gutter(1);
  }
}
