[data-am-columnblock] {
  .column {
    &:first-child {
      width: 30%;
    }

    &:last-child {
      width: 70%;
    }
  }
}
