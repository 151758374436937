[data-wx-subnavigation] {
  .sub-navigation-item {
    & + .sub-navigation-item {
      margin-top: gutter(1);
    }
  }

  .sub-navigation-link {
    display: block;

    &:hover {
      color: var(--c-text-main);
    }
  }
}
