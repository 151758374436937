[data-wx-convertcard] {
  .convert-card-body {
    & + .convert-card-body {
      border-top: 1px solid var(--c-border-main);
    }

    &--contact {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        gap: gutter(0.5);

        [data-wx-button] {
            @include flex-display;
            @include align-items(center);
            @include justify-content(center);
            hyphens: auto;
            word-break: break-word;
            text-align: left;
        }
    }
  }

  .convert-card-section {
    & + .convert-card-section {
      margin-top: gutter(1);

      &.convert-card-section-thin {
        margin-top: gutter(0.5);
      }
    }
  }

  // Banner

  .convert-card-banner {
    @include font-bold;
    padding: gutter(0.375) gutter(1.5);
    background-color: $c-fade-dark-30;
    color: $c-fade-light-80;
    text-transform: uppercase;
    font-size: 0.625rem;
    letter-spacing: 0.182em;
  }
}
