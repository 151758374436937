[data-wx-inlinespacing] {
  font-size: 0;

  > * {
    font-size: 1rem;
  }

  .inline-spacing-item {
    display: inline-block;
    vertical-align: middle;

    & + .inline-spacing-item {
      margin-left: gutter(0.5);
    }
  }
}
