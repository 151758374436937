[data-am-inputrow] {
  .input-row-item {
    & + .input-row-item {
      margin-top: $gutter;
    }
  }
}

[data-am-inputrow~='always'] {
  .input-row {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  .input-row-item {
    display: table-cell;
    vertical-align: bottom;

    & + .input-row-item {
      padding-left: $gutter-half;
    }
  }
}

[data-am-inputrow~='valign-top'] {
  .input-row-item {
    vertical-align: top;
  }
}

[data-am-inputrow~='valign-middle'] {
  .input-row-item {
    vertical-align: middle;
  }
}

// Makes the action item smaller when a round button is used
[data-am-inputrow~='action-round'] {
  .input-row-item {
    &.input-row-item-action {
      width: ($g-input-height + $gutter-half);
    }
  }
}
