[data-wx-bouncer] {
  .bouncer-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .bouncer-item {
    & + .bouncer-item {
      margin-top: gutter(2);
    }
  }

  .bouncer {
    margin-top: gutter(-0.75);
  }

  .bouncer-link {
    @include flex-display;
    @include align-items(center);
    padding: gutter(0.75) 0;
    color: $c-primary;
    border-bottom: 1px solid var(--c-border-main);

    &:hover {
      .bouncer-heading {
        text-decoration: underline;
      }

      .bouncer-icon {
        @include animation(bounceLeft 400ms ease);
      }
    }
  }

  .bouncer-heading {
    @include flex(1 1 auto);
    @include font-medium;
    font-size: 1.125rem;
  }

  .bouncer-icon {
    @include flex-shrink(0);
    padding-left: gutter(1);
  }

  .bouncer-preamble {
    margin-top: gutter(0.75);
  }
}
