[data-wx-share] {
  $share-btn-size: 32px;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: $share-btn-size;
  height: $share-btn-size;
  line-height: $share-btn-size;
  background-color: $c-accent;
  color: var(--c-text-main);
  border-radius: 50%;
  font-size: 1rem;
  text-align: center;
}
