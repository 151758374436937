@use "sass:math";

@mixin create-range-slider {
  $bar-height: 4px;
  $handle-size: 35px;
  $histogram-height: 80px;

  [data-am-rangeslider] {
    .range-slider {
      padding-left: math.div($handle-size, 2);
      padding-right: math.div($handle-size, 2);
      padding-bottom: math.div(($handle-size - $bar-height), 2);
    }

    .range-input-container {
      font-size: 1rem;
      margin-bottom: $gutter;
    }

    .range-span-low,
    .range-span-high {
      font-size: 0.875rem;
    }

    .input {
      font-size: 16px; // Set font size in px to prevent zoom in on iOS
    }

    .rc-slider-rail {
      width: calc(100% + #{math.div($handle-size, 2)});
    }

    .rc-slider-track {
      margin-left: math.div($handle-size, -2);
      margin-right: math.div($handle-size, -2);
    }

    .rc-slider-handle {
      width: $handle-size;
      height: $handle-size;
      margin-top: math.div(($handle-size - $bar-height), -2);
      margin-left: math.div($handle-size, -2);
    }

    .histogram-chart {
      height: $histogram-height;
    }
  }

  [data-am-rangeslider~='simple'] {
    .range-slider {
      padding-top: math.div(($handle-size - $bar-height), 2);
    }

    .rc-slider-track {
      margin-left: math.div($handle-size, -2);
    }
  }
}
@include create-range-slider;
