// Imports

@import './imports/imports';

// Breakpoints

@media (min-width: $screen-sm) {
  @import './imports/imports-sm';
}

@media (max-width: $screen-lt-sm) {
  @import './imports/imports-lt-sm';
}

@media (min-width: $screen-md) {
  @import './imports/imports-md';
}

// TODO: Examine if this breakpoint is necessary
@media (min-width: $screen-md) and (max-width: $screen-lt-lg) {
  @import './imports/imports-md-lt-lg';
}

@media (max-width: $screen-lt-md) {
  @import './imports/imports-lt-md';
}

@media (min-width: $screen-lg) {
  @import './imports/imports-lg';
}

@media (max-width: $screen-lt-lg) {
  @import './imports/imports-lt-lg';
}

@media (min-width: $screen-xl) {
  @import './imports/imports-xl';
}
