[data-wx-actiongroupcontainer] {
  @include align-items(center);

  .action-group-container-alt {
    @include flex-shrink(0);
    padding-right: gutter(0.25);
  }

  .action-group-container-main {
    @include flex(1 1 auto);
    min-width: 1px; // Fixes overflow
  }
}

[data-wx-actiongroupcontainer~='valign-top'] {
  @include align-items(flex-start);
}
