@use "sass:math";

[data-wx-itemcarddetailed] {
    border: 1px solid var(--c-border-main);
    border-radius: 8px;

    .item-card-body {
        padding: gutter(1);
    }

    .item-card-footer {
        @include flex-display;
        @include justify-content(space-between);
        @include align-items(center);
        padding: math.div(1, 4);
        border-top: 1px solid var(--c-border-main);
    }

    .item-card-action {
        @include flex-shrink(0);
        font-size: 0;
        margin: gutter(-0.75);
        padding-left: gutter(1);

        > * {
            font-size: 1rem;
        }
    }

    .item-card-action-btn {
        display: inline-block;
        vertical-align: middle;
        padding: gutter(0.75);
        font-size: var(--font-size-small);

        [class^="icon-"],
        [class*=" icon-"] {
            display: inline-block;
            line-height: 1;
        }

        .icon-trash {
            color: $c-action;
        }

        .icon-edit {
            color: $c-primary;
        }

        .action-text {
            margin-left: gutter(0.25);
        }
    }

    .item-card-meta-list {
        list-style: none;
        padding: gutter(0.75);
        margin: 0;
    }

    .item-card-meta-item {
        display: inline-block;
        font-weight: var(--font-weight-bold);

        margin-right: gutter(1);

        &:last-child {
            margin-right: 0;
        }

        [class^="icon-"],
        [class*=" icon-"] {
            line-height: 1;
            color: $c-action;
        }
    }
}
