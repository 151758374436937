[data-wx-uploadmedia] {
  $preview-width: 16.6666666667%;

  &.has-upload {
    .upload-btn-container {
      width: $preview-width;
    }
  }

  .upload-btn {
    &:hover {
      .upload-btn-text-action {
        text-decoration: underline;
      }
    }
  }

  .upload-preview-item {
    width: $preview-width;
  }
}
