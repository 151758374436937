[data-wx-offercard] {
  cursor: pointer;

  .offer-card-image-container {
    position: relative;
    height: 0;
    padding-bottom: 66.66666667%;
    background-color: $c-accent;

    & + .offer-card-content {
      margin-top: gutter(1);
    }
  }

  .offer-card-image {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
  }

  .offer-card-heading {
    @include font-bold;
    font-size: 1.125rem;
  }
}
