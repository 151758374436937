@use "sass:math";

[data-am-form] {
  .form-group {
    position: relative;

    > label,
    .label {
      @include font-medium;
      display: block;
      margin-bottom: $gutter-half;
      font-size: 0.875rem;
      color: var(--c-text-main);

      &.text-light {
        color: var(--c-text-media);
      }
    }

    .label-placeholder {
      display: block;
      height: 21px; // Height of label (magic number)
    }

    &.is-required {
      > label,
      .label {
        &:after {
          content: ' *';
          color: $c-ui-danger;
        }
      }
    }

    &.has-error,
    &.has-warning {
      .alert {
        display: block;
        padding-left: $gutter;

        &:before {
          font-family: 'icons';
          position: absolute;
          left: 0;
        }
      }

      .input-selection-group {
        border: 1px solid;
        padding: $gutter-half;
      }
    }

    &.has-error {
      [data-am-inputtext] {
        input,
        textarea,
        .unit,
        .suggestion {
          border-color: $c-ui-danger;
        }
      }

      [data-am-inputtext~='box-shadow'] {
        input,
        textarea {
          border-color: $c-ui-danger;
        }

        &[data-am-inputtext~='prefix'],
        &[data-am-inputtext~='suffix'] {
          border-color: $c-ui-danger;
        }
      }

      .input-selection-group {
        border-color: $c-ui-danger;
      }

      [data-am-select] {
        border-color: $c-ui-danger;
      }

      [data-am-locationpicker] {
        .location-picker {
          border: 1px solid $c-ui-danger;
        }
      }

      .alert {
        color: $c-ui-danger;

        &:before {
          content: '\e86c';
        }
      }
    }

    &.has-warning {
      [data-am-inputtext] {
        input,
        textarea {
          border-color: $c-ui-warning;
        }
      }

      [data-am-inputtext~='box-shadow'] {
        input,
        textarea {
          border-color: $c-ui-warning;
        }

        &[data-am-inputtext~='prefix'],
        &[data-am-inputtext~='suffix'] {
          border-color: $c-ui-warning;
        }
      }

      .input-selection-group {
        border-color: $c-ui-warning;
      }

      [data-am-select] {
        border-color: $c-ui-warning;
      }

      [data-am-locationpicker] {
        .location-picker {
          border: 1px solid $c-ui-warning;
        }
      }

      .alert {
        color: $c-ui-warning;

        &:before {
          content: '\e86c';
        }
      }
    }
  }

  .instruction,
  .alert {
    margin-top: math.div($gutter-half, 2);
  }

  .instruction {
    font-size: 0.875em;
    color: var(--c-text-muted);
  }

  .alert {
    display: none;
    font-size: 0.875rem;
  }

  .link-group {
    height: 50px;
    line-height: 50px;
  }
}
