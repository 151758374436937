[data-wx-itemcontent] {
  .item-section {
    margin-left: -($grid-container-gutter-lt-md);
    margin-right: -($grid-container-gutter-lt-md);
    padding-left: $grid-container-gutter-lt-md;
    padding-right: $grid-container-gutter-lt-md;
    padding-top: gutter(2);
    padding-bottom: gutter(2);
    border-bottom: gutter(0.5) solid $c-accent;

    [data-wx-ad] {
      margin-left: 0; // Remove double negative margin
      margin-right: 0; // Remove double negative margin
    }

    &.item-section-no-padding-lt-md {
        padding: 0;
    }
  }

  // Convert Card

  .item-section-convert-card-part {
    & + .item-section-convert-card-part {
        margin-top: gutter(2);
        margin-left: -($grid-container-gutter-lt-md);
        margin-right: -($grid-container-gutter-lt-md);
        padding-top: gutter(2);
        padding-left: $grid-container-gutter-lt-md;
        padding-right: $grid-container-gutter-lt-md;
        border-top: gutter(0.5) solid $c-accent;
    }
  }

  // Gallery

  .item-section-gallery {
    padding: 0;
    border-bottom: 0;
  }

  .item-section-gallery-controls {
    padding: 0;
    padding-bottom: gutter(1);
    margin: 0;
    margin-top: gutter(1);
    border-bottom: none;

    // Remove extra padding if there is no ad
    & + .item-section {
      margin-top: gutter(-1);
    }

    // Remove extra padding if ad is empty
    & + [data-am-adleeads]:empty {
      margin-top: gutter(-1);
    }
  }

  // Exit state (360 media)

  .btn-exit-state {
    bottom: calc(48px + #{gutter(0.5)}); // TODO: Change! 48px = height of [data-wx-gallerycontrols]
  }

  // Ad

  .item-section-ad {
    padding: 0;
    border-bottom: 0;
  }
}
