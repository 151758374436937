[data-wx-userutility] {
  @include flex-display;
  @include align-items(center);

  .user-utility-gallery {
    @include flex(1 1 auto);
  }

  .user-utility-social {
    @include flex-shrink(0);
    padding-left: gutter(1);
  }

  .user-utility-list {
    @include justify-content(flex-end);
  }
}
