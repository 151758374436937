[data-am-lightbox] {
  $lightbox-max-width: 1440px;

  .lightbox {
    display: block;
  }

  .lightbox-nav-container {
    position: fixed;
    top: 0;
    right: $gutter-half; // lightbox gutter
    height: 100vh;

    @media (min-width: $lightbox-max-width) {
      right: calc(((100vw - #{$lightbox-max-width}) / 2) + #{$gutter-half});
    }
  }
}
