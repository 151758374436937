[data-am-mediabutton] {
  position: relative;

  &:hover {
    .media-button {
        &::before {
            opacity: 1;
        }
    }
  }

  .media-button {
    @include transform(translate(-50%, -50%));
    @include unselectable;
    @include font-medium;
    position: absolute;
    top: 50%;
    left: 50%;
    white-space: nowrap;
    padding: gutter(0.75) gutter(1.5);
    border-radius: 999px;
    background-color: $c-complement;
    color: $c-text-on-complement;
    font-size: 0;

    > * {
      font-size: 0.875rem;
    }

    &::before {
        content: "";
        position: absolute;
        inset: 1px;
        background-color: var(--c-overlay);
        border-radius: 999px;
        mix-blend-mode: soft-light;
        opacity: 0;
        transition: opacity 200ms ease;
    }
  }

  .media-button-icon,
  .media-button-text {
    display: inline-block;
    vertical-align: middle;
  }

  .media-button-icon {
    color: $c-primary;
    margin-right: gutter(0.5);
  }
}
