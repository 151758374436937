[data-am-columnblock] {
  display: table;
  width: 100%;

  .column {
    display: table-cell;
    vertical-align: middle;
    width: 50%;

    &:first-child {
      margin-bottom: 0;
    }

    &:last-child {
      padding-left: $gutter;
    }

    &.valign-top {
      vertical-align: top;
    }

    &.valign-bottom {
      vertical-align: bottom;
    }
  }
}
