[data-wx-floatingheader] {
    @include transform(translateY(100%));
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--c-bg-main);
    box-shadow: 0px -8px 10px 0px rgba(var(--c-overlay-rgb), 0.03), 0px -2px 14px 0px rgba(var(--c-overlay-rgb), 0.03),
        0px -2px 5px 0px rgba(var(--c-overlay-rgb), 0.09);
    z-index: 50;
    transition: transform 400ms ease;

    &.is-visible {
        @include transform(none);
    }

    .floating-header-section {
        padding: gutter(0.75) 0;

        & + .floating-header-section {
            border-top: 1px solid var(--c-border-main);
        }

        &.is-thin {
            padding: gutter(0.625) 0;
        }
    }

    .floating-header-row {
        @include flex-display;
        @include align-items(center);
    }

    .floating-header-col {
        @include flex-shrink(0);

        &:first-child {
            @include flex(1 1 auto);
            min-width: 0;
        }

        &:last-child {
            text-align: right;
        }

        & + .floating-header-col {
            padding-left: gutter(1);
        }
    }

    .floating-header-heading {
        @include flex-display;
        @include align-items(center);
        font-size: 0.875rem;
    }

    .floating-header-heading-main {
        @include flex-shrink(0);
        @include font-bold;
    }

    .floating-header-heading-alt {
        margin-left: gutter(0.5);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .floating-header-usp-list {
        @include inline-flex-display;
        @include align-items(center);
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .floating-header-usp-item {
        @include inline-flex-display;
        @include align-items(center);
        font-size: 0.875rem;

        & + .floating-header-usp-item {
            &:before {
                content: "";
                display: block;
                width: 1px;
                height: 1em;
                margin: 0 gutter(1);
                background-color: var(--c-border-main);
            }
        }
    }

    .floating-header-usp-value {
        @include font-bold;
        margin-left: gutter(0.5);
    }

    .floating-header-action-container {
        @include inline-flex-display;
        @include align-items(center);
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .floating-header-action {
        & + .floating-header-action {
            margin-left: gutter(0.5);
        }
    }
}
