[data-am-avatar] {
  $avatar-size: 160px;

  .avatar-container {
    position: relative;
    background-color: var(--c-bg-main);
    display: block;
    width: $avatar-size;
    height: $avatar-size;
    margin-left: auto;
    margin-right: auto;
    box-shadow: $g-box-shadow;
  }

  .avatar {
    position: absolute;
    left: 0;
    top: 0;
    width: calc(100% - ($gutter-half * 2));
    height: calc(100% - ($gutter-half * 2));
    margin: $gutter-half;
    object-fit: contain;
    vertical-align: bottom;
  }
}

[data-am-avatar~='transparent'] {
  $avatar-size: 160px;

  .avatar-container {
    background-color: $c-fade-light-00;
    box-shadow: none;
    width: auto;
    height: auto;
  }

  .avatar {
    position: relative;
    max-width: 100%;
    width: $avatar-size;
    height: auto;
    margin: 0;
  }
}
