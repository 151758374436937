body {
  // Should be applied when filter-modal is open to prevent input marker bug in iOS 11
  &:has([data-wx-filtercontainer]) {
    position: fixed;
    width: 100%;
  }
}

hr {
  height: 1px;
  width: 100%;
  border: 0;
  margin: 0;
  background-color: var(--c-border-main);

  &.accent {
    background-color: $c-accent;
  }
}

button {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background-color: $c-fade-light-00;
  border: 0;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  line-height: 1.2;
  text-align: center;
  color: currentColor;
  border-color: $c-fade-light-00;
  border-radius: 0;
  font-family: inherit;
  cursor: pointer;
  -webkit-tap-highlight-color: $c-fade-light-10;
}

input,
textarea {
  @include font-light;
  color: var(--c-text-main);

  // Input placeholder styling
  @mixin input-placeholder-style {
    @include font-light;
    color: var(--c-input-placeholder);
  }

  &::-webkit-input-placeholder {
    @include input-placeholder-style;
  }

  &:-moz-placeholder {
    @include input-placeholder-style;
  }

  &::-moz-placeholder {
    @include input-placeholder-style;
  }

  &:-ms-input-placeholder {
    @include input-placeholder-style;
  }
}
