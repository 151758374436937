[data-am-icon] {
  $icon-size: 35px;

  @include inline-flex-display;
  @include align-items(center);
  @include justify-content(center);
  vertical-align: middle;
  width: $icon-size;
  height: $icon-size;
  border-radius: 50%;
  color: var(--c-text-muted);
  transition: 200ms ease;

  [class^='icon-'],
  [class*=' icon-'] {
    display: block;

    &:before {
      display: block;
      margin: 0;
    }
  }
}

// Color

@mixin icon-color($bg, $text) {
  background-color: $bg;
  color: $text;
}

[data-am-icon~='primary'] {
  @include icon-color($c-primary, $c-text-on-primary);
}

[data-am-icon~='success'] {
  @include icon-color($c-ui-success-light, $c-ui-success);
}
