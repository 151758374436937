[data-wx-overflowswipe] {
  .slick-slider {
    @include unselectable;
    position: relative;
    padding-bottom: gutter(1.5);
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    &.slick-initialized {
      .slick-slide {
        display: block;
      }
    }
  }

  [dir="rtl"] & {
    .slick-slide {
      float: right;
    }
  }

  .slick-list,
  .slick-track {
    @include flex-display;
    @include align-items(stretch);
  }

  .slick-list,
  .slick-track {
    @include transform(translate3d(0, 0, 0));
  }

  .slick-track,
  .slick-slide {
    .slick-loading & {
      visibility: hidden;
    }
  }

  .slick-list {
    margin: gutter(-0.25);

    &:focus {
      outline: none;
    }

    &.dragging {
      cursor: pointer;
      cursor: hand;
    }
  }

  .slick-track {
    top: 0;
    left: 0;

    &:before,
    &:after {
      content: '';
      display: table;
    }

    &:after {
      clear: both;
    }
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    padding: gutter(0.25);

    &:focus {
      outline: none;
    }

    &.slick-loading {
      .slick-slide-item {
        display: none;
      }
    }

    &.dragging {
      .slick-slide-item {
        pointer-events: none;
      }
    }

    > div {
      height: 100%;
    }
  }

  // Dots

  .slick-dots {
    $slick-dot-size: gutter(0.5);

    @include flex-display;
    @include align-items(center);
    @include justify-content(center);
    @include transform(translateX(-50%));
    position: absolute;
    left: 50%;
    bottom: 0;
    list-style: none;
    padding: 0;
    margin: gutter(-0.25);

    li {
      padding: gutter(0.25);

      &.slick-active {
        button {
          &:before {
            background-color: $c-action;
            opacity: 1;
          }
        }
      }

      button {
        display: block;
        font-size: 0;
        line-height: 0;
        padding: gutter(0.25);
        margin: gutter(-0.25);

        &:before {
          content: '';
          display: block;
          width: $slick-dot-size;
          height: $slick-dot-size;
          border-radius: 50%;
          background-color: var(--c-text-muted);
          opacity: 0.5;
          transition: background-color 200ms ease, opacity 200ms ease;
        }
      }
    }
  }

  // Custom elements

  .overflow-swipe-carousel {
    position: relative;
  }

  .overflow-swipe-offset-btn {
    position: absolute;
    top: 50%;
    margin-top: gutter(-0.75); // Exclude dots from button alignment
    z-index: 1;

    &.slick-disabled {
      display: none;
    }
  }

  .overflow-swipe-offset-btn-prev {
    @include transform(translate(-50%, -50%));
    left: 0;
  }

  .overflow-swipe-offset-btn-next {
    @include transform(translate(50%, -50%));
    right: 0;
  }
}
