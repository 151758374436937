[data-wx-contentsection] {
  .content-section {
    & + .content-section {
      border-top: 1px solid var(--c-border-main);
    }
  }

  .content-section-header,
  .content-section-body {
    padding-top: gutter(1.5);
    padding-bottom: gutter(1.5);
  }

  // Header

  .content-section-header {
    @include flex-display;
    @include align-items(center);
  }

  .content-section-header-media {
    padding-right: gutter(1.5);
  }

  .content-section-header-content {
    @include flex(1 1 auto);
  }

  .content-section-header-icon {
    padding-left: gutter(1.5);
  }

  .content-section-header-heading {
    @include font-medium;
    font-size: 1rem;

    &.is-big {
      font-size: 1.125rem;
    }
  }

  .content-section-header-sub-heading {
    font-size: 0.875rem;
  }

  // Body

  .content-section-body {
    padding-top: 0;
  }

  // Align with data-wx-page padding
  [data-wx-page] & {
    margin-top: gutter(-1.5);
    margin-bottom: gutter(-1.5);
  }
}
