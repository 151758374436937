[data-am-imbox] {
  position: fixed;
  bottom: $gutter;
  right: $gutter;
  z-index: 10;
  pointer-events: none;

  .wayke-imbox {
    position: relative; // Required since imbox uses position: inherit
    pointer-events: none;

    > * {
      pointer-events: all;
    }
  }

  .imbox-active & {
    z-index: 110;
  }
}
