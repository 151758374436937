[data-wx-uploadmedia] {
  $preview-width: 20%;

  &.has-upload {
    .upload-btn-container {
      width: $preview-width;
    }
  }

  .upload-preview-item {
    width: $preview-width;
  }
}
