// Global variables
// This file contains variables used in multiple components.
// All global variables should be prefixed with `$g-`.

// Border Radius

$g-border-radius-small: 3px;
$g-border-radius: 5px;

// Box Shadow

$g-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
$g-box-shadow-darker: 0 1px 4px rgba(0, 0, 0, 0.2);
$g-box-shadow-darker-inverted: 0 -1px 4px rgba(0, 0, 0, 0.2);
$g-box-shadow-big: 0 1px 5px rgba(0, 0, 0, 0.2);
$g-box-shadow-big-hover: 0 1px 20px rgba(0, 0, 0, 0.2);

$g-x-box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.03), 0 2px 5px rgba(0, 0, 0, 0.1);

// Text Shadow

$g-text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);

// Header

$g-header-height: 90px;
$g-header-height-mobile: 65px;

// Tags

$g-tag-height: 35px;

// Input

$g-input-height: 50px;
$g-input-height-thin: 40px;
$g-input-height-thick: 60px;

$g-input-search-md: 48px;
$g-input-search-lt-md: 40px;

$g-input-selection-size: 12px;
$g-input-selection-size-active: 16px;

// Filter Container

$g-filter-container-animation-speed: 300ms;
