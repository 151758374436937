[data-wx-tabs] {
  position: relative;

  .tabs {
    overflow: hidden;
  }

  .tab-list {
    transition: transform 200ms ease;
  }

  .tabs-nav-prev,
  .tabs-nav-next {
    @include transform(translateY(-50%));
    position: absolute;
    top: 50%;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      width: 50%;
      height: 100%;
      background-color: var(--c-bg-main);
    }
  }

  .tabs-nav-prev {
    left: 0;

    &:before {
      left: 0;
    }
  }

  .tabs-nav-next {
    right: 0;

    &:before {
      right: 0;
    }
  }
}

[data-wx-tabs~='boxed'] {
  .tabs-nav-prev,
  .tabs-nav-next {
    @include flex-display;
    @include align-items(center);
    @include transform(none);
    top: 0;
    bottom: 0;
    padding-bottom: gutter(1);

    &:before {
      width: 200%;
      background-color: transparent;
      pointer-events: none;
    }
  }

  .tabs-nav-prev {
    &:before {
      background-image: linear-gradient(90deg, var(--c-bg-main), $c-fade-light-00)
    }
  }

  .tabs-nav-next {
    &:before {
      background-image: linear-gradient(90deg, $c-fade-light-00, var(--c-bg-main))
    }
  }
}
