[data-wx-conversationpreview] {
  @include flex-display;
  @include align-items(center);

  .conversation-preview-content {
    @include flex(1 1 auto);
  }

  .conversation-preview-action {
    @include flex-shrink(0);
    padding-left: gutter(1);
  }
}
