[data-wx-overflowswipe] {
  .slick-list {
    overflow: hidden;
    margin: gutter(-0.5);
  }

  .slick-slide {
    padding: gutter(0.5);
  }

  .slick-dots {
    li {
      button {
        &:before {
          transition: background-color 300ms ease, opacity 300ms ease;
        }
      }
    }
  }
}
