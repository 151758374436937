[data-am-footer] {
  .footer-nav {
    padding-bottom: $gutter-double;
  }

  .footer-link {
    display: block;
    padding: gutter(0.5);
  }

  .info-container {
    margin-top: 0;
  }

  .footer-logo {
    margin-left: auto;
    margin-right: auto;
    margin-top: $gutter;
    margin-bottom: $gutter;
  }

  .footer-link-small {
    display: block;
    width: 100%;
    padding: gutter(0.5);
    text-align: center;
    line-height: 1.5;

    & + .footer-link-small {
      margin-left: 0;
    }
  }
}
