[data-wx-inputtext] {
  @include flex-display;
  @include align-items(center);

  position: relative;
  background-color: var(--c-bg-main);
  box-shadow: inset 0 1px 3px $c-fade-dark-20;
  border-radius: $g-border-radius-small;

  %input-base {
    @include font-light;
    display: block;
    width: 100%;
    border-radius: $g-border-radius-small;
    margin: 0;
    padding: 0;
    border: 1px solid transparent;
    background-color: transparent;
    box-shadow: none;
    font-family: var(--font-family);
    font-size: 16px; // Set font size in px to prevent zoom in on iOS
    transition: border-color 200ms ease;

    &:focus {
      outline: none;
      border-color: var(--c-border-main);
    }

    &[disabled] {
      background-color: $c-accent;
      color: var(--c-text-muted);
      opacity: 1; // Required to display correct color in Safari

      & ~ .input-text-unit {
        background-color: $c-accent;
        color: var(--c-text-muted);
      }

      & ~ .input-text-ghost {
        display: block;
      }
    }
  }

  input {
    @extend %input-base;
    @include flex(1 1 auto);
    height: $g-input-height;
    padding-left: gutter(1);
    padding-right: gutter(1);
    min-width: 0; // Fixes overflow
  }

  textarea {
    @extend %input-base;
    padding: gutter(1);
    height: 100px;
    min-height: 100px;
    resize: vertical;
  }

  .input-text-unit,
  .input-text-action,
  .input-text-regnr {
    @include flex-display;
    @include align-items(center);
    @include unselectable;
    @include font-light;
    padding-right: gutter(1);
    color: var(--c-text-muted);
    height: $g-input-height;
    border: 1px solid transparent;
    border-left: 0;
    border-top-right-radius: $g-border-radius-small;
    border-bottom-right-radius: $g-border-radius-small;
    transition: border-color 200ms ease;
  }

  .input-text-regnr {
    padding-right: gutter(0.5);
  }

  .input-text-regnr-icon {
    width: 22px;
    height: 34px;
  }

  .input-text-ghost {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: inset 0 1px 3px $c-fade-dark-20;
    border-radius: $g-border-radius-small;
    color: transparent;
    pointer-events: none;
  }
}

[data-wx-inputtext~='action'] {
  input {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:focus {
      & ~ .input-text-action {
        border-color: var(--c-border-main);
      }
    }
  }
}

[data-wx-inputtext~='unit'] {
  input {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:focus {
      & ~ .input-text-unit {
        border-color: var(--c-border-main);
      }
    }
  }
}

[data-wx-inputtext~='unit-inverted'] {
  input {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:focus {
      & ~ .input-text-unit {
        border-color: var(--c-border-main);
      }
    }
  }

  .input-text-unit {
    @include flex-order(-1);
    border: 1px solid transparent;
    border-right: 0;
    padding-left: gutter(1);
    padding-right: 0;
    border-top-left-radius: $g-border-radius-small;
    border-bottom-left-radius: $g-border-radius-small;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

[data-wx-inputtext~='regnr'] {
  input {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:focus {
      & ~ .input-text-unit {
        border-color: var(--c-border-main);
      }
    }
  }
}
