[data-wx-box] {
  border: 1px solid var(--c-border-main);
  padding: gutter(1);
}

[data-wx-box~="extra-padding-medium"] {
  padding: gutter(1.5);
}

[data-wx-box~="extra-padding"] {
  padding: gutter(2);
}

[data-wx-box~="no-padding"] {
  padding: 0;
}

[data-wx-box~="no-border"] {
  border: none;
}

[data-wx-box~="box-shadow"] {
  box-shadow: $g-box-shadow;
  border: none;
}

[data-wx-box~="limit-width"] {
  width: 100%;
  max-width: 415px;
}

[data-wx-box~="accent"] {
  border: none;
  background-color: $c-accent;
}

[data-wx-box~="border-radius"] {
  border-radius: $g-border-radius-small;
}

[data-wx-box~="extra-border-radius"] {
  border-radius: $g-border-radius;
}

[data-wx-box~="border-dashed"] {
  border: 1px dashed var(--c-border-main);
}
