[data-wx-page] {
  .page-section {
    &.page-section-bg {
      padding-top: gutter(2);
      padding-bottom: gutter(2);
      padding-left: $grid-container-gutter-lt-md;
      padding-right: $grid-container-gutter-lt-md;
      margin-left: -($grid-container-gutter-lt-md);
      margin-right: -($grid-container-gutter-lt-md);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-color: $c-accent;
      color: var(--c-text-media);
    }
  }

  // Elements used to create column layout

  .page-column-aside {
   margin-bottom: gutter(2);
  }
}

[data-wx-page~='top-thin'] {
  margin-top: gutter(4.5); // Header height (lt-md)
}

[data-wx-page~='relative-top-thin'] {
  margin-top: 0;
}

[data-wx-page~='no-margin-lt-md'] {
  margin-top: 0;
  margin-bottom: 0;
}
