[data-am-gallerycontrols] {
  margin: 0 gutter(-0.75);

  .gallery-controls-btn {
    padding: 0 gutter(0.75);

    &:not([disabled]):hover {
      color: var(--c-text-main);
    }

    &.is-fullscreen {
      display: none;
    }
  }

  .gallery-controls-btn-label {
    font-size: 0.875rem;
  }
}
