@use "sass:math";

[data-wx-floatingaction] {
  $floating-action-height: gutter(3);

  @include flex-display;
  @include transform(translateY(100%) translateY(gutter(1)));
  position: fixed;
  bottom: gutter(1);
  left: gutter(1);
  right: gutter(1);
  height: $floating-action-height;
  border-radius: math.div($floating-action-height, 2);
  box-shadow: $g-x-box-shadow;
  transition: transform 300ms ease;
  z-index: 50;

  &.is-visible {
    @include transform(none);
  }

  .floating-action {
    @include flex(1 0 25%);
    padding: gutter(0.5);
    min-width: 0;
    background-color: $c-action;
    color: $c-text-on-action;
    line-height: 1.2;
    text-align: center;

    &:first-child {
      padding-left: gutter(1);
      border-top-left-radius: math.div($floating-action-height, 2);
      border-bottom-left-radius: math.div($floating-action-height, 2);
    }

    &:last-child {
      padding-right: gutter(1);
      border-top-right-radius: math.div($floating-action-height, 2);
      border-bottom-right-radius: math.div($floating-action-height, 2);
    }

    &.is-primary {
      background-color: $c-primary;
      color: $c-text-on-primary;
    }

    &.is-light {
        background-color: $c-action-light;
        color: $c-action;
    }

    & + .floating-action {
    border-left: 1px solid $c-fade-light-50;
    }

    &[disabled],
    &.is-disabled {
        opacity: 0.37;
        cursor: not-allowed;
    }
  }

  .floating-action-icon {
    font-size: 1rem;

    & + .floating-action-label {
      margin-top: gutter(0.25);
    }
  }

  .floating-action-label {
    @include font-medium;
    font-size: 0.625rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
