// Theme colors
// To be used as variables in the project.

// Brand Colors
$c-primary:                         var(--c-accent-bg);
$c-primary-light:                   var(--c-accent-alt-bg);
$c-action:                          var(--c-primary-bg);
$c-action-light:                    var(--c-secondary-bg);
$c-complement:                      var(--c-contrast-bg);
$c-complement-dark:                 var(--c-contrast-bg);
$c-accent:                          var(--c-bg-alt);
$c-accent-dark:                     var(--c-skeleton);

$c-text-on-primary:                 var(--c-accent-fg);
$c-text-on-primary-light:           var(--c-accent-alt-fg);
$c-text-on-action:                  var(--c-primary-fg);
$c-text-on-action-light:            var(--c-secondary-fg);
$c-text-on-complement:              var(--c-contrast-fg);
$c-text-on-accent:                  var(--c-text-main);
$c-text-on-accent-dark:             var(--c-text-main);

// UI Colors
$c-ui-success:                      var(--c-semantic-success-500);
$c-ui-success-medium:               var(--c-semantic-success-300);
$c-ui-success-light:                var(--c-semantic-success-100);
$c-ui-danger:                       var(--c-semantic-error-500);
$c-ui-danger-medium:                var(--c-semantic-danger-300);
$c-ui-danger-light:                 var(--c-semantic-error-100);
$c-ui-warning:                      var(--c-semantic-warning-500);
$c-ui-warning-medium:               var(--c-semantic-warning-300);
$c-ui-warning-light:                var(--c-semantic-warning-100);
$c-ui-info:                         var(--c-semantic-info-500);
$c-ui-info-medium:                  var(--c-semantic-info-300);
$c-ui-info-light:                   var(--c-semantic-info-100);

$c-text-on-ui-success:              var(--c-semantic-success-100);
$c-text-on-ui-danger:               var(--c-semantic-error-100);
$c-text-on-ui-warning:              var(--c-semantic-warning-100);

// Fade Dark
$c-fade-dark-00:                    rgba(0, 0, 0, 0);
$c-fade-dark-03:                    rgba(0, 0, 0, 0.03);
$c-fade-dark-05:                    rgba(0, 0, 0, 0.05);
$c-fade-dark-10:                    rgba(0, 0, 0, 0.1);
$c-fade-dark-12:                    rgba(0, 0, 0, 0.12);
$c-fade-dark-14:                    rgba(0, 0, 0, 0.14);
$c-fade-dark-15:                    rgba(0, 0, 0, 0.15);
$c-fade-dark-20:                    rgba(0, 0, 0, 0.2);
$c-fade-dark-30:                    rgba(0, 0, 0, 0.3);
$c-fade-dark-40:                    rgba(0, 0, 0, 0.4);
$c-fade-dark-50:                    rgba(0, 0, 0, 0.5);
$c-fade-dark-60:                    rgba(0, 0, 0, 0.6);
$c-fade-dark-70:                    rgba(0, 0, 0, 0.7);
$c-fade-dark-80:                    rgba(0, 0, 0, 0.8);
$c-fade-dark-90:                    rgba(0, 0, 0, 0.9);

// Fade Light
$c-fade-light-00:                   rgba(255, 255, 255, 0);
$c-fade-light-05:                   rgba(255, 255, 255, 0.05);
$c-fade-light-10:                   rgba(255, 255, 255, 0.1);
$c-fade-light-15:                   rgba(255, 255, 255, 0.15);
$c-fade-light-20:                   rgba(255, 255, 255, 0.2);
$c-fade-light-30:                   rgba(255, 255, 255, 0.3);
$c-fade-light-35:                   rgba(255, 255, 255, 0.35);
$c-fade-light-40:                   rgba(255, 255, 255, 0.4);
$c-fade-light-50:                   rgba(255, 255, 255, 0.5);
$c-fade-light-60:                   rgba(255, 255, 255, 0.6);
$c-fade-light-70:                   rgba(255, 255, 255, 0.7);
$c-fade-light-80:                   rgba(255, 255, 255, 0.8);
$c-fade-light-85:                   rgba(255, 255, 255, 0.85);
$c-fade-light-90:                   rgba(255, 255, 255, 0.9);
$c-fade-light-95:                   rgba(255, 255, 255, 0.95);
