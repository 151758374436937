[data-am-lightbox] {
  $lightbox-nav-height: 60px;

  bottom: 0;
  overflow: hidden;

  .lightbox {
    @include flex-direction(column);
    padding-left: 0;
    padding-right: 0;
  }

  .lightbox-media-container {
    @include flex(1 1 auto);
    overflow: auto;
    -webkit-overflow-scrolling: touch; // sass-lint:disable-line no-misspelled-properties
    width: 100%;
    padding: $gutter-half;
  }

  .lightbox-heading {
    margin-bottom: $gutter-half;
    margin-top: $gutter;
    font-size: 1rem;
  }

  .lightbox-item {
    display: block;
  }

  .lightbox-nav-container {
    @include flex-shrink(0);
    display: block;
    width: 100%;
    height: auto;
    padding: 0;
    overflow: hidden;
  }

  .lightbox-nav-upper {
    @include flex-display;
    @include justify-content(space-between);
    margin-bottom: 0;
    background-color: $c-accent;
  }

  .lightbox-nav {
    @include flex(1 1 auto);
    @include flex-order(-1);
  }

  .lightbox-nav-list {
    padding-left: $gutter-half;
    padding-right: $gutter-half;
    font-size: 0;

    > * {
      font-size: 1rem;
    }
  }

  .lightbox-nav-item {
    display: inline-block;
    vertical-align: middle;

    & + .lightbox-nav-item {
      margin-left: $gutter;
    }

    &.is-current {
      .lightbox-nav-action {
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 4px;
          background-color: $c-action;
        }
      }
    }
  }

  .lightbox-nav-action {
    position: relative;
    height: $lightbox-nav-height;
    padding: 0;
    font-size: 0.875rem;
  }

  .lightbox-close-btn-container {
    margin-bottom: 0;
    padding-right: $gutter-half;
  }

  .lightbox-close-btn {
    height: $lightbox-nav-height;
  }

  .lightbox-close-btn-title {
    display: none;
  }

  .lightbox-close-btn-icon {
    margin-left: 0;
  }

  .lightbox-nav-lower {
    display: none;
  }
}
