@use "sass:math";

[data-wx-hero] {
  .hero {
    min-height: 580px;
  }

  .hero-content {
    padding-top: ($g-header-height + gutter(4));
    padding-bottom: gutter(4);
  }

  .hero-content-header {
    padding-bottom: gutter(2);
  }

  .hero-content-footer {
    padding-top: gutter(2);
  }

  .hero-content-actions {
    font-size: 0;

    > * {
      font-size: 1rem;
    }
  }

  .hero-content-action {
    display: inline-block;
    vertical-align: middle;

    & + .hero-content-action {
      margin-left: gutter(0.25);
    }
  }
}

[data-wx-hero~='even-padding'] {
  .hero-content {
    padding: gutter(4) $grid-container-gutter;
  }
}

// Removes min-height from hero and let the content decide height
[data-wx-hero~='collapse'] {
  $hero-collapsed-gutter: gutter(2.5);

  .hero {
    min-height: 0;
  }

  .hero-content {
    padding-top: (($g-header-height - math.div(($g-header-height - $g-input-search-md), 2)) + $hero-collapsed-gutter);
    padding-bottom: $hero-collapsed-gutter;
  }
}

// Thin version of the hero
[data-wx-hero~='thin'] {
  .hero {
    min-height: 485px;
  }
}

// Moves content below the hero instead of inside it
[data-wx-hero~='content-below'] {
  .hero-content {
    padding-top: 0;
    padding-bottom: gutter(1);
  }
}

// Removes min-height from hero when the media has a height
[data-wx-hero~='media-block'] {
  .hero {
    min-height: 0;
  }
}
