[data-wx-filter] {
  .filter-list {
    @include flex-display;
    align-items: center;
    width: 100%;
  }

  .filter-utilities {
    @include flex-shrink(0);
    padding-left: gutter(2);
  }

  .filter-items {
    @include flex(1 1 auto);
    @include flex-order(-1);
    min-width: 1px; // Fixes overflow
    position: relative;
  }

  .filter {
    &.is-clear {
      margin-right: 0;

      .filter-action {
        &:hover {
          color: var(--c-text-main);
        }
      }
    }
  }

  .filter-group-action {
    &:hover {
      color: var(--c-text-main);
    }
  }

  .filter-action {
    // sass-lint:disable-block nesting-depth
    &.is-remove {
      &:hover {
        [class^='icon-'],
        [class^=' icon-'] {
          &:after {
            display: block;
          }
        }
      }
    }
  }
}

[data-wx-filter~='small'] {
  .filter {
    &:hover {
      background-color: $c-action;
    }

    &.is-excluded {
      &:hover {
        background-color: $c-action-light;
      }
    }
  }
}
