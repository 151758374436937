[data-am-inputtext] {
  position: relative;

  %input-base {
    display: block;
    width: 100%;
    border-radius: $g-border-radius-small;
    border: 1px solid var(--c-border-main);
    background-color: var(--c-bg-main);
    font-size: 16px; // Set font size in px to prevent zoom in on iOS
    transition: border-color 200ms ease;

    &:focus {
      outline: none;
      border-color: var(--c-input-border-focus);
    }

    &[disabled] {
      background-color: $c-accent;
      color: var(--c-text-muted);
      opacity: 1; // Required to display correct color in Safari
    }
  }

  input {
    @extend %input-base;
    height: $g-input-height;
    padding-left: ($gutter-half * 1.5);
    padding-right: ($gutter-half * 1.5);
  }

  textarea {
    @extend %input-base;
    padding: ($gutter-half * 1.5);
    min-height: 200px;
    resize: vertical;

    &[disabled] {
      resize: none;
    }
  }

  .input-spinner {
    @include transform(translateY(-50%));
    position: absolute;
    top: 50%;
    right: ($gutter-half * 1.5);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: calc((#{$gutter-half * 1.5} - 1px) * -1);
      left: -($gutter);
      background-image: linear-gradient(90deg, $c-fade-light-00, var(--c-bg-main) 40%);
    }
  }
}

// Input size

[data-am-inputtext~='zip'] {
  max-width: 100px;
}

[data-am-inputtext~='textarea-fixed'] {
  textarea {
    resize: none;
  }
}

[data-am-inputtext~='textarea-thin'] {
  textarea {
    min-height: 100px;
  }
}

[data-am-inputtext~='input-thick'] {
  input {
    height: $g-input-height-thick;
  }

  &[data-am-inputtext~='action'] {
    .action {
      height: $g-input-height-thick;
    }
  }
}

// Prefix & Suffix

[data-am-inputtext~='prefix'],
[data-am-inputtext~='suffix'] {
  display: table;
  width: 100%;

  input,
  textarea,
  .unit {
    display: table-cell;
    vertical-align: middle;
  }

  input,
  textarea {
    &:focus {
      + .unit {
        border-color: var(--c-input-border-focus);
      }
    }
  }

  .unit {
    @include font-medium;
    @include unselectable;
    width: 1px;
    white-space: nowrap;
    padding-left: ($gutter-half * 1.5);
    padding-right: ($gutter-half * 1.5);
    background-color: $c-accent;
    color: var(--c-text-muted);
    border: 1px solid var(--c-border-main);
    font-size: 0.875rem;
    cursor: default;
    transition: border-color 200ms ease;
  }
}

[data-am-inputtext~='prefix'] {
  @include transform(rotate(180deg));

  input,
  textarea,
  .unit {
    @include transform(rotate(180deg));
  }

  input,
  textarea {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .unit {
    border-right: 0;
    border-top-left-radius: $g-border-radius-small;
    border-bottom-left-radius: $g-border-radius-small;
  }
}

[data-am-inputtext~='suffix'] {
  input,
  textarea {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .unit {
    border-left: 0;
    border-top-right-radius: $g-border-radius-small;
    border-bottom-right-radius: $g-border-radius-small;
  }
}

// Action

[data-am-inputtext~='action'] {
  display: table;
  width: 100%;
  border-radius: $g-border-radius-small;

  input,
  textarea,
  .action-container {
    display: table-cell;
    vertical-align: middle;
  }

  input,
  textarea {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;

    &:focus {
      border-right-color: var(--c-border-main);

      + .action-container {
        border-color: var(--c-input-border-focus);
      }
    }
  }

  .action-container {
    @include unselectable;
    width: 1px;
    white-space: nowrap;
    border: 0;
    cursor: default;
    transition: border-color 200ms ease;
  }

  .action {
    @include font-medium;
    height: $g-input-height;
    padding: 0 ($gutter-half * 1.5);
    background-color: $c-primary;
    color: $c-text-on-primary;
    font-size: 0.875rem;
    border-top-right-radius: $g-border-radius-small;
    border-bottom-right-radius: $g-border-radius-small;
    transition: background-color 200ms ease;
  }
}

// Content Suggestion
[data-am-inputtext~='suggestion'] {
  @include transform(rotate(180deg));

  input,
  textarea,
  .suggestion {
    @include transform(rotate(-180deg));
  }

  input,
  textarea {
    &:focus {
      + .suggestion {
        border-color: var(--c-input-border-focus);
      }
    }
  }

  input {
    border-radius: 0;
    border-bottom-left-radius: $g-border-radius-small;
    border-bottom-right-radius: $g-border-radius-small;
  }

  .suggestion {
    background-color: $c-accent;
    color: var(--c-text-muted);
    padding: ($gutter-half * 1.5);
    border: 1px solid var(--c-border-main);
    border-top-left-radius: $g-border-radius-small;
    border-top-right-radius: $g-border-radius-small;
    margin-top: -1px; // Removes border between suggestion and input
    transition: border-color 200ms ease;
  }

  .suggestion-remove-btn {
    @include font-label;
    color: $c-primary;

    &:hover {
      color: $c-complement;
    }
  }
}

// Box Shadow

[data-am-inputtext~='box-shadow'] {
  box-shadow: $g-box-shadow-darker;
  border-radius: $g-border-radius-small;

  input,
  textarea {
    border: 1px solid var(--c-bg-main);

    &[disabled] {
      border-color: $c-accent;
    }

    &:focus {
      border-color: var(--c-bg-main);
    }
  }

  // Combined with other modifiers

  &[data-am-inputtext~='prefix'],
  &[data-am-inputtext~='suffix'] {
    border: 1px solid var(--c-bg-main);

    input,
    textarea {
      border: 0;
    }

    input {
      height: ($g-input-height - 2px); // Removes height of border
    }

    .unit {
      position: relative;
      background-color: var(--c-bg-main);
      border: 0;
      z-index: 1;
      padding-left: $gutter;
      padding-right: $gutter;
      font-size: 0.875rem;

      &:before {
        content: '';
        position: absolute;
        top: $gutter-half;
        bottom: $gutter-half;
        left: $gutter-half;
        right: $gutter-half;
        background-color: $c-accent;
        border-radius: $g-border-radius;
        z-index: -1;
      }
    }
  }

  &[data-am-inputtext~='prefix'] {
    box-shadow: $g-box-shadow-darker-inverted;

    input,
    textarea {
      padding-left: 0;
    }
  }

  &[data-am-inputtext~='suffix'] {
    input,
    textarea {
      padding-right: 0;
    }
  }

  &[data-am-inputtext~='suggestion'] {
    box-shadow: 0 -1px 5px $c-fade-dark-20;

    input,
    textarea {
      &:focus {
        + .suggestion {
          border-color: $c-accent;
        }
      }
    }

    .suggestion {
      border-color: $c-accent;
    }
  }
}
