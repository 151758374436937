[data-wx-video] {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;

  .video-player {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
  }
}