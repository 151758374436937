[data-wx-filtertogglelist] {
  padding-top: gutter(0.75);
  padding-bottom: gutter(0.75);

  .filter-toggle-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .filter-toggle-list-item {
    @include flex-display;
    @include align-items(center);
    padding: gutter(0.75) gutter(1.5);
  }

  .filter-toggle-list-switch {
    @include flex-shrink(0);
    padding-right: gutter(0.5);
  }

  .filter-toggle-list-label {
    @include flex(1 1 auto);
  }
}
