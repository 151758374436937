[data-wx-pricelist] {
  .price-list-row {
    @include flex-display;
    @include align-items(center);
    @include justify-content(space-between);

    & + .price-list-row {
      margin-top: gutter(0.25);

      &.is-reduced {
        margin-top: 0;
      }
    }

    &.is-reduced {
      .price-list-label,
      .price-list-value {
        @include font-light;
        font-size: 0.875rem;
        color: var(--c-text-muted);
      }

      .price-list-value {
        text-decoration: line-through;
      }
    }
  }

  .price-list-label {
    @include font-medium;
    font-size: var(--font-size-small);
  }

  .price-list-value {
    @include font-bold;
    text-align: right;
    font-size: var(--font-size-large);
  }
}
