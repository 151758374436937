[data-wx-locationpicker] {
    .locationpicker-form-input {
        flex: 1 1 auto;

        & + .locationpicker-form-input {
            margin-top: gutter(1);
        }
    }

    .location-map {
        height: 0;
        padding-bottom: 56.25%;
        background-color: $c-accent;
        margin-top: gutter(1);
    }
}
