[data-wx-form] {
  .form-group {
    &.is-half {
      width: 50%;
    }
  }

  .form-group-row {
    margin-left: gutter(-0.5);
    margin-right: gutter(-0.5);

    .form-group {
      &.is-half {
        padding-left: gutter(0.5);
        padding-right: gutter(0.5);
        margin-top: 0;
      }
    }
  }
}
