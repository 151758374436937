[data-wx-carfax] {
  @include flex-display;
  @include align-items(center);

  .carfax-info {
    @include flex-display;
    @include align-items(center);
  }

  .carfax-logo {
    padding-right: gutter(0.75);
  }

  .carfax-action {
    padding-left: gutter(0.75);
  }
}
