[data-am-inputrow] {
  text-align: left;
}

[data-am-inputrow~='valign-top'] {
  .input-row-item {
    vertical-align: top;
  }
}

[data-am-inputrow~='valign-middle'] {
  .input-row-item {
    vertical-align: middle;
  }
}
