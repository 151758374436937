@use "sass:math";

[data-wx-gallerylist] {
  .gallery-list {
    list-style: none;
    padding: 0;
    margin: math.div(gutter(1), -4);
    font-size: 0;

    > * {
      font-size: 1rem;
    }
  }

  .gallery-item {
    display: inline-block;
    vertical-align: middle;
    width: 25%;
    padding: math.div(gutter(1), 4);
  }

  .gallery-img-container {
    height: 0;
    overflow: hidden;
    padding-bottom: 100%;
    position: relative;
  }

  .gallery-img {
    height: 100%;
    left: auto;
    object-fit: cover;
    position: absolute;
    top: auto;
    transform: none;
    width: 100%;
    border-radius: 8px;
  }

  .gallery-img-label {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $c-fade-dark-80;
    border-radius: 8px;
  }

  .gallery-img-label-text {
    @include transform(translateY(-50%));
    @include font-bold;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    padding: gutter(0.5) gutter(1);
    color: var(--c-text-media);
    text-align: center;
  }
}

[data-wx-gallerylist~="full-width"] {
  .gallery-list {
    @include flex-display;
    @include flex-direction(column);
    margin: math.div(gutter(1), -2)
  }

  .gallery-img-container {
    padding-bottom: 66.666%;
  }

  .gallery-item {
    width: 100%;
    padding: math.div(gutter(1), 2);
  }

  .gallery-img {
    border-radius: 0;
  }
}
