[data-wx-filterpanel] {
  height: auto;
  max-height: 100%;

  // Header

  .filter-panel-header-btn {
    padding: 0 gutter(1);
  }

  .filter-panel-header-btn-label {
    font-size: 0.875rem;
  }

  // Body Header

  .filter-panel-body-header-section {
    padding: gutter(1);
  }

  // Body Footer

  .filter-panel-body-footer {
    padding: gutter(1);
  }

  .filter-panel-body-footer-action {
    padding-left: gutter(0.25);
    padding-right: gutter(0.25);

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

[data-wx-filterpanel~='map'] {
  height: 100%;

  // Requires flexbox to give [data-wx-filtermap] height in Safari
  .filter-panel-body-content {
    @include flex-display;
    @include flex-direction(column);
  }

  .filter-panel-body-footer {
    padding-top: 0;
  }
}
