@use "sass:math";

[data-wx-slider] {
  $slider-dot-size: 6px;

  position: relative;

  .slider-container {
    overflow: hidden;
    padding-bottom: gutter();
    margin-bottom: gutter(-1);
  }

  .slider {
    @include flex-display;
    @include flex-direction(row);
    font-size: 0;
    padding-left: 0;
    transition: transform 400ms ease;
    margin-right: gutter(-0.5);

    > * {
      font-size: 1rem;
      white-space: normal;
    }
  }

  .slide {
    // sass-lint:disable-block mixins-before-declarations
    cursor: move;
    display: inline-block;
    @include flex-display;
    @include flex-direction(column);
    @include flex(0 0 auto);
    @include justify-content(center);
    vertical-align: top;
    position: relative;
    z-index: 2;
    padding: gutter(2) 25%;
    text-align: center;
    background-position: center;
    background-size: cover;

    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(225deg, $c-action, $c-primary);
      opacity: 0.6;

      @supports (mix-blend-mode: multiply) {
        mix-blend-mode: multiply;
        opacity: 1;
      }
    }
  }

  .slide--light {
    color: var(--c-text-media);
  }

  .slide-content {
    position: relative;
    z-index: 2;
  }

  .slider-nav {
    @include flex-display;
    @include justify-content(center);
    margin: 0;
    margin-bottom: gutter(2);
    padding: 0;
    list-style-type: none;
  }

  .slider-nav-dot {
    cursor: pointer;
    position: relative;
    display: block;
    width: $slider-dot-size;
    height: $slider-dot-size;
    margin: math.div($slider-dot-size, -3) 0;
    padding: math.div($slider-dot-size, 3);
    opacity: 0.5;
    box-sizing: content-box;

    &:after {
      content: '';
      position: absolute;
      top: math.div($slider-dot-size, 3);
      left: math.div($slider-dot-size, 3);
      width: $slider-dot-size;
      height: $slider-dot-size;
      background-color: var(--c-bg-main);
      border-radius: 50%;
    }

    &:hover {
      opacity: 0.7;
    }

    &.is-active {
      opacity: 1;
    }
  }
}

@for $i from 1 through 6 {
  [data-wx-slider~='desktop-#{$i}'] {
    .slider {
      @include flex-wrap(nowrap);
    }

    .slide {
      width: math.div(100%, $i);
    }

    $max-offsets: 8;

    @for $j from 0 through $max-offsets {
      &[data-offset='#{$j}'] {
        .slider {
          @include transform(translateX(-(math.div(100%, $i) * $j)));
        }
      }
    }
  }
}
