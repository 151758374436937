@use "sass:math";

[data-am-footer] {
  .footer-nav {
    text-align: center;
  }

  .footer-action {
    margin-left: math.div($gutter-half, 2);
    margin-right: math.div($gutter-half, 2);
  }
}