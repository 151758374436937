[data-am-select] {
  transition: border-color 200ms ease;

  &:hover {
    border-color: var(--c-input-border-focus);
  }
}

[data-am-select~='box-shadow'] {
  &:hover {
    border-color: var(--c-bg-main);
  }
}

[data-am-select~='simple'] {
  &:hover {
    &:before {
      color: $c-primary;
    }
  }
}
