@use "sass:math";

// Gutter

$gutter:              20px;
$gutter-half:         math.div($gutter, 2);
$gutter-double:       ($gutter * 2);
$gutter-triple:       ($gutter * 3);
$gutter-quadruple:    ($gutter * 4);

// Gutter (Project X)
// NOTE: These gutters will replace normal gutter

$x-gutter: 16px;

@function gutter($multiplier: 1) {
  @return ($x-gutter * $multiplier);
}

// Grid gutter
// Set grid gutter as global variables to be usable in all components

$grid-gutter: gutter(1);
$grid-gutter-lt-md: gutter(1);
$grid-container-gutter: gutter(3);
$grid-container-gutter-lt-md: gutter(1);