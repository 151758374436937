[data-wx-carfax] {
  @include flex-display;
  @include align-items(center);

  .carfax-info {
    @include flex(1 1 auto);
  }

  .carfax-logo {
    margin-bottom: gutter(0.25);
  }

  .carfax-action {
    padding-left: gutter(1);
  }
}
