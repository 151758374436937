[data-wx-textregnr=""] {
  @include flex-display;

  .block-text {
    margin: auto 8px;
    @include font-bold;
    font-size: 0.625rem;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    color: $c-fade-dark-70;
  }

  .text-regnr-icon {
    width: 16px;
    height: 24px;
  }
}
