[data-wx-actionbutton] {
  $action-button-size: 36px;

  @include animation(fadeIn 400ms ease);
  @include transform-origin(50% 50%);
  @include flex-display;
  @include align-items(center);
  @include justify-content(center);
  width: $action-button-size;
  height: $action-button-size;
  box-shadow: $g-box-shadow-big;
  background-color: var(--c-bg-main);
  color: var(--c-text-main);
  border-radius: 50%;
  font-size: 1rem;
  transition: transform 200ms ease;
  cursor: pointer;

  &:active {
    @include transform(scale(0.95));
  }

  [class^='icon-'],
  [class*=' icon-'] {
    display: block;
    line-height: 1;

    &:before {
      display: block;
      margin: 0 auto;
    }
  }
}

[data-wx-actionbutton~='action'] {
  background-color: $c-action;
  color: $c-text-on-action;
}

[data-wx-actionbutton~='transparent-dark'] {
  background-color: $c-fade-dark-60;
  color: var(--c-text-media);
  border: 2px solid $c-fade-light-10;
  background-clip: padding-box;
}

[data-wx-actionbutton~='big'] {
  $action-button-size-big: 48px;

  width: $action-button-size-big;
  height: $action-button-size-big;
  font-size: 18px;
}

[data-wx-actionbutton~='huge'] {
  $action-button-size-big: 48px;

  width: $action-button-size-big;
  height: $action-button-size-big;
  font-size: 18px;
}
