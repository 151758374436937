[data-wx-actiongroup] {
  position: relative;

  .action-group-nav {
    position: absolute;
    top: 50%;

    &:before {
      content: '';
      position: absolute;
      top: gutter(-0.5);
      bottom: gutter(-0.5);
      width: 200%;
      pointer-events: none;
    }
  }

  .action-group-nav-prev {
    @include transform(translateY(-50%));
    left: 0;
    display: none;

    &:before {
      left: 0;
      background-image: linear-gradient(90deg, var(--c-bg-main) gutter(1), $c-fade-light-00);
    }
  }

  .action-group-nav-next {
    @include transform(translate(50%, -50%));
    right: 0;

    &:before {
      right: 0;
      background-image: linear-gradient(-90deg, var(--c-bg-main) gutter(1), $c-fade-light-00);
    }
  }
}
