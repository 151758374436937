@use "sass:list";
@use "sass:map";

// Resets

.reset-webkit-overflow-scrolling {
  * {
    -webkit-overflow-scrolling: auto !important; // sass-lint:disable-line no-misspelled-properties
  }
}

// Text

.text-left            { text-align: left; }
.text-right           { text-align: right; }
.text-center          { text-align: center; }

.text-uppercase       { text-transform: uppercase; }
.text-italic          { font-style: italic; }

.text-icon-fix        { font-size: 15px; } // Renders fonts better on Windows

.line-height-1        { line-height: 1; }
.line-height-smaller  { line-height: 1.2; }
.line-height-default  { line-height: 1.5; }
.line-height-bigger   { line-height: 2; }

.word-break-all       { word-break: break-all; }
.word-break-word      { word-break: break-word; }
.hyphens-auto {
  overflow-wrap: break-word;
  hyphens: auto;
}

.white-space-nowrap   { white-space: nowrap; }
.white-space-normal   { white-space: normal; }

.text-line-through    { text-decoration: line-through; }

// Font Family

.font-light           { @include font-light; }
.font-regular         { @include font-regular; }
.font-medium          { @include font-medium; }
.font-bold            { @include font-bold; }

// Text Colors

.text-dark            { color: var(--c-text-main); }
.text-light           { color: var(--c-text-media) }
.text-dark-lighten    { color: var(--c-text-muted); }
.text-primary         { color: $c-primary; }
.text-primary-light   { color: $c-primary-light; }
.text-action          { color: $c-action; }
.text-ui-success      { color: $c-ui-success; }
.text-ui-danger       { color: $c-ui-danger; }
.text-ui-warning      { color: $c-ui-warning; }
.text-faded-light     { color: $c-fade-light-70; }
.text-faded-dark      { color: $c-fade-dark-15; }

// Text Style

.text-shadow          { text-shadow: $g-text-shadow; }
.text-underline       { text-decoration: underline; }

// Vertical Align

.valign-top           { vertical-align: top; }
.valign-bottom        { vertical-align: bottom !important; }
.valign-middle        { vertical-align: middle; }
.valign-baseline      { vertical-align: baseline; }

// Layout

.l-block            { display: block; }
.l-inline-block     { display: inline-block; }

.l-relative         { position: relative; }
.l-absolute         { position: absolute; }
.l-fixed            { position: fixed; }

.l-full             { width: 100%; }
.l-max-full         { max-width: 100%; }

// Hide

.hidden             { display: none !important; }

// Overflow

.overflow-hidden    { overflow: hidden; }

.overflow-hidden-hard {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

// Float

.float-left         { float: left; }
.float-right        { float: right; }
.float-none         { float: none; }

// Margin & Padding

.no-margin          { margin: 0 !important; }
.no-padding         { padding: 0 !important; }

// Z-index

.z-index-reset      { z-index: 0 !important; }
.z-index-1          { z-index: 1; }
.z-index-5          { z-index: 5; }

// Border

.border             { border: 1px solid var(--c-border-main); }
.border-top         { border-top: 1px solid var(--c-border-main); }
.border-bottom      { border-bottom: 1px solid var(--c-border-main); }
.border-left        { border-left: 1px solid var(--c-border-main); }
.border-right       { border-right: 1px solid var(--c-border-main); }

// Border Radius

.border-radius        { border-radius: $g-border-radius; }
.border-radius-small  { border-radius: $g-border-radius-small; }

// Box Shadow

.box-shadow           { box-shadow: $g-box-shadow; }

// Hover Effects

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

.no-tap-highlight {
  -webkit-tap-highlight-color: $c-fade-dark-00;
}

// Table

.table {
  display: table;
  width: 100%;
}

.table-layout-fixed {
  table-layout: fixed;
}

.thead {
  display: table-header-group;
}

.tbody {
  display: table-row-group;
}

.tr {
  display: table-row;
}

.td,
.th {
  display: table-cell;

  &.th-minimal,
  &.td-minimal {
    width: 1px;
  }
}

// Image

.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// Background Colors

@mixin create-util-bg {
  // This mixin generates background color utility classes.
  // Each class will be prefixed with `bg-` followed by the color name.
  // Example of class construction: .bg-primary

  // Update this map with the background colors.
  // Pass a list of backgroud color and text color to each key.

  $background-colors: (
    default:                (var(--c-bg-main), var(--c-text-main)),
    primary:                ($c-primary, $c-text-on-primary),
    primary-light:          ($c-primary-light, $c-text-on-primary-light),
    action:                 ($c-action, $c-text-on-action),
    action-light:           ($c-action-light, $c-text-on-action-light),
    complement:             ($c-complement, $c-text-on-complement),
    accent:                 ($c-accent, $c-text-on-accent),
    accent-dark:            ($c-accent-dark, $c-text-on-accent-dark),
    ui-success:             ($c-ui-success, $c-text-on-ui-success),
    ui-warning:             ($c-ui-warning, $c-text-on-ui-warning),
    ui-danger:              ($c-ui-danger, $c-text-on-ui-danger)
  );

  @each $key, $index in $background-colors {
    $bg: list.nth($index, 1);
    $color: list.nth($index, 2);

    @if $key == default {
      .bg {
        background-color: $bg;
        color: $color;
      }
    } @else {
      .bg-#{$key} {
        background-color: $bg;
        color: $color;
      }
    }
  }
}
@include create-util-bg;

// Text Sizes

@mixin crate-font-sizes {
  // This mixin generates utility classes used for text size.

  // To add a text size in the html, use class name 'font-size-' followed by the suffix (key in $text-sizes).
  // Example of class construction: .text-tiny, .text-small etc.

  $text-sizes: (
    tiny: 0.75,
    small: 0.875,
    default: 1,
    big: 1.125,
    xbig: 1.375,
  );

  // Add more units by adding the unit to this list.
  // All additional units will be added as a suffix to the class name (`-unit`)
  // The first value in the list will be the default unit and will not have a suffix to its class name.
  // Example of class construction with custom unit (rem): .text-tiny-rem, .text-small-rem etc.

  $text-units: (
    em,
    rem
  );

  @each $suffix in map.keys($text-sizes) {
    $size: map.get($text-sizes, $suffix);

    @each $unit in $text-units {
      @if list.index($text-units, $unit) == 1 {
        .font-size-#{$suffix} {
          font-size: #{$size}#{$unit};
        }
      } @else {
        .font-size-#{$suffix}-#{$unit} {
          font-size: #{$size}#{$unit};
        }
      }
    }
  }
}
@include crate-font-sizes;

// Spacing Utility Classes

@mixin create-spacing-classes {

  // This mixin generates utility classes used for spacing.

  // Suffixes: none (all), t (top), b (bottom), l (left), r (right), x (left + right), y (top + bottom).
  // To add sizes, add a new key/value (size suffix/gutter) to the $spacing-sizes map.
  // To add propersies, add a news key/value (property suffix/CSS property) to the $spacing-properties map.
  // Example of class construction: .m-b-half, .m-b, .m-b-double etc.

  // To use an unsuffixed classname, set key to 'none'

  $spacing-sizes: (
    mini: gutter(0.25),
    half: gutter(0.5),
    none: gutter(1),
    double: gutter(2),
    triple: gutter(3),
    quadruple: gutter(4)
  );

  $spacing-properties: (
    m: margin,
    p: padding
  );

  @each $suffix in map.keys($spacing-sizes) {
    $size: map.get($spacing-sizes, $suffix);

    // Class name suffix
    @if $suffix == none {
      $suffix: '';
    } @else {
      $suffix: '-#{$suffix}';
    }

    @each $preperty-name in map.keys($spacing-properties) {
      $property:   map.get($spacing-properties, $preperty-name);

      // Generate spacing classes
      // sass-lint:disable-block one-declaration-per-line
      .#{$preperty-name}#{$suffix} { #{$property}: $size; }
      .#{$preperty-name}-t#{$suffix} { #{$property}-top: $size; }
      .#{$preperty-name}-b#{$suffix} { #{$property}-bottom: $size; }
      .#{$preperty-name}-l#{$suffix} { #{$property}-left: $size; }
      .#{$preperty-name}-r#{$suffix} { #{$property}-right: $size; }
      .#{$preperty-name}-x#{$suffix} { #{$property}-left: $size; #{$property}-right: $size; }
      .#{$preperty-name}-y#{$suffix} { #{$property}-top: $size; #{$property}-bottom: $size; }
    }
  }

  // Repeatable content
  // These classes are used to add margin between repeatable content
  [class*='repeat-m'] {
    @each $suffix in map.keys($spacing-sizes) {
      $size: map.get($spacing-sizes, $suffix);

      // Class name suffix
      @if $suffix == none {
        $suffix: '';
      } @else {
        $suffix: '-#{$suffix}';
      }

      & + .repeat-m#{$suffix} {
        margin-top: $size;
      }
    }
  }
}
@include create-spacing-classes;
