// Thin version of the hero
[data-wx-offsetcontentblock] {
  @include flex-display;
  @include flex-direction(column);
  @include justify-content(flex-end);

  .wrapper {
    @include flex(1 1 auto);
    @include flex-display;
    @include flex-direction(column);
    position: relative;
    min-height: 375px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      backdrop-filter: blur(10px);
    }
  }

  .content-media {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }

  .content-background-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .content-block {
    margin: -96px 0 auto;
    padding: gutter(2.5);
    background-color: var(--c-bg-main);
    z-index: 1;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.03),
      0px 2px 14px rgba(0, 0, 0, 0.03), 0px 2px 5px rgba(0, 0, 0, 0.09);
    position: relative;
  }
}
