@use "sass:math";

[data-wx-filterchecklist] {
  .filter-check-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .filter-check-list-item {
    @include flex-display;
    @include align-items(center);
  }

  .filter-check-list-content {
    @include flex(1 1 auto);
  }

  .filter-check-list-amout {
    @include flex-shrink(0);
    font-size: 0.875rem;
    color: var(--c-text-muted);
    padding-right: gutter(1.5);
  }

  // Single

  .filter-check-list-option-single {
    @include flex-display;
    @include align-items(flex-start);
    width: 100%;
    padding: gutter(1) gutter(1.5);
    text-align: left;
    font-size: 0;

    > * {
      font-size: 1rem;
    }

    &.is-checked {
      .filter-check-list-option-label {
        @include font-medium;
      }

      .filter-check-list-option-single-checkbox {
        &:before {
          opacity: 1;
        }
      }
    }

    .filter-check-list-option-label {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .filter-check-list-option-single-icon {
    display: inline-block;
    vertical-align: middle;
    padding-right: gutter(0.5);
  }

  .filter-check-list-option-single-checkbox {
    $option-single-checkbox-size-inactive: $g-input-selection-size;
    $option-single-checkbox-size-active: $g-input-selection-size-active;

    position: relative;
    display: block;
    width: $option-single-checkbox-size-inactive;
    height: $option-single-checkbox-size-inactive;
    border-radius: $g-border-radius-small;
    background-color: $c-accent-dark;
    margin: math.div(($option-single-checkbox-size-active - $option-single-checkbox-size-inactive), 2);

    &:before {
      @include transform(translate(-50%, -50%));
      content: '\e831';
      font-family: 'icons';
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: $option-single-checkbox-size-active;
      height: $option-single-checkbox-size-active;
      line-height: $option-single-checkbox-size-active;
      border-radius: $g-border-radius-small;
      background-color: $c-action;
      color: $c-text-on-action;
      text-align: center;
      font-size: math.div($option-single-checkbox-size-active, 2);
      opacity: 0;
    }
  }

  // Multi

  .filter-check-list-option-multi {
    @include flex-display;
    @include align-items(flex-start);
    padding: gutter(0.75) gutter(1.5);

    &.is-checked {
      .filter-check-list-option-label {
        @include font-medium;
      }
    }
  }

  .filter-check-list-option-multi-action {
    @include flex-shrink(0);
    padding-right: gutter(1);
    font-size: 0;

    > * {
      font-size: 1rem;
    }
  }

  .filter-check-list-option-multi-btn {
    $option-multi-btn-size: 28px;

    display: inline-block;
    vertical-align: middle;
    width: $option-multi-btn-size;
    height: $option-multi-btn-size;
    line-height: $option-multi-btn-size;
    background-color: $c-accent-dark;
    color: var(--c-text-muted);
    border-radius: $g-border-radius-small;
    font-size: 1rem;

    &.is-checked {
      &.is-positive {
        background-color: $c-action;
        color: $c-text-on-action;
      }

      &.is-negative {
        background-color: $c-action-light;
        color: $c-action;
      }
    }

    & +.filter-check-list-option-multi-btn {
      margin-left: gutter(0.375);
    }
  }

  .filter-check-list-option-multi-label {
    @include flex(1 1 auto);
  }

  // Extend

  .filter-check-list-extend-btn {
    @include font-medium;
    display: block;
    width: 100%;
    padding: gutter(1);
    color: $c-primary;
    font-size: 1rem;
  }
}
