[data-wx-submenu] {
  .sub-menu-action {
    @include flex-display;
    @include align-items(center);
    border-bottom: 1px solid var(--c-border-main);
    padding: gutter(0.75) 0;
    margin-top: gutter(-0.75);
  }

  .sub-menu-action-heading {
    @include flex(1 1 auto);
    @include font-medium;
  }

  .sub-menu-action-icon {
    @include flex-shrink(0);
    color: $c-primary;
    padding-left: gutter(0.75);
  }

  .sub-menu-heading {
    display: none;
  }

  .sub-menu-body {
    display: none;
    margin-top: gutter(1.5);

    &.is-open {
      display: block;
    }
  }
}
