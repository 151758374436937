[data-wx-subnavigation] {
  $notification-size: 6px;

  .sub-navigation-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .sub-navigation-item {
    &.is-active {
      .sub-navigation-link {
        color: var(--c-text-main);
      }
    }

    &.has-notification {
      .sub-navigation-link-label {
        padding-right: $notification-size;

        &:after {
          display: block;
        }
      }
    }
  }

  .sub-navigation-link {
    @include font-medium;
    color: var(--c-text-muted);
  }

  .sub-navigation-link-label {
    position: relative;
    display: inline-block;

    &:after {
      content: '';
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      width: $notification-size;
      height: $notification-size;
      border-radius: 50%;
      background-color: $c-action;
    }
  }
}
