[data-am-inputrow] {
  .input-row {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  .input-row-item {
    display: table-cell;
    vertical-align: bottom;

    & + .input-row-item {
      padding-left: $gutter-half;
    }
  }
}

[data-am-inputrow~='valign-top'] {
  .input-row-item {
    vertical-align: top;
  }
}

[data-am-inputrow~='valign-middle'] {
  .input-row-item {
    vertical-align: middle;
  }
}
