@use "sass:math";

[data-am-form] {
  .form-group {
    > label,
    .label {
      margin-bottom: math.div($gutter-half, 2);
    }
  }
}
