@use "sass:math";

@mixin button-main($bg, $text) {
  background-color: $bg;
  color: $text;
  border-color: $bg;
}

@mixin button-outline($color) {
  color: $color;
  border-color: currentColor;
}

@mixin button-clear($color) {
  color: $color;

  &::before {
    content: none;
  }
}

[data-am-button] {
  @include transform-origin(50% 50%);
  @include font-medium;
    position: relative;
  display: inline-block;
  padding: $gutter;
  border-radius: 30px; // Magic number
  border: 1px solid $c-fade-light-00;
  line-height: 1;
  font-size: 0.875rem;
  text-align: center;
  -webkit-tap-highlight-color: $c-fade-dark-00;
  transition: transform 200ms ease;

  &::before {
    content: "";
    position: absolute;
    inset: 1px;
    background-color: var(--c-overlay);
    border-radius: 30px; // Magic number
    mix-blend-mode: soft-light;
    opacity: 0;
    transition: opacity 200ms ease;
    }

  &:enabled:hover {
    text-decoration: none;

    &::before {
        opacity: 1;
    }
  }

  &:active {
    @include transform(scale(0.97));
  }

  &[disabled],
  &.is-disabled {
    opacity: 0.37;
    cursor: not-allowed;
  }

  .button-img {
    margin: -($gutter-half);
    margin-right: $gutter-half;
  }

  [class^='icon-'],
  [class*=' icon-'] {
    &:before {
      margin: 0;
    }
  }
}

// Colors

[data-am-button~='primary'] {
  @include button-main($c-primary, $c-text-on-primary);
}

[data-am-button~='primary-extra-light'] {
  @include button-main($c-primary-light, $c-text-on-primary-light);
}

[data-am-button~='primary-outline'] {
  @include button-outline($c-primary);
}

[data-am-button~='primary-clear'] {
  @include button-clear($c-primary);
}

[data-am-button~='complement'] {
  @include button-main($c-complement, $c-text-on-complement);
}

[data-am-button~='action'] {
  @include button-main($c-action, $c-text-on-action);
}

[data-am-button~='action-light'] {
  @include button-main($c-action-light, $c-action);
}

[data-am-button~='action-outline'] {
  @include button-outline($c-action);
}

[data-am-button~='action-clear'] {
  @include button-clear($c-action);
}

[data-am-button~='accent'] {
  @include button-main($c-accent, $c-primary);
}

[data-am-button~='on-image-clear'] {
  @include button-clear(var(--c-bg-main));
}

[data-am-button~='text-dark-clear'] {
  @include button-clear(var(--c-text-main));
}

// Size

[data-am-button~='full-width'] {
  display: block;
  width: 100%;
}

[data-am-button~='full-height'] {
  height: 100%;
  padding-top: 0;
  padding-bottom: 0;
}

[data-am-button~='thin'] {
  padding-top: $gutter-half;
  padding-bottom: $gutter-half;
}

[data-am-button~='medium-thin'] {
  padding-top: ($gutter-half * 1.5);
  padding-bottom: ($gutter-half * 1.5);
}

[data-am-button~='thick'] {
  padding-top: $gutter-double;
  padding-bottom: $gutter-double;
}

[data-am-button~='narrow'] {
  padding-left: $gutter-half;
  padding-right: $gutter-half;
}

[data-am-button~='wide'] {
  padding-left: $gutter-triple;
  padding-right: $gutter-triple;
}

[data-am-button~='no-padding'] {
  padding: 0;
}

// Text

[data-am-button~='text-regular'] {
  font-size: 1rem;
}

[data-am-button~='text-big'] {
  font-size: 1.375rem;
}

[data-am-button~='text-left'] {
  text-align: left;
}

// Layout

[data-am-button~='block'] {
  display: block;
}

[data-am-button~='border'] {
  border: 2px solid;
}

[data-am-button~='square'] {
  border-radius: 0;
}

[data-am-button~='input-height'] {
  height: $g-input-height;
  line-height: ($g-input-height - 2px);
  padding-top: 0;
  padding-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &[data-am-button~='input-height-round'] {
    width: $g-input-height;
    padding-left: 0;
    padding-right: 0;
  }
}

// Icon

[data-am-button~='icon-left'] {
  position: relative;
  padding-left: calc(#{$gutter-double} + 1em);
  padding-right: calc(#{$gutter} + 1em);

  .button-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: $gutter;

    [class^='icon-'],
    [class*=' icon-'] {
      @include transform(translateY(-50%));
      position: absolute;
      top: 50%;

      &:before {
        margin: 0;
      }
    }
  }
}

[data-am-button~='icon-big'] {
  padding: math.round(math.div($gutter-half, 3));
  padding-right: $gutter;

  .button-icon {
    $icon-size: 35px;

    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: $icon-size;
    height: $icon-size;
    border-radius: 50%;
    background-color: var(--c-bg-main);
    margin-right: $gutter-half;

    [class^='icon-'],
    [class*=' icon-'] {
      @include transform(translate(-50%, -50%));
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 1.125rem;

      &:before {
        margin: 0;
      }
    }
  }
}
