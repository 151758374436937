[data-am-map] {
  min-height: none;

  .map {
    position: relative;
    width: 100%;
    height: 300px;
  }

  .map-nav {
    padding: 0;
    width: 100%;
    max-width: none;
  }

  .map-nav-content {
    box-shadow: none;
  }

  .body-section {
    padding-left: 0;
    padding-right: 0;
  }
}
