[data-wx-userutility] {
  .user-utility-list {
    @include flex-display;
    @include align-items(center);
    gap: gutter(0.5);
    list-style: none;
    padding: 0;
    margin: 0;
  }
}
