@use "sass:math";

[data-wx-actiongroup] {
    $action-group-btn-height: 36px;

    .action-group-clip {
        overflow-x: clip;
    }

    .action-group-list {
        display: flex;
        align-items: center;
        gap: gutter(0.25);
    }

    .action-group-btn {
        $active-dot-size: 6px;

        @include font-medium;

        flex-shrink: 0;
        display: flex;
        align-items: center;
        gap: gutter(0.5);
        color: var(--c-text-main);
        height: $action-group-btn-height;
        font-size: 0.875rem;
        border-radius: math.div($action-group-btn-height, 2);
        border: 1px solid var(--c-border-main);
        padding-left: gutter(1);
        padding-right: gutter(1);

        &.is-active {
            &:before {
                content: "";
                display: inline-block;
                vertical-align: middle;
                width: $active-dot-size;
                height: $active-dot-size;
                border-radius: 50%;
                background-color: $c-action;
            }
        }
    }
}
