[data-am-columnrow] {
  display: table;
  width: 100%;

  .column {
    display: table-cell;
    vertical-align: middle;

    & + .column {
      padding-left: $gutter;
      text-align: right;
    }

    &.minimal {
      width: 1px;
      white-space: nowrap;
    }

    &.valign-top {
      vertical-align: top;
    }

    &.valign-bottom {
      vertical-align: bottom;
    }
  }
}

[data-am-columnrow~='text-left'] {
  .column {
    & + .column {
      text-align: left;
    }
  }
}

[data-am-columnrow~='half'] {
  table-layout: fixed;

  .column {
    width: 50%;

    & + .column {
      text-align: left;
    }
  }
}

[data-am-columnrow~='mobile-only'] {
  width: auto;
}
