[data-wx-listsection] {
  @include grid-display;
  @include grid-template-columns(1fr auto);

  .list-section-heading {
    @include grid-column-span(1, 1);
  }

  .list-section-content {
    @include grid-column-span(1, 2);
    @include grid-row-span(2, 1);
    margin-top: 0;
  }

  .list-section-action {
    @include grid-column-span(2, 1);
    margin-left: gutter(1.5);
  }

  .list-section-heading,
  .list-section-action {
    @include grid-align-self(end);
  }
}

[data-wx-listsection~='heading-inline'] {
  display: block;
  white-space: nowrap;
  font-size: 0;

  > * {
    font-size: 1rem;
    white-space: normal;
  }

  .list-section-heading,
  .list-section-content {
    display: inline-block;
    vertical-align: middle;
  }

  .list-section-heading {
    width: 33.333%;
    padding-right: gutter(1.5);
  }

  .list-section-content {
    width: 66.666%;
  }

  // TODO: Remove this when Project X is live
  &[data-wx-listsection~='product-grid'] {
    margin-left: -($gutter-half);
    margin-right: -($gutter-half);

    .list-section-heading,
    .list-section-content {
      padding-left: $gutter-half;
      padding-right: $gutter-half;
    }
  }
}
