[data-wx-contentsection] {
  .container & {
    margin-left: -($grid-container-gutter-lt-md);
    margin-right: -($grid-container-gutter-lt-md);
  }

  .content-section-header,
  .content-section-body {
    padding: gutter(1) $grid-container-gutter-lt-md;
  }

  .content-section-body {
    padding-top: 0;
  }

  // Align with data-wx-page padding
  [data-wx-page] & {
    margin-top: gutter(-1);
    margin-bottom: gutter(-1);
  }
}
