[data-am-inputtext~='suggestion'] {
  display: table;
  width: 100%;

  input {
    border-left: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: $g-border-radius-small;

    &,
    & + .suggestion {
      display: table-cell;
      vertical-align: middle;
    }

    & + .suggestion {
      @include font-medium;
      width: 1px;
      white-space: nowrap;
      font-size: 0.875rem;
      height: $g-input-height;
      margin-top: 0;
      padding: 0 ($gutter-half * 1.5);
    }
  }

  .suggestion {
    border-radius: 0;
    border-top-left-radius: $g-border-radius-small;
    border-bottom-left-radius: $g-border-radius-small;
  }
}
