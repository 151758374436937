// This files includes CSS for font icons
// sass-lint:disable no-css-comments, force-pseudo-nesting, indentation

$font-path: '../font/icons/';
$font-name: 'icons';

@font-face {
  font-family: '#{$font-name}';
  src: url('#{$font-path}#{$font-name}.woff2') format('woff2'),
       url('#{$font-path}#{$font-name}.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^='icon-'],
[class*=' icon-'] {
  &:before {
    font-family: '#{$font-name}';
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    margin-left: .2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  // Remove spacing when using spacing utility classes
  &.no-margin,
  &[class*='m-l'],
  &[class*='m-r'] {
    &:before {
      margin: 0;
    }
  }
}

// Icons
// Copy and paste from Fontello CSS file

.icon-search:before { content: '\e800'; } /* '' */
.icon-settings:before { content: '\e801'; } /* '' */
.icon-cart:before { content: '\e802'; } /* '' */
.icon-lock-open:before { content: '\e803'; } /* '' */
.icon-tag:before { content: '\e804'; } /* '' */
.icon-messages:before { content: '\e805'; } /* '' */
.icon-helper-close:before { content: '\e806'; } /* '' */
.icon-helper-open:before { content: '\e807'; } /* '' */
.icon-info-circle:before { content: '\e808'; } /* '' */
.icon-filter:before { content: '\e809'; } /* '' */
.icon-download:before { content: '\e80a'; } /* '' */
.icon-mail:before { content: '\e80b'; } /* '' */
.icon-email:before { content: '\e80c'; } /* '' */
.icon-share:before { content: '\e80d'; } /* '' */
.icon-frown:before { content: '\e80e'; } /* '' */
.icon-auction:before { content: '\e810'; } /* '' */
.icon-edit:before { content: '\e811'; } /* '' */
.icon-message:before { content: '\e813'; } /* '' */
.icon-arrow-diagonal-up-right:before { content: '\e815'; } /* '' */
.icon-close:before { content: '\e816'; } /* '' */
.icon-watch:before { content: '\e818'; } /* '' */
.icon-thumb-up:before { content: '\e81c'; } /* '' */
.icon-sort:before { content: '\e820'; } /* '' */
.icon-trash:before { content: '\e822'; } /* '' */
.icon-heart:before { content: '\e827'; } /* '' */
.icon-camera:before { content: '\e829'; } /* '' */
.icon-minus:before { content: '\e82b'; } /* '' */
.icon-plus:before { content: '\e82c'; } /* '' */
.icon-arrow-up:before { content: '\e82d'; } /* '' */
.icon-arrow-right:before { content: '\e82e'; } /* '' */
.icon-arrow-left:before { content: '\e82f'; } /* '' */
.icon-arrow-down:before { content: '\e830'; } /* '' */
.icon-check:before { content: '\e831'; } /* '' */
.icon-profile:before { content: '\e832'; } /* '' */
.icon-list:before { content: '\e834'; } /* '' */
.icon-login:before { content: '\e838'; } /* '' */
.icon-map-pin:before { content: '\e83b'; } /* '' */
.icon-card-grid:before { content: '\e83d'; } /* '' */
.icon-article:before { content: '\e83e'; } /* '' */
.icon-gearbox:before { content: '\e841'; } /* '' */
.icon-mileage:before { content: '\e842'; } /* '' */
.icon-calendar:before { content: '\e844'; } /* '' */
.icon-trend-down:before { content: '\e846'; } /* '' */
.icon-globe:before { content: '\e847'; } /* '' */
.icon-list-add:before { content: '\e848'; } /* '' */
.icon-skip:before { content: '\e849'; } /* '' */
.icon-meatballs:before { content: '\e84b'; } /* '' */
.icon-armchair:before { content: '\e84c'; } /* '' */
.icon-contract:before { content: '\e84d'; } /* '' */
.icon-clock:before { content: '\e84e'; } /* '' */
.icon-stair:before { content: '\e84f'; } /* '' */
.icon-phone:before { content: '\e850'; } /* '' */
.icon-steering-wheel:before { content: '\e851'; } /* '' */
.icon-card-list:before { content: '\e852'; } /* '' */
.icon-upload-image:before { content: '\e853'; } /* '' */
.icon-credit:before { content: '\e854'; } /* '' */
.icon-360:before { content: '\e855'; } /* '' */
.icon-direction-arrow:before { content: '\e856'; } /* '' */
.icon-image:before { content: '\e857'; } /* '' */
.icon-play:before { content: '\e858'; } /* '' */
.icon-fullscreen:before { content: '\e85a'; } /* '' */
.icon-fullscreen-active:before { content: '\e85b'; } /* '' */
.icon-thumb-down:before { content: '\e85c'; } /* '' */
.icon-messenger:before { content: '\e85f'; } /* '' */
.icon-link:before { content: '\e861'; } /* '' */
.icon-logout:before { content: '\e864'; } /* '' */
.icon-compare:before { content: '\e86b'; } /* '' */
.icon-exclamation:before { content: '\e86c'; } /* '' */
.icon-fuel:before { content: '\e86d'; } /* '' */
.icon-create-ad:before { content: '\e871'; } /* '' */
.icon-lock:before { content: '\e872'; } /* '' */
.icon-trend-up:before { content: '\e874'; } /* '' */
.icon-upload:before { content: '\e875'; } /* '' */
.icon-heart-outline:before { content: '\e879'; } /* '' */
.icon-info:before { content: '\e888'; } /* '' */
.icon-youtube:before { content: '\e8a4'; } /* '' */
.icon-link-external:before { content: '\f08e'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-chevron-left:before { content: '\f104'; } /* '' */
.icon-chevron-right:before { content: '\f105'; } /* '' */
.icon-chevron-up:before { content: '\f106'; } /* '' */
.icon-chevron-down:before { content: '\f107'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */