@use "sass:math";

[data-am-columnrow~='no-margin-lt-md'] {
  .column {
    & + .column {
      margin-top: 0;
    }
  }
}

[data-am-columnrow~='small-margin-lt-md'] {
  .column {
    & + .column {
      margin-top: math.div($gutter-half, 2);
    }
  }
}