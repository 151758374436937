[data-wx-articlecard] {
  display: block;
  cursor: pointer;

  .article-card-image {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $c-accent;
  }

  .article-card-image-action {
    @include transform(translate(-50%, -50%));
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .article-card-body {
    padding: gutter(1.5);
    color: var(--c-text-media);
  }

  .article-card-body-action {
    margin-bottom: gutter(1);

    > * {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .article-card-pre-heading {
    margin-bottom: gutter(0.4);
  }

  .article-card-heading {
    @include font-bold;
    font-size: 1.1875rem;
    line-height: 1.2;
  }

  .article-card-preamble {
    @include font-medium;
    margin-top: gutter(0.5);
  }

  .article-card-footer {
    margin-top: gutter(1);
  }
}

[data-wx-articlecard~='standalone'] {
  background-color: $c-complement-dark;

  .article-card-body {
    color: var(--c-text-media);
    text-align: center;
    z-index: 1;
  }
}

[data-wx-articlecard~='cursor-unset'] {
  cursor: unset;
}
