[data-am-imbox] {
  bottom: $gutter-half;
  right: $gutter-half;
  transition: margin-bottom 200ms ease;

  &.list-utility-open {
    // Required to use margin instead of transform due to fixed position on nested element
    margin-bottom: 50px; // Height of utility bar
  }
}
