@use "sass:math";

// Mixin used to scale up the tick box
@mixin input-selection-active-state($pre-size, $post-size) {
  @include transform(scale(math.div(strip-unit($post-size), strip-unit($pre-size)))); // Scale to size equalent to $post-size
}

@mixin create-inputselection {
  $input-selection-size: $g-input-selection-size;
  $input-selection-size-active: $g-input-selection-size-active;
  $input-top-offset: 6px; // Magic number depending on font
  $input-animation-duration: 300ms;
  $input-animation-timing-function: cubic-bezier(.47, 1.64, 0.41, 0.8);

  [data-wx-inputselection] {
    display: inline-block;
    min-height: $input-selection-size;

    input {
      // sass-lint:disable-block nesting-depth
      &[type='checkbox'],
      &[type='radio'] {
        position: fixed;
        left: -9999px;
        opacity: 0;

        &[disabled] {
          + label {
            cursor: not-allowed;
            color: var(--c-text-muted);

            &:before,
            &:after {
              cursor: not-allowed;
            }

            &:hover {
              &:before {
                @include transform(none);
              }
            }
          }
        }

        &:checked,
        &.is-checked {
          + label {
            &:before {
              @include input-selection-active-state($input-selection-size, $input-selection-size-active);
              background-color: $c-action;
            }
          }
        }
      }
    }

    label {
      position: relative;
      display: block;
      font-size: 1rem;
      color: var(--c-text-main);

      &:before {
        content: '';
        position: absolute;
        top: $input-top-offset;
        left: math.div(($input-selection-size-active - $input-selection-size), 2);
        width: $input-selection-size;
        height: $input-selection-size;
        background-color: $c-accent-dark;
        transition: transform $input-animation-duration $input-animation-timing-function, background-color math.div($input-animation-duration, 2) linear;
        cursor: pointer;
      }

      &:after {
        @include animation(scaleUp $input-animation-duration $input-animation-timing-function);
        cursor: pointer;
      }

      &:hover {
        &:before {
          @include input-selection-active-state($input-selection-size, $input-selection-size-active);
        }
      }
    }

    .text {
      @include unselectable;
      display: block;
      padding-left: calc(#{$input-selection-size-active} + #{gutter(0.5)});
    }
  }

  // Types

  [data-wx-inputselection~='checkbox'] {
    input {
      // sass-lint:disable-block nesting-depth
      &[type='checkbox'],
      &[type='radio'] {
        &:checked,
        &.is-checked {
          + label {
            &:after {
              display: block;
            }
          }
        }
      }
    }

    label {
      &:before {
        border-radius: $g-border-radius-small;
      }

      &:after {
        @include animation(scaleUp $input-animation-duration $input-animation-timing-function);
        content: '\e831';
        font-family: 'icons';
        display: none;
        position: absolute;
        top: $input-top-offset;
        left: math.div(($input-selection-size-active - $input-selection-size), 2);
        width: $input-selection-size;
        height: $input-selection-size;
        line-height: $input-selection-size;
        text-align: center;
        font-size: 0.7em;
        color: $c-text-on-action;
      }
    }

    &[data-wx-inputselection~='center-input'] {
      label {
        &:after {
          top: 50%;
          margin-top: math.div($input-selection-size, -2);
        }
      }
    }
  }

  [data-wx-inputselection~='radio'] {
    $check-size: math.div($input-selection-size, 2);

    input {
      // sass-lint:disable-block nesting-depth
      &[type='checkbox'],
      &[type='radio'] {
        &:checked,
        &.is-checked {
          + label {
            &:after {
              display: block;
            }
          }
        }
      }
    }

    label {
      &:before,
      &:after {
        border-radius: 50%;
      }

      &:after {
        content: '';
        display: none;
        position: absolute;
        top: (($input-top-offset - math.div(($input-selection-size-active - $input-selection-size), 2)) + math.div(($input-selection-size-active - $check-size), 2) );
        left: math.div(($input-selection-size-active - $check-size), 2);
        width: $check-size;
        height: $check-size;
        background-color: $c-accent;
      }
    }

    &[data-wx-inputselection~='center-input'] {
      label {
        &:after {
          top: calc(50% - #{math.div(($input-selection-size-active - $check-size - ($input-top-offset - math.div(($input-selection-size-active - $input-selection-size), 2))), 2)});
        }
      }
    }
  }

  // Display

  [data-wx-inputselection~='block'] {
    display: block;
  }

  [data-wx-inputselection~='center-input'] {
    label {
      &:before {
        top: 50%;
        margin-top: math.div($input-selection-size, -2);
      }
    }
  }
}
@include create-inputselection;
