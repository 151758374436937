[data-am-linkcloud] {
  .link-cloud-heading {
    margin-bottom: 0;
  }

  .link-cloud,
  .link-cloud-nested {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .link-cloud {
    font-size: 0;

    > * {
      font-size: 1rem;
    }

    &.is-tight {
      padding-top: ($gutter-half * 1.5);
      margin: gutter(-0.5) 0;

      .link-cloud-item {
        margin-top: 0;
        padding-right: 0;
      }

      .link-cloud-link {
        margin: 0 gutter(-0.5);
      }
    }
  }

  .link-cloud-nested {
    margin: gutter(-0.5);

    // Hide all but the first five items
    .link-cloud-nested-item {
      &:nth-child(n+6) {
        display: none;
      }
    }

    &.is-extended {
      .link-cloud-nested-item {
        &:nth-child(n+6) {
          display: block;
        }
      }
    }
  }

  .link-cloud-title {
    color: var(--c-text-main);
    font-size: var(--font-size-regular-static);
    margin-bottom: gutter(1);
    line-height: 1.4;

    a {
      display: block;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .link-cloud-item {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    margin-top: gutter(2);
    padding-right: $gutter;
  }

  .link-cloud-nested-item {
    display: block;
  }

  .link-cloud-link {
    @include font-regular;
    display: block;
    width: 100%;
    padding: gutter(0.5);
    line-height: 1.4;
    font-size: var(--font-size-small-static);
  }

  .link-cloud-item-label,
  .link-cloud-item-count {
    vertical-align: middle;
  }

  .link-cloud-item-label {
    display: inline;
    color: var(--c-text-main);
  }

  .link-cloud-item-count {
    display: inline;
    color: var(--c-text-muted);
    margin-left: .5ch;

    &:before {
      content: '(';
    }

    &:after {
      content: ')';
    }
  }

  .link-cloud-extend {
    margin-top: gutter(1);
  }

  .link-cloud-extend-btn {
    @include font-medium;
    display: block;
    width: 100%;
    padding: gutter(0.5);
    margin: gutter(-0.5);
    text-align: left;
    color: $c-primary;
    font-size: var(--font-size-small-static);
    line-height: 1.4;
  }
}
