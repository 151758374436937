[data-wx-convertcard] {
    .convert-card-body {
        & + .convert-card-body {
            margin-top: gutter(1);
            padding-top: gutter(1);
        }
      }

  // Banner

  .convert-card-banner {
    padding: gutter(0.375) gutter(1);
    margin-bottom: gutter(1);
    border-radius: 3px;
  }
}
