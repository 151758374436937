[data-wx-form] {
  font-size: 0;

  > * {
    font-size: 1rem;
  }

  .form-group {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 100%;

    & + .form-group-row {
      margin-top: gutter(1);
    }

    & + .form-group {
      margin-top: gutter(1);
    }

    &.is-required {
      [data-wx-inputlabel] {
        &:after {
          content: '*';
        }
      }
    }

    &.has-error,
    &.has-warning {
      .alert {
        display: block;
        padding-left: $gutter;

        &:before {
          font-family: 'icons';
          position: absolute;
          left: 0;
        }
      }

      .input-selection-group {
        border: 1px solid;
        padding: $gutter-half;
      }
    }

    &.has-error {
      [data-wx-inputtext],
      [data-wx-locationpicker] {
        input,
        textarea,
        .location-picker,
        .input-text-unit,
        .input-text-regnr,
        .suggestion {
          border-color: $c-ui-danger;
        }
      }

      .input-selection-group {
        border-color: $c-ui-danger;
      }

      [data-wx-select] {
        border-color: $c-ui-danger;
      }

      [data-wx-inputlabel] {
        color: $c-ui-danger;
      }

      .alert {
        color: $c-ui-danger;

        &:before {
          content: '\e86c';
        }
      }
    }

    &.has-warning {
      [data-wx-inputtext] {
        input,
        textarea {
          border-color: $c-ui-warning;
        }
      }

      .input-selection-group {
        border-color: $c-ui-warning;
      }

      [data-wx-select] {
        border-color: $c-ui-warning;
      }

      [data-wx-inputlabel] {
        color: $c-ui-warning;
      }

      .alert {
        color: $c-ui-warning;

        &:before {
          content: '\e86c';
        }
      }
    }
  }

  .form-group-row {
    font-size: 0;

    > *  {
      font-size: 1rem;
    }

    & + .form-group-row {
      margin-top: gutter(1);
    }

    & + .form-group {
      margin-top: gutter(1);
    }
  }

  .form-instruction {
    font-size: 0.875em;
    color: var(--c-text-muted);
    margin-top: gutter(0.25);
  }

  .alert {
    display: none;
    font-size: 0.875rem;
  }

  .label-placeholder {
    display: block;
    height: 25px; // Height of label (magic number)
  }
}
