[data-am-columnrow] {
  .column {
    & + .column {
      margin-top: $gutter;
    }
  }
}

[data-am-columnrow~='always'] {
  display: table;
  width: 100%;

  .column {
    display: table-cell;
    vertical-align: middle;

    & + .column {
      padding-left: $gutter-half;
      text-align: right;
      white-space: nowrap;

      &:empty {
        padding-left: 0;
      }
    }

    &.valign-top {
      vertical-align: top;
    }

    &.valign-bottom {
      vertical-align: bottom;
    }

    &.minimal {
      width: 1px;
      white-space: nowrap;
    }
  }
}

[data-am-columnrow~='table-fixed'] {
  table-layout: fixed;

  .column {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

[data-am-columnrow~='text-left'] {
  .column {
    & + .column {
      text-align: left;
      white-space: normal;
    }
  }
}

[data-am-columnrow~='white-space-normal'] {
  .column {
    & + .column {
      white-space: normal;
    }
  }
}
