@use "sass:math";
@use "sass:selector";

@mixin create-tag {
  $tag-spacing: 5px;
  $toggle-tag-height: 40px;

  %tag-base {
    @include transform-origin(50% 50%);
    @include font-bold;
    display: inline-block;
    margin-bottom: $tag-spacing;
    margin-right: $tag-spacing;
    vertical-align: middle;
    height: $g-tag-height;
    border-radius: math.div($g-tag-height, 2);
    background-color: var(--c-bg-main);
    color: $c-primary;
    white-space: nowrap;
    font-size: 0;
    transition: transform 200ms ease;

    > * {
      font-size: 1rem;
    }

    &.is-active {
      background-color: $c-action;
      color: $c-text-on-action;
    }

    &.is-excluded {
      background-color: $c-action-light;
      color: $c-action;
    }

    &:active {
      @include transform(scale(0.98));
    }
  }

  %tag-btn-base {
    display: inline-block;
    vertical-align: middle;
    height: $g-tag-height;
    line-height: $g-tag-height;
    padding-left: $gutter-half;
    padding-right: $gutter-half;
    cursor: default;
    font-size: 0.875rem;
    -webkit-tap-highlight-color: $c-fade-light-00;

    & + .tag-btn {
      padding-left: 0;
    }

    &.tag-btn-remove {
      font-size: 0.6rem;
    }

    @at-root {
      #{selector.unify(&, button)} {
        cursor: pointer;
      }
    }

    [class^='icon-'],
    [class*=' icon-'] {
      &:before {
        margin: 0;
      }
    }
  }

  %tag-toggle-base {
    @extend %tag-base;
    @include font-regular;
    height: $toggle-tag-height;
    background-color: $c-accent;
    color: var(--c-text-main);
    border-radius: $g-border-radius-small;

    &.is-active {
      @include font-medium;
      background-color: $c-primary-light;
      color: var(--c-text-main);
    }
  }

  %tag-btn-toggle-base {
    @extend %tag-btn-base;
    height: $toggle-tag-height;
    line-height: $toggle-tag-height;
    padding-left: ($gutter-half * 1.5);
    padding-right: ($gutter-half * 1.5);
  }

  [data-am-tag] {
    margin-bottom: -($tag-spacing);

    .tag-container {
      font-size: 0;
    }

    .tag-group,
    .tag {
      display: inline-block;
      margin-bottom: $tag-spacing;
      margin-right: $tag-spacing;
      vertical-align: middle;
    }

    .tag-group {
      display: inline-table;
      padding-top: $tag-spacing;
      padding-left: $tag-spacing;
      background-color: $c-action-light;
      border-radius: math.div(($g-tag-height + ($tag-spacing * 2)), 2);
    }

    .tag-group-section {
      display: table-cell;
      vertical-align: top;
      font-size: 0;

      > * {
        font-size: 1rem;
      }
    }

    .tag-group-remove-btn {
      display: inline-block;
      padding-left: $gutter-half;
      padding-right: $gutter-half;
      height: $g-tag-height;
      line-height: $g-tag-height;
      font-size: 0.7rem;
      color: $c-action;

      [class^='icon-'],
      [class*=' icon-'] {
        &:before {
          margin: 0;
        }
      }
    }

    .tag {
      @extend %tag-base;
    }

    .tag-btn {
      @extend %tag-btn-base;
    }

    .tag-row {
      display: inline;
      font-size: 0;

      > * {
        font-size: 1rem;
      }
    }

    // This class is applied to `.tag` since React Sortable (HOC)
    // places the item outside of it's wrapper `[data-am-tag]` while being dragged.
    @at-root {
      // Default tag styling
      .react-sortable-hoc-ghost-tag {
        @extend %tag-base;
        box-shadow: $g-box-shadow;
        transition: none !important;

        .tag-btn {
          @extend %tag-btn-base;
        }
      }

      // Toggable tag styling
      .react-sortable-hoc-ghost-tag-toggle {
        @extend %tag-toggle-base;
        box-shadow: $g-box-shadow;

        .tag-btn {
          @extend %tag-btn-toggle-base;
        }
      }
    }
  }

  [data-am-tag~='thin'] {
    $tag-height-thin: 25px;

    .tag,
    .tag-btn,
    .tag-group-remove-btn {
      height: $tag-height-thin;
    }

    .tag-btn,
    .tag-group-remove-btn {
      line-height: $tag-height-thin;
    }

    .tag-btn {
      font-size: 0.75rem;
    }
  }

  [data-am-tag~='cursor-pointer'] {
    .tag-btn {
      cursor: pointer;
    }
  }

  [data-am-tag~='toggle'] {
    .tag {
      @extend %tag-toggle-base;
    }

    .tag-btn {
      @extend %tag-btn-toggle-base;
    }
  }
}
@include create-tag;
