[data-wx-inlinebutton] {
  font-size: 0;

  > * {
    font-size: 1rem;
  }

  .inline-button-icon,
  .inline-button-text {
    display: inline-block;
    vertical-align: middle;
  }

  .inline-button-icon {
    color: $c-action;

    & + .inline-button-text {
      margin-left: gutter(0.5);
    }
  }

  .inline-button-text {
    @include font-medium;
    font-size: 0.875rem;
  }
}
