[data-wx-locationpicker] {
    .locationpicker-input-wrapper {
        @include flex-display;
        @include flex(1 0 auto);
        @include align-items(flex-end);
    }

    .locationpicker-form-input {
        & + .locationpicker-form-input {
            margin-left: gutter(1);
        }
    }
}
