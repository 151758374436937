[data-am-gallerycontrols] {
  @include flex-display;
  @include align-items(center);

  .gallery-controls-btn {
    @include font-medium;
    @include inline-flex-display;
    @include align-items(center);
    @include justify-content(center);
    height: 36px;
    color: var(--c-text-main);

    &[disabled] {
      color: $c-fade-dark-50;
      cursor: default;
    }

    &.is-active {
      color: var(--c-text-main);
    }
  }

  .gallery-controls-btn-icon {
    font-size: 1rem;

    & + .gallery-controls-btn-label {
      margin-left: gutter(0.5);
    }
  }

  .gallery-controls-btn-label {
    font-size: 0.875rem;

    & + .gallery-controls-btn-icon {
      margin-left: gutter(0.5);
    }
  }
}
