[data-wx-detaillist] {
  .detail-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .detail-list-value {
    & + .detail-list-value {
      margin-top: gutter(0.25);
    }
  }

  .detail-list-label {
    & + .detail-list-label {
      margin-top: gutter(0.25);
    }
  }
}
