@use "sass:selector";

[data-am-list] {
  list-style: none;
  margin: 0;
  padding: 0;
}

// Styling

[data-am-list~='default'] {
  $bullet-size: 8px;

  .list-item {
    position: relative;
    padding-left: calc(#{$gutter} + #{$bullet-size});
    margin-bottom: $gutter-half;

    &:before {
      @include transform(translateY(-50%));
      position: absolute;
      top: 50%;
      left: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  // Different layout depending on element type
  @at-root {
    #{selector.unify(&, ul)} {
      .list-item {
        &:before {
          content: '';
          width: $bullet-size;
          height: $bullet-size;
          background-color: currentColor;
          border-radius: 50%;
        }
      }
    }

    #{selector.unify(&, ol)} {
      counter-reset: ol;

      .list-item {
        counter-increment: ol;

        &:before {
          @include font-medium;
          content: counter(ol);
          font-size: 1.2em;
        }
      }
    }
  }
}

[data-am-list~='columns'] {
  margin-top: -($gutter-half);
  font-size: 0;

  > * {
    font-size: 1rem;
  }

  .list-item {
    display: inline-block;
    vertical-align: top;
    width: 25%;
    margin-top: $gutter-half;
  }
}
