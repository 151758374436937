[data-am-contentbox] {
  background-color: var(--c-bg-main);
  border: 1px solid var(--c-border-main);

  &:empty {
    display: none;
  }

  .content-box-header,
  .content-box-section {
    padding: $gutter;
  }

  .content-box-header {
    border-bottom: 1px solid var(--c-border-main);
  }

  .content-box-footer {
    padding: $gutter-half $gutter;
    background-color: $c-accent;
  }

  // Table

  .content-box-table {
    .tr {
      &:first-child {
        .td {
          border-top: 0;
        }
      }
    }

    .td {
      border-top: 1px solid var(--c-border-main);
      padding: $gutter;
    }
  }
}

[data-am-contentbox~='box-shadow'] {
  border: 0;
  box-shadow: $g-box-shadow-big;
}
