@use "sass:selector";

[data-am-tag] {
  &:not([data-am-tag~='no-hover']) {
    .tag-group-remove-btn {
      transition: opacity 200ms ease;

      &:hover {
        opacity: 0.6;
      }
    }

    .tag-btn {
      @at-root {
        #{selector.unify(&, button)} {
          transition: opacity 200ms ease;

          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }
}
