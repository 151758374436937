@use "sass:math";

[data-am-tag] {
  $tag-spacing: 3px;

  margin-bottom: -($tag-spacing);

  .tag-group,
  .tag {
    @include unselectable;
    margin-bottom: $tag-spacing;
    margin-right: $tag-spacing;
  }

  .tag-group {
    padding-top: $tag-spacing;
    padding-left: $tag-spacing;
    border-radius: math.div(($g-tag-height + ($tag-spacing * 2)), 2);
  }

  .tag {
    &:active {
      @include transform(scale(0.95));
    }
  }
}

[data-am-tag~='overflow'] {
  overflow: hidden;
  margin-bottom: -($gutter-half);

  .container & {
    margin-left: -($grid-gutter-lt-md);
    width: calc(100% + #{$grid-gutter-lt-md * 2});

    .tag-container {
      padding-left: $grid-gutter-lt-md;
      padding-right: $grid-gutter-lt-md;
    }
  }

  .tag-container {
    padding-bottom: $gutter-half;
    white-space: nowrap;
    overflow: auto;
    -webkit-overflow-scrolling: touch; // sass-lint:disable-line no-misspelled-properties

    &.contains-tag-row {
      white-space: normal;
    }
  }

  .tag-row {
    display: inline-block;
    white-space: nowrap;
  }
}