@use "sass:math";

[data-wx-notificationindicator] {
  $notification-size: 6px;

  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: $notification-size;
  height: 1.5em; // 1em * line-height (1.5)
  margin-left: -4px; // Inline element spacing (magic number)

  &:after {
    content: '';
    position: absolute;
    top: math.div($notification-size, -2);
    left: 0;
    display: block;
    width: $notification-size;
    height: $notification-size;
    border-radius: 50%;
    background-color: $c-action;
  }
}

[data-wx-notificationindicator~='absolute'] {
  $notification-size: 8px;

  position: absolute;
  top: 0;
  right: auto;
  left: 38px; // Magic number (based on header avatar)
  width: $notification-size;
  height: $notification-size;

  &:after {
    top: 0;
    left: -1px;
    width: $notification-size;
    height: $notification-size;
  }
}
