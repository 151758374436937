[data-wx-detaillist] {
  .detail-list {
    border-top: 1px solid var(--c-border-main);
    border-bottom: 1px solid var(--c-border-main);
  }

  .detail-list-item {
    @include flex-display;
    @include align-items(flex-start);
    @include justify-content(space-between);
    padding-top: gutter(0.75);
    padding-bottom: gutter(0.75);

    & + .detail-list-item {
      border-top: 1px solid var(--c-border-main);
    }

    &.is-open {
      .is-extendable {
        display: block;
      }

      .detail-list-extend-btn {
        &:after {
          content: '\f106';
        }
      }
    }
  }

  .detail-list-item-cell {
    &:last-child {
      text-align: right;
      padding-left: gutter(0.75);
    }
  }

  .detail-list-label {
    &:not(.is-extendable) {
      @include font-medium;
    }
  }

  .is-extendable {
    display: none;
  }

  .detail-list-extend-btn {
    font-weight: var(--font-weight-link);
    color: $c-primary;
    font-size: 1em;

    &:after {
      content: '\f107';
      font-family: 'icons';
      margin-left: gutter(0.375);
    }
  }
}
