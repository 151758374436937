@use "sass:math";

@mixin create-grid {
  $grid-columns: 12;
  $grid-gutter: gutter(1) !global;
  $grid-gutter-lt-md: gutter(1) !global;
  $grid-container-gutter: gutter(3) !global;
  $grid-container-gutter-lt-md: gutter(1) !global;
  $grid-container-max-width: $container-max-width;

  $grid-breakpoints: (
    sm: $screen-sm,
    md: $screen-md,
    lg: $screen-lg,
    xl: $screen-xl,
  ) !default;

  %container-base {
    @include clearfix;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding-left: $grid-container-gutter;
    padding-right: $grid-container-gutter;

    @media (max-width: $screen-lt-md) {
      padding-left: $grid-container-gutter-lt-md;
      padding-right: $grid-container-gutter-lt-md;
    }
  }

  .container {
    @extend %container-base;
    max-width: $grid-container-max-width;

    &.container-small {
      @extend %container-base;
      max-width: 920px;
    }
  }

  .container-fluid {
    @extend %container-base;
  }

  %row-base {
    margin-left: math.div($grid-gutter, -2);
    margin-right: math.div($grid-gutter, -2);

    &.small-gutter {
      margin-left: math.div($grid-gutter, -4);
      margin-right: math.div($grid-gutter, -4);
    }

    @media (max-width: $screen-lt-md) {
      margin-left: math.div($grid-gutter-lt-md, -2);
      margin-right: math.div($grid-gutter-lt-md, -2);

      &.small-gutter {
        margin-left: math.div($grid-gutter-lt-md, -4);
        margin-right: math.div($grid-gutter-lt-md, 4);
      }
    }
  }

  .row {
    @extend %row-base;
    @include clearfix;
  }

  .fluid-row {
    @extend %row-base;
    font-size: 0;

    > * {
      font-size: 1rem;
    }

    > [class^="col-"],
    > [class*=" col-"] {
      float: none;
      display: inline-block;
      vertical-align: top;
    }
  }

  .flex-row {
    @extend %row-base;
    @include flex-display;
    @include flex-direction(row);
    @include flex-wrap(wrap);
    font-size: 0;

    > [class^="col-"],
    > [class*=" col-"] {
      // sass-lint:disable-block mixins-before-declarations
      display: inline-block;
      @include flex-display;
      @include flex(0 0 auto);
      @include flex-direction(column);
      float: none;
      vertical-align: top;

      > * {
        @include flex(1 0 auto);
      }
    }

    > * {
      font-size: 1rem;
    }
  }

  [class^="col-"],
  [class*=" col-"] {
    width: 100%;
    max-width: 100%;
    min-height: 1px;
    float: left;
    padding-left: math.div($grid-gutter, 2);
    padding-right: math.div($grid-gutter, 2);

    .small-gutter & {
      padding-left: math.div($grid-gutter, 4);
      padding-right: math.div($grid-gutter, 4);
    }

    @media (max-width: $screen-lt-md) {
      .small-gutter & {
        padding-left: math.div($grid-gutter-lt-md, 4);
        padding-right: math.div($grid-gutter-lt-md, 4);
      }
    }
  }

  // Create non-breakpoint specific columns
  @for $i from 1 through $grid-columns {
    .col-#{$i} {
      $col-width: (math.div(100%, $grid-columns) * $i);

      width: $col-width;
      max-width: $col-width;
    }

    .col-offset-#{$i} {
      margin-left: (math.div(100%, $grid-columns) * $i);
    }
  }

  // Create breakpoint specific columns
  @each $breakpoint-name, $breakpoint-width in $grid-breakpoints {
    // Create media query for each breakpoint
    @media (min-width: $breakpoint-width) {
      // Add offset-0 to reset offset in specific breakpoints
      .col-#{$breakpoint-name}-offset-0 {
        margin-left: 0;
      }

      // Create columns and column offsets for each size in this breakpoint
      @for $i from 1 through $grid-columns {
        .col-#{$breakpoint-name}-#{$i} {
          $col-width: (math.div(100%, $grid-columns) * $i);

          width: $col-width;
          max-width: $col-width;
        }

        .col-#{$breakpoint-name}-offset-#{$i} {
          margin-left: (math.div(100%, $grid-columns) * $i);
        }
      }
    }
  }
}
@include create-grid;
