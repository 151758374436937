@use "sass:math";

[data-am-pagination] {
  @include clearfix;

  .pagination-prev-container {
    float: left;
  }

  .pagination-next-container {
    float: right;
  }

  .pagination-list {
    overflow: hidden;
    text-align: center;
    font-size: 0;
    list-style: none;
    padding: 0;
    margin: 0;

    > * {
      font-size: 1rem;
    }
  }

  .pagination-item,
  .pagination-navigation {
    display: inline-block;
    vertical-align: baseline;
  }

  .pagination-item {
    &.is-active {
      .pagination-btn {
        background-color: $c-primary-light;
        color: $c-primary;
      }
    }
  }

  .pagination-step,
  .pagination-btn {
    display: block;
    line-height: 1;
  }

  .pagination-step {
    -webkit-tap-highlight-color: $c-fade-dark-00;
    transition: transform 200ms ease;

    &:active {
      @include transform(scale(0.95));
    }

    &.is-disabled {
      cursor: not-allowed;
      opacity: 0.37;
    }
  }

  .pagination-btn {
    padding: $gutter-half ($gutter-half * 1.5);
    margin-left: math.div($gutter-half, 6);
    margin-right: math.div($gutter-half, 6);
    border-radius: $g-border-radius-small;
    -webkit-tap-highlight-color: $c-fade-dark-10;

    &:hover {
      background-color: $c-primary-light;
    }
  }

  .pagination-jump {
    padding: $gutter-half ($gutter-half * 1.5);
    margin-left: math.div($gutter-half, 6);
    margin-right: math.div($gutter-half, 6);
    line-height: 1;
    cursor: default;
  }
}
