@use "sass:math";

[data-am-button] {
  @include unselectable;

  &:active {
    @include transform(scale(0.95));
  }
}

[data-am-button~='icon-big'] {
  padding: math.round(math.div($gutter-half, 3));
  padding-right: $gutter;
}

[data-am-button~='full-height'] {
  padding-top: 0;
  padding-bottom: 0;
}

[data-am-button~='full-width-lt-md'] {
  display: block;
  width: 100%;
}

[data-am-button~='no-padding'] {
  padding: 0;
}

[data-am-button~='input-height'] {
  padding-top: 0;
  padding-bottom: 0;
}
