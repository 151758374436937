[data-wx-detaillist] {
  .detail-list-item {
    font-size: 0;

    > * {
      font-size: 1rem;
    }

    & + .detail-list-item {
      margin-top: gutter(1);
    }
  }

  .detail-list-item-cell {
    display: inline-block;
    vertical-align: top;

    &:first-child {
      width: 35%;
    }

    &:last-child {
      width: 65%;
      padding-left: gutter(1);
    }
  }

  .detail-list-label,
  .detail-list-value {
    &.is-main {
      @include font-medium;
    }
  }

  .detail-list-exten-btn {
    cursor: text;
  }
}
