[data-wx-skeleton] {
  background-color: var(--c-skeleton);
}

[data-wx-skeleton~='text'] {
  height: 1em;
}

[data-wx-skeleton~='circle'] {
  height: 0;
  padding-bottom: 100%;
  border-radius: 50%;
}
