[data-wx-overview] {
  .overview-spec-cell {
    width: 50%;
  }

  .overview-spec-key {
    font-size: 0.875rem;
  }

  .overview-spec-value {
    font-size: 1rem;
  }

  .overview-pro-con-cell {
    & + .overview-pro-con-cell {
      margin-top: gutter(1);
      padding-top: gutter(1);
      border-top: 1px solid var(--c-border-main);
    }
  }
}
