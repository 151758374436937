[data-wx-itemcontent] {
  $item-content-left-col-width: 66.66667%;
  $item-content-right-col-width: 33.33333%;

  .item-content-main {
    position: relative;
  }

  .item-section {
    margin-bottom: gutter(3.5);
    width: $item-content-left-col-width;
    padding-right: gutter(1);
  }

  // Convert Card

  .item-section-convert-card {
    position: absolute;
    top: gutter(1);
    right: 0;
    width: $item-content-right-col-width;
    height: 100%;
    padding-right: 0;
  }

  .item-section-convert-card-part {
    & + .item-section-convert-card-part {
        margin-top: gutter(1.5);
    }
  }

  // Gallery

  .item-section-gallery {
    width: 100%;
    padding-right: 0;
    margin-bottom: gutter(1);
  }

  .item-section-gallery-controls {
    width: $item-content-left-col-width;
    padding-right: gutter(1);
    margin-bottom: gutter(2);
  }

  .item-content-gallery {
    @include transform(translateX(-50%));
    position: relative;
    width: 100vw;
    left: 50%;
  }

  .item-content-gallery-window-container {
    width: 100%;
    max-width: $container-max-width;
    padding-left: $grid-container-gutter;
    padding-right: $grid-container-gutter;
    margin: 0 auto;
  }

  .item-content-gallery-window {
    width: $item-content-left-col-width;
    padding-right: gutter(1);
  }

  // Exit state (360 media)

  .btn-exit-state {
    bottom: calc(51px + #{gutter(1)}); // TODO: Change! 51px = height of [data-wx-gallerycontrols]
  }

  // Separator

  .item-section-stretch {
    @include transform(translateX(-50%));
    position: relative;
    width: 100vw;
    padding-right: 0;
    left: 50%;
  }

  .item-section-full-width {
    width: 100%;
    padding-right: 0;
  }
}
