[data-wx-togglelist] {
  .toggle-list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 0;

    > * {
      font-size: 1rem;
    }
  }

  .toggle-list-item {
    display: inline-block;
    vertical-align: middle;

    & + .toggle-list-item {
      margin-left: gutter(1);
    }
  }

  .toggle-list-action {
    @include font-medium;
    position: relative;
    display: block;
    padding-top: gutter(0.375);
    padding-bottom: gutter(0.375);
    font-size: 0.875rem;
    color: var(--c-text-main);
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }

    &.is-active {
      opacity: 1;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background-color: $c-action;
      }
    }
  }
}
