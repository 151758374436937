@use "sass:math";

[data-am-image360] {
  // sass-lint:disable-block no-duplicate-properties
  @include keyframes(lightbox-360-description) {
    0%,
    100% {
      @include transform(translateX(-50%) translateY(50%));
      opacity: 0;
    }

    15%,
    85% {
      @include transform(translateX(-50%) translateY(0));
      opacity: 1;
    }
  }

  background-color: var(--c-contrast-bg);
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  &:after {
    @include animation(lightbox-360-description 4s ease forwards 600ms);
    @include font-medium;
    content: attr(data-description);
    position: absolute;
    bottom: $gutter-quadruple;
    left: 50%;
    background-color: $c-complement;
    color: var(--c-text-media);
    padding: math.div($gutter-half, 2) $gutter-half;
    border-radius: $g-border-radius;
    opacity: 0;
    font-size: 0.75rem;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    pointer-events: none;
  }
}

[data-am-image360~='contain'] {
  height: 100%;

  > * {
    position: relative;
    height: 100%;

    img {
      position: absolute;
      top: auto;
      bottom: auto;
      left: auto;
      right: auto;
      width: 100% !important;
      height: 100% !important;
      object-fit: contain;
      margin: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
