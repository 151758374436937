[data-wx-listsection] {
  .list-section-content,
  .list-section-action {
    margin-top: gutter(1);
  }

  .list-section-action {
    text-align: center;
  }
}
