[data-wx-datalist] {
    margin: gutter(-0.75) 0;
    font-size: var(--font-size-regular);

    .data-list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .data-list-item {
        @include flex-display;
        @include align-items(center);
        gap: gutter(0.75);
        padding: gutter(0.75) 0;

        & + .data-list-item {
            border-top: 1px solid var(--c-border-main);
        }

        &.is-boolean {
            .data-list-value {
                order: -1;
            }
        }
    }

    .data-list-label {
        @include flex(1 1 auto);
    }

    .data-list-value {
        margin-left: auto;
        text-align: right;
    }

    .data-list-btn {
        display: block;
        color: $c-primary;
        text-align: left;
        font-size: inherit;
        font-weight: var(--font-weight-link);

        &:hover {
            text-decoration: underline;
        }
    }
}
