[data-wx-overview] {
  margin-left: -($grid-container-gutter);
  margin-right: -($grid-container-gutter);
  padding: gutter(1) $grid-container-gutter;

  .overview-pro-con-cell {
    width: 50%;
    padding-left: gutter(0.5);
    padding-right: gutter(0.5);

    &:nth-child(odd) {
      padding-left: 0;
    }

    &:nth-child(even) {
      padding-right: 0;
    }
  }
}
