@use "sass:math";

[data-wx-itemcontent] {
    .item-section {
       &:empty {
        display: none;
       }
      }

  .item-content-gallery {
    overflow: hidden; // TODO: Is this needed after removing overflow: visible from [data-wx-slick]?
  }

  .item-content-gallery-window {
    position: relative;
  }

  .btn-exit-state {
    $button-height: 40px;

    @include font-medium;
    @include transform(translateX(-50%));
    position: absolute;
    left: 50%;
    padding: gutter(0.75) gutter(1.5);
    min-height: $button-height;
    border-radius: math.div($button-height, 2);
    background-color: $c-complement;
    color: $c-text-on-complement;
    font-size: 0.875rem;
  }
}
