[data-am-togglebar] {
  .toggle-bar-header {
    @include flex-display;
    @include justify-content(space-between);
    @include align-items(center);
  }

  .toggle-bar-header-section {
    & + .toggle-bar-header-section {
      padding-left: ($gutter-half * 1.5);
    }
  }

  .toggle-bar-logo {
    width: 100%;
  }

  .toggle-bar-body {
    margin-top: ($gutter-half * 1.5);
  }

  .toggle-bar-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
}
