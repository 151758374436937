[data-am-linkcloud] {
  .link-cloud-item {
    width: 25%;
  }

  .link-cloud-link {
    &:hover {
      .link-cloud-item-label {
        text-decoration: underline;
      }
    }
  }

  .link-cloud-extend-btn {
    &:hover {
      text-decoration: underline;
    }
  }
}
