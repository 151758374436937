@use "sass:math";

[data-am-chat] {
  $chat-bubble-width: 55%;
  $profile-picture-size: 30px;
  $chat-header-action-width: 40px;

  .chat-header {
    position: relative;
    text-align: center;
    padding: $gutter-half 0;
    border-bottom: 1px solid var(--c-border-main);
  }

  .chat-header-section {
    padding-left: $chat-header-action-width;
    padding-right: $chat-header-action-width;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .chat-header-action {
    position: absolute;
    top: 0;
    display: block;
    height: 100%;
    width: $chat-header-action-width;
    color: $c-primary;
    font-size: 1.8rem;

    > * {
      @include transform(translate(-50%, -50%));
      position: absolute;
      top: 50%;
      left: 50%;
    }

    &.chat-header-action-left {
      left: 0;
    }

    &.chat-header-action-right {
      right: 0;
    }
  }

  .chat-header-action-logo {
    display: block;
    width: 18px;
  }

  .chat-header-title,
  .chat-header-pre-title {
    padding-left: $gutter;
    padding-right: $gutter;
    font-size: 0.875rem;
  }

  .chat-header-pre-title {
    color: var(--c-text-muted);
  }

  .chat-container {
    position: relative;
    padding: $gutter;
    padding-bottom: 0;
    min-height: 500px;
    max-height: 600px;
    overflow: auto;
    -webkit-overflow-scrolling: touch; // sass-lint:disable-line no-misspelled-properties

    // Padding bottom with "overflow: auto;" work around for Firefox
    &:after {
      content: '';
      display: block;
      height: $gutter;
    }

    // Place messages at bottom if messages does not fill the whole screen
    > * {
      &:first-child {
        margin-top: auto;
      }
    }
  }

  .chat-group {
    position: relative;

    & + .chat-group {
      margin-top: $gutter;
    }

    &.received {
      padding-left: calc(#{$profile-picture-size} + #{$gutter-half});
    }
  }

  .chat-bubble {
    $arrow-size: 3px;

    @include clearfix;
    width: $chat-bubble-width;

    & + .chat-bubble {
      margin-top: math.div($gutter-half, 2);
    }

    p {
      margin-bottom: $gutter-half;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .chat-bubble-inner {
    float: left;
    padding: $gutter-half ($gutter-half * 1.5);
    border-radius: 10px;
    line-height: 1.4;
    max-width: 100%;
    word-break: break-word;
    overflow-wrap: break-word;
    transition: border-radius 400ms ease;
  }

  .chat-time-stamp {
    @include font-medium;
    font-size: 0.7em;
    margin-top: math.div($gutter-half, 2);
    line-height: 1;
  }

  .chat-bubble-received {
    .chat-bubble-inner {
      background-color: $c-accent;
    }

    &:first-child {
      &:not(:only-child) {
        .chat-bubble-inner {
          border-bottom-left-radius: 0;
        }
      }
    }

    & + .chat-bubble-received {
      .chat-bubble-inner {
        border-top-left-radius: 0;
      }

      &:not(:last-child) {
        .chat-bubble-inner {
          border-bottom-left-radius: 0;
        }
      }
    }

    .chat-time-stamp {
      color: $c-fade-dark-30;
    }
  }

  .chat-bubble-sent {
    margin-left: (100% - $chat-bubble-width);

    .chat-bubble-inner {
      background-color: $c-primary;
      color: $c-text-on-primary;
      float: right;
    }

    &:first-child {
      &:not(:only-child) {
        .chat-bubble-inner {
          border-bottom-right-radius: 0;
        }
      }
    }

    & + .chat-bubble-sent {
      .chat-bubble-inner {
        border-top-right-radius: 0;
      }

      &:not(:last-child) {
        .chat-bubble-inner {
          border-bottom-right-radius: 0;
        }
      }
    }

    &.chat-bubble-loading {
      .chat-bubble-inner {
        line-height: 1;
      }
    }

    .chat-time-stamp {
      color: $c-fade-light-40;
      text-align: right;
    }
  }

  .chat-bubble-location {
    display: block;
    width: $chat-bubble-width;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $c-accent;
    overflow: hidden;
  }

  .chat-bubble-location-image {
    display: block;
    width: 100%;
    height: auto;
    -webkit-tap-highlight-color: $c-fade-dark-00;
  }

  .chat-bubble-location-title {
    @include font-medium;
    padding: math.div($gutter-half, 2) ($gutter-half * 1.5);
    background-color: $c-accent;
    font-size: 0.875rem;
  }

  .received-profile-picture {
    position: absolute;
    bottom: 0;
    left: 0;
    width: $profile-picture-size;
    height: $profile-picture-size;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $c-fade-dark-15;
  }

  .time-stamp {
    text-align: center;
    color: var(--c-text-muted);
    font-size: 0.875em;
    margin-top: $gutter;

    & + .chat-group {
      margin-top: $gutter;
    }
  }

  .received-name {
    font-size: 0.75em;
    color: var(--c-text-muted);
    padding-left: $gutter-half;
    margin-bottom: math.div($gutter-half, 2);
  }

  // Chat alert

  .chat-alert {
    padding: $gutter-half;
    border: 1px solid var(--c-border-main);
    background-color: $c-accent-dark;
    color: var(--c-text-muted);
    border-radius: $g-border-radius;
    margin-top: $gutter;
  }

  // Chat input

  .chat-input {
    @include flex-display;
    @include flex-direction(row);
    @include align-items(flex-end);
    padding: math.div($gutter-half, 2) $gutter-half;
    border-top: 1px solid var(--c-border-main);

    &.has-focus {
      .chat-input-send-container {
        display: block;
      }
    }
  }

  .chat-input-utility-container {
    white-space: nowrap;
  }

  .chat-input-text-container {
    @include flex(1 1 auto);
  }

  .chat-input-send-container {
    @include align-self(center);
    @include animation(fadeIn 400ms ease);
    display: none;
  }

  .chat-input-text-input {
    display: block;
    width: 100%;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: $gutter-half;
    background-color: var(--c-bg-main);
    font-size: 16px; // Set font size in px to prevent zoom in on iOS
    line-height: 1.2;
    resize: none;

    &:focus {
      outline: none;
    }
  }

  .chat-input-utility-btn {
    font-size: 16px;
    padding: $gutter-half;
    line-height: 1.2;
    color: var(--c-text-muted);
  }

  .chat-input-send-btn {
    @include font-medium;
    font-size: 0.875rem;
    padding: $gutter-half;
    line-height: 1;
    color: $c-primary;

    &.is-disabled {
      color: var(--c-text-muted);
    }
  }
}
