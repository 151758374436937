@use "sass:math";

[data-wx-filter] {
  $filter-height: 36px;
  $filter-spacing: 4px;

  .filter-list {
    margin-top: -($filter-spacing);
    font-size: 0;

    > * {
      font-size: 1rem;
    }
  }

  .filter-utilities,
  .filter-items {
    font-size: 0;

    > * {
      font-size: 1rem;
    }
  }

  .filter-group,
  .filter {
    display: inline-block;
    vertical-align: middle;
    margin-right: $filter-spacing;
    margin-top: $filter-spacing;
  }

  .filter-group {
    padding: $filter-spacing;
    padding-top: 0;
    padding-right: 0;
    background-color: $c-action-light;
    border-radius: math.div(($filter-height + ($filter-spacing * 2)), 2);
    font-size: 0;

    > * {
      font-size: 1rem;
    }
  }

  .filter-group-action {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: $filter-height;
    height: $filter-height;
    margin-top: $filter-spacing;
    color: $c-action;

    &:first-child {
      border-top-left-radius: math.div($filter-height, 2);
      border-bottom-left-radius: math.div($filter-height, 2);
    }

    &:last-child {
      border-top-right-radius: math.div($filter-height, 2);
      border-bottom-right-radius: math.div($filter-height, 2);
    }
  }

  .filter {
    position: relative;
    background-color: $c-action;
    color: $c-text-on-action;
    border-radius: math.div($filter-height, 2);
    white-space: nowrap;
    font-size: 0;

    &::before {
        content: "";
        position: absolute;
        inset: 1px;
        background-color: var(--c-overlay);
        border-radius: math.div($filter-height, 2);
        mix-blend-mode: soft-light;
        opacity: 0;
        transition: opacity 200ms ease;
    }

    &:hover {
        &::before {
            opacity: 1;
        }
    }

    > * {
      font-size: 1rem;
    }

    &.is-excluded {
      background-color: $c-action-light;
      color: $c-action;

      .filter-action {
        [class^='icon-'],
        [class^=' icon-'] {
          &:after {
            background-color: $c-action-light;
          }
        }
      }
    }

    &.is-disabled {
        opacity: 0.37;
        cursor: not-allowed;
    }

    &.is-clear {
      background-color: transparent;
      color: $c-action;

      &::before {
        content: none;
      }

      .filter-action {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .filter-action {
    @include font-medium;
    display: inline-flex;
    align-items: center;
    vertical-align: top;
    height: $filter-height;
    font-size: 0.875rem;
    white-space: nowrap;
    padding-left: gutter(0.5);
    padding-right: gutter(0.5);

    &:first-child {
      padding-left: gutter(1);
    }

    &:last-child {
      padding-right: gutter(1);
    }

    &.is-remove {
      opacity: 0.6;

      [class^='icon-'],
      [class^=' icon-'] {
        position: relative;

        &:after {
          $indicator-size: 20px;

          @include transform(translate(-50%, -50%));
          content: '';
          display: none;
          position: absolute;
          top: 50%;
          left: 50%;
          width: $indicator-size;
          height: $indicator-size;
          border-radius: 50%;
          background-color: $c-fade-dark-20;
          z-index: -1;
        }
      }
    }

    &[disabled] {
      cursor: default;
    }

    [class^='icon-'],
    [class^=' icon-'] {
      display: inline-block;

      &:before {
        display: block;
        line-height: 1;
      }
    }
  }
}

[data-wx-filter~='small'] {
  $filter-height: 24px;
  $filter-spacing: 4px;

  .filter-list {
    margin-top: -($filter-spacing);
  }

  .filter-group,
  .filter {
    margin-right: $filter-spacing;
    margin-top: $filter-spacing;
  }

  .filter-group {
    padding: $filter-spacing;
    padding-top: 0;
    padding-right: 0;
    border-radius: math.div(($filter-height + ($filter-spacing * 2)), 2);
  }

  .filter-group-action {
    width: $filter-height;
    height: $filter-height;

    &:first-child {
      border-top-left-radius: math.div($filter-height, 2);
      border-bottom-left-radius: math.div($filter-height, 2);
    }

    &:last-child {
      border-top-right-radius: math.div($filter-height, 2);
      border-bottom-right-radius: math.div($filter-height, 2);
    }
  }

  .filter {
    border-radius: math.div($filter-height, 2);
  }

  .filter-action {
    height: $filter-height;
    line-height: $filter-height;
    font-size: 0.75rem;

    &:first-child {
      padding-left: gutter(0.75);
    }

    &:last-child {
      padding-right: gutter(0.75);
    }
  }
}
