[data-wx-profilepicture] {
  $profile-picutre-size-md: 100px;

  width: $profile-picutre-size-md;
  height: $profile-picutre-size-md;

  &:hover {
    .profile-picture-img {
      &:before {
        opacity: 1;
      }
    }

    .profile-picture-upload-btn {
      color: var(--c-text-main);
    }
  }

  .profile-picture {
    width: $profile-picutre-size-md;
    height: $profile-picutre-size-md;
  }

  .profile-picture-img {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: $g-x-box-shadow;
      border-radius: 50%;
      z-index: -1;
      opacity: 0;
      transition: opacity 200ms ease;
    }
  }
}
