.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @include font-bold;
  margin-top: 0;
  position: relative;
  margin-bottom: gutter(1);
  letter-spacing: -0.01em;
  text-wrap: balance;
}

.h1 {
  font-size: var(--font-size-h1);
  line-height: var(--line-height-h1);
  letter-spacing: -0.02em;
}

.h2 {
  font-size: var(--font-size-h2);
  line-height: var(--line-height-h2);
}

.h3 {
  font-size: var(--font-size-h3);
  line-height: var(--line-height-h3);
}

.h4 {
  font-size: var(--font-size-h4);
  line-height: var(--line-height-h4);
}

.h5 {
  font-size: var(--font-size-h5);
  line-height: var(--line-height-h5);
}

.h6 {
  font-size: var(--font-size-h6);
  line-height: var(--line-height-h6);
  letter-spacing: 0.15em;
  text-transform: uppercase;
  opacity: 0.8;
}
