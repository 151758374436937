[data-wx-uspgrid] {
  .usp-grid-item {
    @include flex-display;
    @include align-items(center);

    & + .usp-grid-item {
      margin-left: gutter(1.5);
    }
  }

  .usp-grid-label {
    margin-left: gutter(0.5);
  }

  .usp-grid-meta {
    margin-left: gutter(0.5);
  }
}
