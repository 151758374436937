[data-wx-helperbox] {
    .helperbox-header {
        @include flex-display;
        @include align-items(center);
        width: 100%;
        text-align: left;
    }

    .helperbox-title {
        @include flex(1 1 auto);
        @include font-bold;
        font-size: 0.75rem;
        margin: auto;
    }

    .helperbox-label {
        @include font-medium;
        display: block;
        flex: 1 1 auto;
        font-size: 0.875rem;
    }

    .helperbox-icon {
        color: $c-primary;
        flex-shrink: 0;
        padding-left: gutter(0.5);
    }

    .content {
        background-color: $c-accent;
        padding: gutter(0.75);
        margin-top: gutter(0.5);
    }
}
