[data-wx-page] {
  margin-top: gutter(4.5);
  margin-bottom: gutter(4.5);

  .page-section {
    &.page-section-accent {
      padding: gutter(4.5) 0;
    }

    & + .page-section {
      margin-top: gutter(4.5);

      &.page-section-thin {
        margin-top: gutter(1.5);
      }
    }

    &.page-section-bg {
      background-image: none !important;
    }

    &.page-section-thin {
      &:first-child {
        margin-top: -(gutter(4.5) - gutter(1.5));
      }

      & + .page-section {
        margin-top: gutter(1.5);
      }
    }
  }

  // Elements used to create column layout

  .page-columns {
    @include flex-display;
    @include align-items(stretch);
  }

  .page-column-aside {
    @include flex-shrink(0);
    width: 355px;
    padding-right: gutter(2);
    margin-right: gutter(4.5);
    border-right: 1px solid var(--c-border-main);
  }

  .page-column-main {
    @include flex(1 1 auto);
  }
}

[data-wx-page~="top-thin"] {
  margin-top: 0;
}

[data-wx-page~="relative-top-thin"] {
  margin-top: 0;

  .page-section {
    &.page-section-thin {
      &:first-child {
        margin-top: 0;
      }
    }
  }
}

[data-wx-page~="no-margin-bottom"] {
  margin-bottom: 0;
}
