@use "sass:math";

[data-wx-overflowgrid] {
  position: relative;

  .overflow-grid-container {
    overflow: hidden;
    padding: gutter(0.5);
    margin: gutter(-0.5);
    padding-bottom: gutter();
    margin-bottom: gutter(-1);
  }

  .overflow-grid {
    transition: transform 400ms ease;
  }

  .overflow-grid-item {
    width: 100%;
  }

  .offset-btn {
    position: absolute;
    top: 50%;
  }

  .offset-btn-prev {
    $offset-btn-prev-translate: translate(-50%, -50%);

    @include transform($offset-btn-prev-translate);
    left: 0;
  }

  .offset-btn-next {
    $offset-btn-next-translate: translate(50%, -50%);

    @include transform($offset-btn-next-translate);
    right: 0;
  }

  &[data-offset='0'] {
    .offset-btn-prev {
      display: none;
    }
  }

  .container & {
    max-width: calc(100vw - #{$grid-container-gutter * 2});
  }
}

// Small
// This modifiers displays smaller cards on smaller screens.
[data-wx-overflowgrid~='overflow-small'] {
  .overflow-grid-item {
    width: 100%;
  }
}

// Desktop Size
// Number of visible items. Items outside of the viewport will be visible through a carousel
@for $i from 2 through 6 {
  [data-wx-overflowgrid~='desktop-#{$i}'] {
    .overflow-grid {
      @include flex-wrap(nowrap);
    }

    .overflow-grid-item {
      width: math.div(100%, $i);

      &.is-wide {
        width: (math.div(100%, $i) * 2);
      }
    }

    $max-offsets: 8;
    @for $j from 0 through $max-offsets {
      &[data-offset='#{$j}'] {
        .overflow-grid {
          @include transform(translateX(-(math.div(100%, $i) * $j)));
        }
      }
    }

    // Offset Button 16:9
    &[data-wx-overflowgrid~='offset-button-16:9'] {
      .offset-btn {
        padding-top: calc(((100% - #{gutter($i - 1)}) / #{$i}) * 0.5625);
      }
    }

    // Offset Button 3:2
    &[data-wx-overflowgrid~='offset-button-3:2'] {
      .offset-btn {
        padding-top: calc(((100% - #{gutter($i - 1)}) / #{$i}) * 0.6666666667);
      }
    }
  }
}

// Offset Button 16:9
// Place offset-btn in middle of an element with proportions of 16:9
[data-wx-overflowgrid~='offset-button-16:9'] {
  .offset-btn {
    top: gutter(0.5);
    padding-top: calc(100% * 0.5625);
  }
}

// Offset Button 3:2
// Place offset-btn in middle of an element with proportions of 3:2
[data-wx-overflowgrid~='offset-button-3:2'] {
  .offset-btn {
    top: gutter(0.5);
    padding-top: calc(100% * 0.6666666667);
  }
}
