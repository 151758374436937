@use "sass:selector";

[data-am-link] {
  color: $c-primary;
  font-weight: var(--font-weight-link);
  cursor: pointer;

  // Overwrite button font-size
  @at-root {
    #{selector.unify(&, button)} {
        font-size: 1em;

      &[disabled] {
        opacity: 0.5;
        cursor: default;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

// Colors

[data-am-link~='action'] {
  color: $c-action;
}

[data-am-link~='on-primary'] {
  color: $c-text-on-primary;
}

[data-am-link~='current-color'] {
  color: currentColor;
}

// Font Size

[data-am-link~='font-inherit'] {
  @at-root {
    #{selector.unify(&, button)} {
      font-size: 1em;
    }
  }
}

// Weight

[data-am-link~='thin'] {
  @include font-light;
}

[data-am-link~='medium'] {
  @include font-medium;
}

// Layout

[data-am-link~='underline'] {
  text-decoration: underline;
}

// Height

[data-am-link~='height-adoptive'] {
  @include inline-flex-display;
  @include align-items(center);
}

// Clickable Parent

// Combine with a relative positioned element to make it clickable
[data-am-link~='clickable-parent'] {
  position: static;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
