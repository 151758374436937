@use "sass:math";

[data-wx-switch] {
  $switch-width: 40px;
  $switch-height: 24px;
  $thumb-margin: 2px;

  display: inline-block;
  vertical-align: middle;

  .checkbox {
    position: fixed;
    left: -9999px;
    opacity: 0;

    &:checked {
      // sass-lint:disable-block nesting-depth
      + .switch {
        background-color: $c-action;

        &:before {
          @include transform(translate3d(calc(100% - #{$thumb-margin * 2}), 0, 0.00001px));
          content: '\e831';
          color: $c-action;
        }
      }
    }
  }

  .switch {
    position: relative;
    display: block;
    width: $switch-width;
    height: $switch-height;
    background-color: $c-accent-dark;
    border-radius: math.div($switch-height, 2);
    cursor: pointer;
    transition: background-color 200ms ease;

    &:before {
      content: '\e816';
      font-family: 'icons';
      position: absolute;
      left: 0;
      width: ($switch-height - ($thumb-margin * 2));
      height: ($switch-height - ($thumb-margin * 2));
      line-height: ($switch-height - ($thumb-margin * 2));
      border-radius: 50%;
      background-color: var(--c-bg-main);
      color: var(--c-text-muted);
      box-shadow: $g-box-shadow;
      margin: $thumb-margin;
      font-size: 0.875rem;
      text-align: center;
      transition: transform 200ms ease, color 200ms ease;
    }
  }
}
