[data-wx-filtercontainer] {
  $animation-speed: $g-filter-container-animation-speed;

  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  &.is-open {
    display: block;
  }

  .filter-container-section-nav {
    background-color: var(--c-bg-main);
  }

  .filter-container-section-content {
    position: relative;
  }

  .filter-container-content {
    z-index: 1;
  }

  .ui-block {
    @include animation(fadeIn $animation-speed ease);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $c-fade-dark-40;
  }
}
