[data-wx-overview] {
  $pro-con-list-spacing: (gutter(1) + gutter(0.75));

  margin-left: -($grid-container-gutter-lt-md);
  margin-right: -($grid-container-gutter-lt-md);
  padding: gutter(1) $grid-gutter-lt-md;
  background-color: $c-accent;

  .overview-section {
    & + .overview-section {
      margin-top: gutter(1);
      padding-top: gutter(1);
      border-top: 1px solid var(--c-border-main);
    }
  }

  .overview-spec {
    margin: gutter(-0.25) gutter(-0.5);
    font-size: 0;

    > * {
      font-size: 1rem;
    }
  }

  .overview-spec-cell {
    display: inline-block;
    vertical-align: top;
    width: 25%;
    padding: gutter(0.25) gutter(0.5);
  }

  .overview-spec-key {
    @include font-medium;
    font-size: 0.75rem;
  }

  .overview-spec-value {
    font-size: 0.875rem;
  }

  .overview-pro-con {
    font-size: 0;

    > * {
      font-size: 1rem;
    }
  }

  .overview-pro-con-cell {
    display: inline-block;
    vertical-align: top;
    width: 100%;
  }

  .overview-pro-con-list {
    list-style: none;
    margin: 0;
    padding-left: $pro-con-list-spacing;

    &.is-pro {
      .overview-pro-con-list-item {
        &:before {
          content: '\e82c';
          color: $c-ui-success;
        }
      }
    }

    &.is-con {
      .overview-pro-con-list-item {
        &:before {
          content: '\e82b';
          color: $c-ui-danger;
        }
      }
    }
  }

  .overview-pro-con-list-item {
    &:before {
      font-family: 'icons';
      display: inline-block;
      width: $pro-con-list-spacing;
      margin-left: -($pro-con-list-spacing);
    }

    & + .overview-pro-con-list-item {
      margin-top: gutter(0.25);
    }
  }
}
