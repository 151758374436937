[data-am-togglebar] {
  background-color: $c-accent;
  padding: ($gutter-half * 1.5);
  border-radius: $g-border-radius-small;

  .toggle-bar-logo {
    display: block;
  }

  .toggle-bar-body {
    color: var(--c-text-muted);
  }

  .toggle-bar-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .toggle-bar-link {
    white-space: nowrap;
  }
}
