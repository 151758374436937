[data-wx-dotnavigation] {
  .dot-navigation-list {
    @include flex-display;
    @include align-items(center);
    @include justify-content(center);
    list-style: none;
    padding: 0;
    margin: gutter(-0.25);
  }

  .dot-navigation-item {
    position: relative;
    padding: gutter(0.25);
  }

  .dot-navigation-action {
    display: block;
    width: gutter(0.5);
    height: gutter(0.5);
    border-radius: 50%;
    background-color: var(--c-text-muted);
    opacity: 0.5;
    font-size: 0;

    &:before {
      content: '';
      position: absolute;
      top: gutter(-0.25);
      bottom: gutter(-0.25);
      left: gutter(-0.25);
      right: gutter(-0.25);
    }

    &:hover {
      opacity: 1;
    }

    &.is-active {
      background-color: $c-action;
      opacity: 1;
    }
  }
}
