@use "sass:math";

[data-am-contentbox] {
  .table,
  .tr,
  .td {
    display: block;
    width: 100%;

    img {
      width: 100%;
    }
  }

  .content-box-table {
    .tr {
      border-bottom: 1px solid var(--c-border-main);
      padding: $gutter-half 0;

      &:last-child {
        border-bottom: 0;
      }
    }

    .td {
      border-top: 0;
      padding: 0 $gutter;

      [data-am-label] {
        margin-bottom: math.div($gutter-half, 2);
      }
    }
  }
}
