[data-wx-listcard] {
  .list-card-body,
  .list-card-footer {
    padding: gutter(0.75);
  }

  .list-card-body {
    & + .list-card-footer {
      padding-top: 0;
    }
  }

  .list-card-logo {
    max-width: 110px;
  }
}

[data-wx-listcard~='padding-small'] {
  .list-card-body,
  .list-card-footer {
    padding: gutter(1);
  }

  .list-card-body {
    & + .list-card-footer {
      padding-top: 0;
    }
  }
}
