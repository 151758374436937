@use "sass:math";

[data-am-slick] {
  .slick-slider {
    @include unselectable;
    position: relative;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    &.slick-initialized {
      .slick-slide {
        display: block;
      }
    }
  }

  [dir="rtl"] & {
    .slick-slide {
      float: right;
    }
  }

  .slick-list,
  .slick-slider,
  .slick-track {
    position: relative;
    display: block;
  }

  .slick-list,
  .slick-track {
    @include transform(translate3d(0, 0, 0));
  }

  .slick-track,
  .slick-slide {
    .slick-loading & {
      visibility: hidden;
    }
  }

  .slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;

    &:focus {
      outline: none;
    }

    &.dragging {
      cursor: pointer;
      cursor: hand;
    }
  }

  .slick-track {
    top: 0;
    left: 0;
    background-color: var(--c-contrast-bg);

    &:before,
    &:after {
      content: '';
      display: table;
    }

    &:after {
      clear: both;
    }
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;

    &:focus {
      outline: none;
    }

    &.slick-loading {
      .slick-slide-item {
        display: none;
      }
    }

    &.dragging {
      .slick-slide-item {
        pointer-events: none;
      }
    }
  }

  // Dots

  .slick-dots {
    $slick-dot-size: gutter(0.5);

    position: absolute;
    bottom: gutter(1);
    left: 50%;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: math.div($slick-dot-size, -2);
    white-space: nowrap;
    font-size: 0;
    z-index: 10;

    > * {
      font-size: 1rem;
    }

    li {
      display: inline-block;
      vertical-align: middle;
      transition: transform 300ms ease;

      & + li {
        margin-left: gutter(0.5);
      }

      &.slick-active {
        button {
          &:before {
            background-color: $c-action;
          }
        }
      }

      button {
        display: block;
        font-size: 0;
        line-height: 0;
        padding: gutter(0.25);
        margin: gutter(-0.25);

        &:before {
          content: '';
          display: block;
          width: $slick-dot-size;
          height: $slick-dot-size;
          border-radius: 50%;
          background-color: $c-fade-light-50;
          border: 1px solid rgba(var(--c-overlay-rgb), 0.1);
          transition: background-color 300ms ease;
        }
      }
    }
  }

  // Arrow

  .slick-arrow {
    @include transform(translateY(-50%));
    position: absolute;
    top: 50%;
    font-size: 2rem;
    color: var(--c-text-media);
    text-shadow: $g-text-shadow;
    z-index: 10;

    [class^='icon-'],
    [class*=' icon-'] {
      &:before {
        display: block;
        line-height: 1;
      }
    }
  }

  .slick-arrow-prev {
    left: gutter(1);
  }

  .slick-arrow-next {
    right: gutter(1);
  }

  // Custom elements

  .slick-slide-item {
    display: block;
    cursor: zoom-in;
  }

  .slick-slide-image-container, .slick-slide-video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 66.666%;
  }

  .slick-slide-image, .slick-slide-video {
    position: absolute;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  // Media Button

  [data-am-mediabutton] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// This modifier should be used on instances where Slick is rendered in small areas, such as in the product list
[data-am-slick~='small'] {
  // Dots

  .slick-dots {
    $slick-dot-size: gutter(0.375);

    bottom: gutter(0.5);

    li {
      & + li {
        margin-left: gutter(0.25);
      }

      &.slick-active {
        button {
          background-color: var(--c-bg-main);
        }
      }

      button {
        &:before {
          width: $slick-dot-size;
          height: $slick-dot-size;
        }
      }
    }
  }

  // Arrow

  .slick-arrow {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  .slick-arrow-prev {
    left: gutter(0.25);
  }

  .slick-arrow-next {
    right: gutter(0.25);
  }
}
