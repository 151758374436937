[data-wx-select] {
  $select-offset: 25px; // Magic number

  position: relative;
  overflow: hidden;
  z-index: 0;
  background-color: var(--c-bg-main);
  border: 1px solid transparent;
  box-shadow: inset 0 1px 3px $c-fade-dark-20;
  border-radius: $g-border-radius-small;

  &:has([disabled]),
  &.is-disabled {
    background-color: $c-accent;
    color: var(--c-text-muted);
    opacity: 1; // Required to display correct color in Safari

    .select {
      color: var(--c-text-muted);
    }
  }

  &:before {
    content: '\f107';
    font-family: 'icons';
    position: absolute;
    top: 50%;
    height: 1em;
    line-height: 1em;
    font-size: 0.875rem;
    margin-top: -0.5em;
    right: gutter(1);
    z-index: -1;
    transition: color 200ms ease;
  }

  .select {
    @include font-light;
    position: relative;
    -webkit-appearance: none;
    border: 0;
    padding: 0;
    padding-left: gutter(1);
    color: var(--c-text-main);
    border-radius: 0;
    width: calc(100% + #{$select-offset});
    background-color: $c-fade-light-00;
    height: $g-input-height;
    font-size: 1rem;
    cursor: pointer;
    z-index: 1;

    &::-ms-expand {
      display: none;
    }

    &:focus {
      outline: none;
      border: 1px solid var(--c-border-main);
    }
  }
}
