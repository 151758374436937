[data-am-spinner] {
  $spinner-size: 60px;
  $spinner-dots: 15;
  $spinner-dot-delay: 0.02;
  $spinner-animation-speed: 2000ms;

  // Add loading animation to Wayke Logo
  @for $i from 1 through $spinner-dots {
    @include keyframes(spinner-dot-#{$i}) {
      0%,
      #{100% * (($i - 1) * $spinner-dot-delay)},
      100% {
        @include transform(scale(0));
      }

      // Add the delay to 33% so every dot has the same animation time
      #{33% + (100% * (($i - 1) * $spinner-dot-delay))} {
        @include transform(scale(1));
      }
    }

    .spinner-dot-#{$i} {
      @include animation(spinner-dot-#{$i} $spinner-animation-speed linear infinite);
    }
  }

  .spinner {
    display: none;
    width: $spinner-size;
    height: auto;
    fill: var(--c-text-main);
  }

  // Transform Origin need to be set in px relative to the svg viewBox in order to work in Firefox
  // These values are theoretically the same as '50% 50%' (circle's cx and cy)
  @mixin spinner-dot-transform-origin($cx, $cy) {
    @include transform-origin(($cx * 1px) ($cy * 1px));
  }

  .spinner-dot-1 { @include spinner-dot-transform-origin(4.25, 39.68); }
  .spinner-dot-2 { @include spinner-dot-transform-origin(25.15, 68.03); }
  .spinner-dot-3 { @include spinner-dot-transform-origin(46.04, 96.38); }
  .spinner-dot-4 { @include spinner-dot-transform-origin(66.93, 68.03); }
  .spinner-dot-5 { @include spinner-dot-transform-origin(87.82, 39.68); }
  .spinner-dot-6 { @include spinner-dot-transform-origin(108.72, 68.03); }
  .spinner-dot-7 { @include spinner-dot-transform-origin(129.61, 96.38); }
  .spinner-dot-8 { @include spinner-dot-transform-origin(150.5, 68.03); }
  .spinner-dot-9 { @include spinner-dot-transform-origin(171.4, 39.68); }
  .spinner-dot-10 { @include spinner-dot-transform-origin(129.61, 39.68); }
  .spinner-dot-11 { @include spinner-dot-transform-origin(66.93, 11.34); }
  .spinner-dot-12 { @include spinner-dot-transform-origin(150.5, 11.34); }
  .spinner-dot-13 { @include spinner-dot-transform-origin(108.72, 11.34); }
  .spinner-dot-14 { @include spinner-dot-transform-origin(46.04, 39.68); }
  .spinner-dot-15 { @include spinner-dot-transform-origin(25.15, 11.34); }


  // Use CSS spinner in browsers where svg animation is not supported (IE9, IE10, IE11)
  &:before {
    $spinner-size: 30px;

    @include animation(rotate360 600ms linear infinite);
    content: '';
    display: inline-block;
    width: $spinner-size;
    height: $spinner-size;
    border-radius: 50%;
    border: 2px solid var(--c-text-main);
    border-right-color: $c-fade-light-00;
  }

  // Overwrite IE specific CSS in modern browsers
  // sass-lint:disable-block no-misspelled-properties
  @supports (animation: spinner) and (not(-ms-ime-align: auto)) {
    .spinner {
      display: block;
    }

    &:before {
      content: none !important; // Hide IE spinner
    }
  }
}

// Style

[data-am-spinner~='inline'] {
  $spinner-size: 1em;

  &:before {
    width: $spinner-size;
    height: $spinner-size;
  }

  .spinner {
    @include animation(rotate360 600ms linear infinite);
    width: $spinner-size;
    height: $spinner-size;
    border-radius: 50%;
    border: 2px solid var(--c-text-main);
    border-bottom-color: $c-fade-light-00;
  }

  // Overwrite IE specific CSS in modern browsers
  // sass-lint:disable-block no-misspelled-properties
  @supports (animation: spinner) and (not(-ms-ime-align: auto)) {
    .spinner {
      display: inline-block;
    }
  }
}

[data-am-spinner~='inline-block'] {
  $spinner-size: 1em;

  &:before {
    width: $spinner-size;
    height: $spinner-size;
  }

  .spinner {
    @include animation(rotate360 600ms linear infinite);
    width: $spinner-size;
    height: $spinner-size;
    border-radius: 50%;
    border: 2px solid var(--c-text-main);
    border-bottom-color: $c-fade-light-00;
  }

  // Overwrite IE specific CSS in modern browsers
  // sass-lint:disable-block no-misspelled-properties
  @supports (animation: spinner) and (not(-ms-ime-align: auto)) {
    .spinner {
      display: block;
    }
  }
}

[data-am-spinner~='block'] {
  padding-top: $gutter;
  padding-bottom: $gutter;

  .spinner,
  &:before {
    margin-left: auto;
    margin-right: auto;
  }

  &:before {
    display: block;
  }

  // Overwrite IE specific CSS in modern browsers
  // sass-lint:disable-block no-misspelled-properties
  @supports (animation: spinner) and (not(-ms-ime-align: auto)) {
    .spinner {
      display: block;
    }
  }
}

[data-am-spinner~='element'] {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 70;
  background-color: $c-fade-light-80;

  .spinner,
  &:before {
    @include transform(translate(-50%, -50%));
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

[data-am-spinner~='overlay'] {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 70;
  background-color: $c-fade-light-90;

  .spinner,
  &:before {
    @include transform(translate(-50%, -50%));
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

// Color

[data-am-spinner~='inverted'] {
  &:before {
    border-color: var(--c-bg-main);
  }

  .spinner {
    fill: var(--c-bg-main);
  }

  &[data-am-spinner~='inline'] {
    .spinner {
      border-color: var(--c-text-media);
      border-bottom-color: $c-fade-light-00;
    }
  }
}

[data-am-spinner~='current-color'] {
  &:before {
    border-color: currentColor;
  }

  .spinner {
    fill: currentColor;
  }

  &[data-am-spinner~='inline'] {
    .spinner {
      border-color: currentColor;
      border-bottom-color: $c-fade-light-00;
    }
  }
}
