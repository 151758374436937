[data-wx-overflowgrid] {
  .overflow-grid-item {
    width: 50%;
  }
}

// Small
// This modifiers displays smaller cards on smaller screens.

[data-wx-overflowgrid~='overflow-small'] {
  .overflow-grid-item {
    width: 33.333%;
  }
}

[data-wx-overflowgrid~='full-width-mobile'] {
  .overflow-grid-item {
    width: auto;
    //min-width: 33.333%;
  }
}
