[data-wx-filterpanel] {
  .filter-panel-header-btn {
    &:hover {
      color: $c-primary;
    }

    &.is-action {
      .filter-panel-header-btn-icon {
        @include transform-origin(50% 50%);
        transition: transform 150ms ease;
      }

      &:hover {
        color: $c-action;

        .filter-panel-header-btn-label {
          color: var(--c-text-main);
        }

        .filter-panel-header-btn-icon {
          @include transform(scale(1.2));
        }
      }
    }
  }
}
