@use "sass:math";

[data-wx-inputsearch] {
  $input-search-height: $g-input-search-md;
  $input-seach-spacing: 5px;

  position: relative;
  border-radius: math.div($input-search-height,2);

  &.foldout-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  %input-search-util-base-md {
    $input-search-util-size: ($input-search-height - ($input-seach-spacing * 2));
    width: $input-search-util-size;
    height: $input-search-util-size;
    line-height: $input-search-util-size;
  }

  // Icon

  .input-search-icon {
    @extend %input-search-util-base-md;
  }

  // Input

  .input-search-input {
    height: $input-search-height;
    line-height: $input-search-height;
  }

  // Action

  .input-search-action {
    @extend %input-search-util-base-md;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        inset: 1px;
        background-color: var(--c-overlay);
        border-radius: 50%;
        mix-blend-mode: soft-light;
        opacity: 0;
        transition: opacity 200ms ease;
    }

    &:enabled:hover {
        text-decoration: none;

        &::before {
            opacity: 1;
        }
    }
  }

  .input-search-clear {
    width: $input-search-height;
    height: $input-search-height;

    &:hover {
      &:before {
        color: var(--c-text-main);
      }
    }
  }

  // Foldout

  .input-search-query-suggestion-action {
    &:hover {
      background-color: $c-accent;
    }
  }
}

[data-wx-inputsearch~='thin'] {
  $input-search-height: 40px;
  $input-seach-spacing: 5px;

  border-radius: math.div($input-search-height,2);

  %input-search-util-base-md {
    $input-search-util-size: ($input-search-height - ($input-seach-spacing * 2));
    width: $input-search-util-size;
    height: $input-search-util-size;
    line-height: $input-search-util-size;
  }

  // Icon

  .input-search-icon {
    @extend %input-search-util-base-md;
  }

  // Input

  .input-search-input {
    height: $input-search-height;
    line-height: $input-search-height;
  }

  // Action

  .input-search-action {
    @extend %input-search-util-base-md;
  }

  .input-search-clear {
    width: $input-search-height;
    height: $input-search-height;
  }
}
