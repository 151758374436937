[data-am-switch] {
  .checkbox {
    &:focus {
      // sass-lint:disable-block nesting-depth
      + .switch {
        &:before {
          box-shadow: $g-box-shadow-big;
        }
      }
    }
  }
}
