@use "sass:math";

@mixin create-select {
  $select-offset: 25px; // Magic number

  [data-am-select] {
    position: relative;
    overflow: hidden;
    z-index: 0;
    background-color: var(--c-bg-main);
    border: 1px solid var(--c-border-main);
    border-radius: $g-border-radius-small;

    &.is-set {
      border-color: $c-primary;
    }

    &.is-disabled {
      background-color: $c-accent;

      .select {
        color: var(--c-text-muted);
      }
    }

    &:before {
      content: '\f107';
      font-family: 'icons';
      position: absolute;
      top: 50%;
      height: 1em;
      line-height: 1em;
      font-size: 0.875rem;
      margin-top: -0.5em;
      right: $gutter-half;
      z-index: -1;
      transition: color 200ms ease;
    }

    .select {
      @include font-regular;
      position: relative;
      -webkit-appearance: none;
      border: 0;
      padding: 0;
      padding-left: $gutter-half;
      color: var(--c-text-main);
      border-radius: 0;
      width: calc(100% + #{$select-offset});
      background-color: $c-fade-light-00;
      height: ($g-input-height - 2px);
      font-size: 0.875rem;
      cursor: pointer;
      z-index: 1;

      &::-ms-expand {
        display: none;
      }

      &:focus {
        outline: none;
      }
    }
  }

  [data-am-select~='box-shadow'] {
    border-color: var(--c-bg-main);
    box-shadow: $g-box-shadow-darker;
  }

  [data-am-select~='simple'] {
    display: inline-block;
    vertical-align: middle;
    padding-right: $gutter;
    background-color: $c-fade-light-00;
    border: 0;
    border-radius: 0;

    &:before {
      color: $c-primary;
      right: 0;
    }

    .select {
      $g-input-height: 20px;

      @include font-medium;
      width: calc(100% + 38px); // 38 is a magic number to hide the default arrow button
      height: $g-input-height;
      line-height: $g-input-height;
      padding: 0;
      color: $c-primary;
    }
  }

  [data-am-select~='inline'] {
    display: inline-block;
    vertical-align: bottom;
    white-space: nowrap;

    // Add additional spacing to hide default select arrow
    &:after {
      content: '';
      display: inline-block;
      width: ($select-offset + math.div($gutter-half, 2));
    }
  }

  [data-am-select~='thick'] {
    .select {
      height: ($g-input-height-thick - 2px);
    }
  }

  [data-am-select~='thin'] {
    .select {
      height: ($g-input-height-thin - 2px);
    }
  }
}
@include create-select;
