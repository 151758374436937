[data-am-spinner] {
  $spinner-size: 60px;

  .spinner {
    width: $spinner-size;
  }
}

[data-am-spinner~='inline'],
[data-am-spinner~='inline-block'] {
  $spinner-size: 1em;

  .spinner {
    width: $spinner-size;
    height: $spinner-size;
  }
}