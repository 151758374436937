[data-wx-submenu] {
  .sub-menu-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .sub-menu-item {
    & + .sub-menu-item {
      margin-top: gutter(2);
    }
  }

  .sub-menu-preamble {
    margin-top: gutter(0.75);
    color: var(--c-text-muted);
  }
}
