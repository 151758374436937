@use "sass:math";
@use "sass:selector";

// TODO: Remove .btn-group

.btn-group {
  @include flex-display;
  @include flex-wrap(wrap);
  margin-top: gutter(-0.75);

  [data-wx-button] {
    margin-top: gutter(0.75);

    &:not(:last-of-type) {
      margin-right: gutter(0.75);
    }
  }
}

[data-wx-button] {
  $button-height: 48px;

  @include inline-flex-display;
  @include align-items(center);
  @include transform-origin(50% 50%);
  @include font-medium;
  position: relative;
  padding: gutter(0.75) gutter(2);
  min-height: $button-height;
  border: 0;
  border-radius: math.div($button-height, 2);
  line-height: 1.15;
  font-size: 0.875rem;
  text-align: center;
  -webkit-tap-highlight-color: $c-fade-dark-00;
  transition: transform 200ms ease;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    inset: 1px;
    background-color: var(--c-overlay);
    border-radius: math.div($button-height, 2);
    mix-blend-mode: soft-light;
    opacity: 0;
    transition: opacity 200ms ease;
    }

    &:enabled:hover {
        text-decoration: none;

        &::before {
            opacity: 1;
        }
      }

  &:active {
    @include transform(scale(0.97));
  }

  &[disabled],
  &.is-disabled {
    opacity: 0.37;
    cursor: not-allowed;
  }

  .button-icon,
  .button-text {
    display: inline-block;
    vertical-align: middle;
  }

  .button-icon {
    font-size: 1rem;

    & + .button-text {
      margin-left: gutter(0.5);
    }
  }

  .button-text {
    & + .button-icon {
      margin-left: gutter(0.5);
    }
  }

  @at-root {
    #{selector.unify(&, a)} {
      @include inline-flex-display;
      @include align-items(center);
      @include justify-content(center);
    }
  }
}

// Color

[data-wx-button~='primary'] {
  background-color: $c-primary;
  color: $c-text-on-primary;
}

[data-wx-button~='primary-light'] {
  background-color: $c-primary-light;
  color: $c-primary;
}

[data-wx-button~='action'] {
  background-color: $c-action;
  color: $c-text-on-action;
}

[data-wx-button~='action-light'] {
  background-color: $c-action-light;
  color: $c-text-on-action-light;
}

[data-wx-button~='transparent-dark'] {
  background-color: $c-fade-dark-60;
  color: var(--c-text-media);
  border: 2px solid $c-fade-light-10;
  background-clip: padding-box;
}

[data-wx-button~='light'] {
  background-color: var(--c-bg-main);
  color: $c-action;
  box-shadow: $g-x-box-shadow;
}

// Size

[data-wx-button~='thin'] {
  $button-height-thin: 36px;

  min-height: $button-height-thin;
  padding: gutter(0.5) gutter(1);
  border-radius: math.div($button-height-thin, 2);
  font-size: 0.875rem;
}

[data-wx-button~='thin-wide'] {
  $button-height-thin: 36px;

  min-height: $button-height-thin;
  padding: gutter(0.5) gutter(2);
  border-radius: math.div($button-height-thin, 2);
  font-size: 0.875rem;
}

[data-wx-button~='medium'] {
  $button-height-medium: 40px;

  min-height: $button-height-medium;
  padding: gutter(0.5) gutter(1.5);
  border-radius: math.div($button-height-medium, 2);
}

[data-wx-button~='narrow'] {
  $button-height-thin: 32px;

  padding-left: gutter(1);
  padding-right: gutter(1);
}

[data-wx-button~='full-width'] {
  display: block;
  width: 100%;
}
