[data-am-embed] {
  position: relative;
  width: 100% !important;
  height: 0;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

[data-am-embed~='has-margin'] {
  margin-top: $gutter-double;
  margin-bottom: $gutter-double;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

[data-am-embed~='contain'] {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    min-height: 1px;
    background-color: transparent;
  }
}
