@use "sass:math";

[data-wx-hero] {
  .hero-background-image {
    max-height: 70vh;
  }

  .hero-content {
    padding-top: ($g-header-height-mobile + gutter(1.5));
    padding-bottom: gutter(1.5);
    padding-left: $grid-container-gutter-lt-md;
    padding-right: $grid-container-gutter-lt-md;
  }

  .hero-content-body {
    @include flex-display;
    @include flex-direction(column);
    @include justify-content(flex-end);
    @include align-items(stretch);
    @include flex(1 1 auto);
  }

  .hero-content-header {
    padding-bottom: gutter(1.5);
  }

  .hero-content-footer {
    padding-top: gutter(1.5);
  }

  .hero-content-actions {
    @include flex-display;
    @include justify-content(space-between);
  }
}

// Removes min-height from hero and let the content decide height
[data-wx-hero~='collapse'] {
  $hero-collapsed-gutter: gutter(1.5);

  .hero-content {
    padding-top: (($g-header-height-mobile - math.div(($g-header-height-mobile - $g-input-search-lt-md), 2)) + $hero-collapsed-gutter);
    padding-bottom: $hero-collapsed-gutter;
  }
}

// Places text in the center
[data-wx-hero~='text-center'] {
  .hero-content-header,
  .hero-content-body,
  .hero-content-footer {
    @include align-items(center);
  }
}

// Placed the content at the bottom of the hero
[data-wx-hero~='content-bottom'] {
  .hero-content {
    @include justify-content(flex-end);
  }
}

[data-wx-hero~='even-padding'] {
  .hero-content {
    padding: gutter(1.5) $grid-container-gutter-lt-md;
  }
}

// Placed the content at the center of the hero
[data-wx-hero~='content-center'] {
  .hero-content-body {
    @include justify-content(center);
  }
}

// Moves content below the hero instead of inside it
[data-wx-hero~='content-below'] {
  .hero-content {
    padding-top: 0;
  }
}