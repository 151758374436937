[data-wx-page] {
  margin-top: gutter(2);
  margin-bottom: gutter(2);

  .page-section {
    &.page-section-accent {
      background-color: $c-accent;
      padding: gutter(2) 0;
    }

    &.page-section-action-extra-light {
      background-color: $c-action-light;
      padding: gutter(3.5) 0;
    }
  }

  .page-section {
    & + .page-section {
      margin-top: gutter(2);

      &.page-section-thin {
        margin-top: gutter(1.5);
      }
    }

    &.page-section-thin {
      & + .page-section {
        margin-top: gutter(1.5);
      }
    }
  }

  .page-section-sticky-bottom {
    @include position-sticky;
    bottom: gutter(1);
    z-index: 10;
  }
}

[data-wx-page~="no-margin-bottom"] {
  margin-bottom: 0;
}
