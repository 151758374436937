[data-wx-uploadmedia] {
  $preview-width: 20%;

  &.has-upload {
    .upload-btn-container {
      width: $preview-width;
    }
  }

  .upload-btn-text, .upload-btn-text-action {
    display: inline-block;
    vertical-align: middle;
  }

  .upload-btn {
    padding: 48px 16px;
  }

  .upload-preview-item {
    width: $preview-width;
  }

  .upload-preview-retry-btn {
    @include transform(translate(-50%, 50%));
    bottom: 50%;
  }
}
