.swipe {
  position: relative;
  overflow: hidden;
  visibility: hidden;
}

.swipe-wrap {
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  font-size: 0;

  > * {
    font-size: 1rem;
  }
}

.swipe-item {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;

  // Fixes bug when there is two images
  &[data-cloned] {
    display: none;
  }
}
