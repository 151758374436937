// This modifier shows the other slides outside of the slick container. Should be used on the product page
[data-am-slick~='overflow-visible'] {
  .slick-list {
    overflow: visible;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100vw;
      background-color: $c-fade-dark-60;
      z-index: 1;
    }

    &:before {
      right: 100%;
    }

    &:after {
      left: 100%;
    }
  }
}
