[data-wx-filterchecklist] {
  .filter-check-list-option-label {
    font-size: 0.875rem;
  }

  .filter-check-list-amout {
    font-size: 0.75rem;
  }

  // Multi

  .filter-check-list-option-multi-btn {
    $option-multi-btn-size: 20px;

    width: $option-multi-btn-size;
    height: $option-multi-btn-size;
    line-height: $option-multi-btn-size;
  }

  // Extend

  .filter-check-list-extend-btn {
    font-size: 0.875rem;
  }
}
