[data-wx-filterpanel] {
  $filter-panel-header-height: gutter(3.5);

  @include flex-display;
  @include flex-direction(column);
  height: 100%;
  background-color: var(--c-bg-main);

  // Header

  .filter-panel-header {
    @include flex-shrink(0);
    position: relative;
    height: $filter-panel-header-height;
  }

  .filter-panel-header-label {
    @include transform(translate(-50%, -50%));
    @include font-bold;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .filter-panel-header-action {
    position: absolute;
    top: 0;
    bottom: 0;

    &.is-left {
      left: 0;
    }

    &.is-right {
      right: 0;
    }
  }

  .filter-panel-header-btn {
    display: block;
    height: $filter-panel-header-height;
    padding: 0 gutter(1.5);
    font-size: 0;

    &.is-action {
      color: $c-action;
    }

    > * {
      font-size: 1rem;
    }
  }

  .filter-panel-header-btn-icon {
    display: inline-block;
    vertical-align: middle;
    font-size: 1rem;

    &.is-big {
      font-size: 1.4rem;
    }

    [class^='icon-'],
    [class*=' icon-'] {
      display: block;
      line-height: 1;
    }

    & + .filter-panel-header-btn-label {
      margin-left: gutter(0.5);
    }
  }

  .filter-panel-header-btn-label {
    @include font-medium;
    display: inline-block;
    vertical-align: middle;
    font-size: 0.75rem;
  }

  // Body

  .filter-panel-body {
    @include flex(1 1 auto);
    @include flex-display;
    @include flex-direction(column);
    min-height: 1px;
  }

  .filter-panel-body-header-section,
  .filter-panel-body-footer {
    pointer-events: none;

    > * {
      pointer-events: auto;
    }
  }

  // Body Header

  .filter-panel-body-header-section {
    @include flex-shrink(0);
    position: relative;
    padding: gutter(1) gutter(1.5);
    z-index: 1;

    & + .filter-panel-body-header-section {
      padding-top: 0;
    }
  }

  // Body Content

  .filter-panel-body-content {
    @include flex(1 1 auto);
    overflow: auto;
    -webkit-overflow-scrolling: touch; // sass-lint:disable-line no-misspelled-properties
  }

  .filter-panel-body-content-wrapper {
    padding: gutter(1) gutter(1.5);
  }

  // Body Footer

  .filter-panel-body-footer {
    @include flex-shrink(0);
    position: relative;
    padding: gutter(1) gutter(1.5);
    z-index: 1;
  }

  .filter-panel-body-footer-action-container {
    @include flex-display;
  }

  .filter-panel-body-footer-action {
    @include flex(1 0 auto);
  }

  // Tabs

  .filter-panel-tab-list {
    @include flex-display;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: $c-accent;
  }

  .filter-panel-tab-item {
    @include flex(1 0 0);

    &.is-active {
      .filter-panel-tab-action {
        color: var(--c-text-main);

        &:after {
          display: block;
        }
      }
    }
  }

  .filter-panel-tab-action {
    @include font-medium;
    position: relative;
    display: block;
    width: 100%;
    padding: gutter(1) gutter(0.75);
    font-size: 0.875rem;
    color: var(--c-text-muted);

    &:after {
      content: '';
      display: none;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: gutter(0.25);
      background-color: $c-action;
    }

    &:hover {
      color: var(--c-text-main);
    }
  }
}

[data-wx-filterpanel~='list'] {
  // Body Content

  .filter-panel-body-content {
    // Make actions and search appear floating
    margin-bottom: -80px; // Magic number: Body Footer height

    // Requires to be a block in order to work in Firefox
    &:after {
      content: '';
      display: block;
      height: 80px;
    }

    &.has-search {
      margin-top: -72px; // Magic number: Search section height
      padding-top: 72px; // Magic number: Search section height
    }
  }
}

[data-wx-filterpanel~='map'] {
  .filter-panel-header {
    box-shadow: 0 4px 2px -2px $c-fade-dark-20;
    z-index: 1;
  }

  .filter-panel-body-footer {
    padding-top: 0;
  }
}
