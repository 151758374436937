@use "sass:math";

[data-am-rangeslider] {
  $bar-height: 4px;
  $handle-size: 25px;
  $histogram-height: 70px;
  $histogram-spacing: 2px;

  position: relative;

  .range-slider {
    padding-left: math.div($handle-size, 2);
    padding-right: math.div($handle-size, 2);
    padding-bottom: math.div(($handle-size - $bar-height), 2);
  }

  // RC Slider

  .rc-slider {
    position: relative;
    height: $bar-height;
  }

  .rc-slider-rail {
    position: absolute;
    left: 0;
    width: calc(100% + #{math.div($handle-size, 2)});
    background-color: #e2e2e2;
    height: $bar-height;
    border-radius: math.div($bar-height, 2);
  }

  .rc-slider-track {
    position: absolute;
    left: 0;
    height: $bar-height;
    border-radius: math.div($bar-height, 2);
    background-color: $c-primary;
    margin-left: math.div($handle-size, -2);
    margin-right: math.div($handle-size, -2);
  }

  .rc-slider-handle {
    @include unselectable;
    @include transform-origin(50% 50%);
    position: absolute;
    top: 0;
    width: $handle-size;
    height: $handle-size;
    border-radius: 50%;
    margin-top: math.div(($handle-size - $bar-height), -2);
    margin-left: math.div($handle-size, -2);
    background-color: var(--c-bg-main);
    border: 1px solid $c-primary;
    cursor: move; // sass-lint:disable-line no-duplicate-properties
    cursor: ew-resize; // sass-lint:disable-line no-duplicate-properties
    cursor: -webkit-grab; // sass-lint:disable-line no-duplicate-properties
    cursor: grab; // sass-lint:disable-line no-duplicate-properties
    transition: transform 200ms ease;

    &:focus {
      outline: none;
    }

    &:active {
      @include transform(scale(1.3));
    }
  }

  .rc-slider-step,
  .rc-slider-mark {
    display: block;
  }

  // Histogram

  .histogram-container {
    margin-left: -($histogram-spacing);
    margin-right: -($histogram-spacing);

    + .bar,
    ~ .bar {
      margin-top: 0;
    }
  }

  .histogram-chart {
    display: table;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: $histogram-spacing 0;
    width: 100%;
    height: $histogram-height;
    overflow: hidden;
    margin-bottom: -1px; // Prevents half-pixel browser bugs to be visible
  }

  .histogram-column {
    display: table-cell;
    vertical-align: bottom;
    height: 100%;
  }

  .column-fill {
    position: relative;
    height: 0;
    background-color: $c-accent;

    &.is-active {
      background-color: $c-primary-light;
    }
  }

  // Range Input

  .range-input-section {
    display: table;
    width: 100%;
    margin-bottom: $gutter;
  }

  .range-input-container,
  .range-input-separator {
    display: table-cell;
    vertical-align: middle;
  }

  .range-input {
    $input-height: 40px;

    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: var(--c-bg-main);
    border: 1px solid var(--c-border-main);
    border-radius: $g-border-radius-small;
    height: $input-height;
    padding-left: $gutter-half;
    padding-right: $gutter-half;
    font-size: 16px; // Set font size in px to prevent zoom in on iOS

    &:focus {
      outline: none;
    }

    &[disabled] {
      opacity: 0.6;
    }
  }

  .range-input-separator {
    width: 1px;

    &:before {
      content: '–';
      padding-left: $gutter-half;
      padding-right: $gutter-half;
    }
  }

  // Range Span

  .range-span {
    @include clearfix;
  }

  .range-span-low,
  .range-span-high {
    @include unselectable;
    font-size: 0.875rem;
    color: $c-fade-dark-50;
    cursor: default;
  }

  .range-span-low {
    float: left;
  }

  .range-span-high {
    float: right;
  }
}

[data-am-rangeslider~='simple'] {
  $bar-height: 4px;
  $handle-size: 25px;

  .range-slider {
    padding-top: math.div(($handle-size - $bar-height), 2);
  }

  .rc-slider-track {
    margin-left: math.div($handle-size, -2);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
