[data-wx-actiongroup] {
  .container & {
    margin: -($grid-container-gutter-lt-md);
  }

  .action-group-list {
    padding: $grid-container-gutter-lt-md;
    overflow: auto;
    transform: none !important;
    }

  .action-group-nav {
    display: none !important; // !important is required since display state will be toggled with javascript
  }
}
