[data-wx-articlecard] {
  position: relative;
  height: 0;
  padding-bottom: 88%;
  box-shadow: $g-x-box-shadow;

  .article-card-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60%;
  }

  .article-card-body {
    @include flex-display;
    @include flex-direction(column);
    @include justify-content(flex-end);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $c-complement-dark;
    min-height: 40%;
    padding: gutter(1.5);

    &:before {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
      height: 90px;
      background-image: linear-gradient($c-fade-dark-00, $c-complement-dark);
    }
  }
}

[data-wx-articlecard~='video'] {
  padding-bottom: 58%;

  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    top: auto;
    bottom: 0;
    width: 50%;
    height: 100%;
    background-image: linear-gradient(225deg, $c-fade-dark-00 50%, $c-complement-dark 100%);
  }

  .article-card-image {
    height: 100%;
    z-index: -1;
  }

  .article-card-action {
    position: absolute;
    top: calc(50% - #{gutter(5.5)});
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .article-card-body {
    $bg-height: gutter(11);

    z-index: 1;
    padding: gutter(2.5);
    background-color: transparent;

    &:before {
      z-index: 0;
      bottom: 0;
      height: calc(100% - #{$bg-height});
      background-image: none;
      background-color: $c-complement-dark;
    }

    &:after {
      content: '';
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: $bg-height;
      background-image: linear-gradient($c-fade-dark-00, $c-complement-dark);
      z-index: -1;
    }
  }

  .article-card-pre-heading,
  .article-card-heading,
  .article-card-preamble,
  .article-card-content {
    position: relative;
    z-index: 2;
  }

  .article-card-heading {
    font-size: 2.0625rem;

    ~ .article-card-content {
      margin-top: gutter(2);
    }
  }

  .article-card-content {
    width: 80%;
  }
}

[data-wx-articlecard~='standalone'] {
  width: 100%;
  max-width: 1064px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0;
  background-color: var(--c-bg-main);
  box-shadow: none;

  &:before {
    content: none;
  }

  .article-card-image {
    left: 50%;
    height: 100%;
  }

  .article-card-body {
    @include justify-content(center);
    position: relative;
    padding: gutter(2);
    padding-left: 0;
    width: 50%;
    min-height: 295px;
    background-color: transparent;
    color: var(--c-text-main);
    text-align: left;

    &:before {
      content: none;
    }
  }

  .article-card-body-action {
    display: none;
  }

  .article-card-heading {
    font-size: 1.375rem;
  }

  .article-card-preamble {
    display: block;
  }
}
