[data-wx-extendbox] {
  background-color: var(--c-bg-main);
  border: 1px solid var(--c-border-main);

  &.is-open {
    .extend-box-body {
      display: block;
    }

    .extend-box-footer-btn-icon {
      @include transform(rotateX(180deg));
    }
  }

  .extend-box-header {
    padding: gutter(1);
  }

  .extend-box-body {
    display: none;
    padding: gutter(1);
    border-top: 1px solid var(--c-border-main);
  }

  .extend-box-footer {
    border-top: 1px solid var(--c-border-main);
  }

  .extend-box-footer-btn {
    display: block;
    width: 100%;
    padding: gutter(0.625) gutter(1);
    color: $c-action;
    font-size: 0;

    > * {
      font-size: 1rem;
    }
  }

  .extend-box-footer-btn-label,
  .extend-box-footer-btn-icon {
    display: inline-block;
    vertical-align: middle;
  }

  .extend-box-footer-btn-label {
    @include font-medium;
    font-size: 0.875rem;
  }

  .extend-box-footer-btn-icon {
    margin-left: gutter(0.25);
    font-size: 1rem;

    [class^='icon-'],
    [class*=' icon-'] {
      &:before {
        display: block;
        line-height: 1;
      }
    }
  }
}
