[data-am-map] {
  position: relative;
  min-height: 500px;

  .map {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $c-accent;
  }

  // Navigation

  .map-nav {
    position: relative;
    padding-top: $gutter;
    padding: $gutter;
    width: 50%;
    max-width: 420px;
  }

  .map-nav-content {
    background-color: var(--c-bg-main);
    box-shadow: $g-box-shadow-big;
  }

  .map-nav-header {
    background-color: $c-accent;
    padding: $gutter-half;
  }

  .map-nav-heading {
    padding: $gutter-half;
  }

  .body-section {
    padding: $gutter;
  }

  // Location Input

  $location-input-height: 60px;

  .location {
    background-color: var(--c-bg-main);
    box-shadow: $g-box-shadow;
  }

  .location-input-container {
    overflow: hidden;
  }

  .location-input {
    width: 100%;
    height: $location-input-height;
    border: 0;
    padding: 0 $gutter-half;
    margin: 0;
    background-color: $c-fade-light-00;
    font-size: 16px; // Set font size in px to prevent zoom in on iOS

    &:focus {
      outline: none;
    }
  }

  .location-btn {
    float: right;
    height: $location-input-height;
    padding-left: $gutter-half;
    padding-right: $gutter-half;
    color: var(--c-text-muted);
    transition: color 200ms ease;
    font-size: 1.4rem;
    line-height: 1;

    &:hover {
      color: var(--c-text-main);
    }
  }

  // Body

  .map-nav-list {
    counter-reset: mapList; // Counter to be displayed in badge
    box-shadow: 0 -4px 4px -4px $c-fade-dark-05; // Box shadow top
  }

  .map-nav-item {
    counter-increment: mapList;
    border-top: 1px solid var(--c-border-main);

    &:first-child {
      border-top: 0;
    }

    &.is-open {
      .item-header {
        &:after {
          content: '\f106';
        }
      }
    }
  }

  .item-header {
    position: relative;
    display: block;
    width: 100%;
    padding: $gutter-half $gutter;
    background-color: var(--c-bg-main);
    text-align: left;

    &:after {
      @include transform(translateY(-50%));
      content: '\f107';
      font-family: 'icons';
      position: absolute;
      top: 50%;
      right: $gutter-half;
      color: var(--c-text-muted);
    }
  }

  .item-header-content {
    display: table;
    width: 100%;
  }

  .item-header-badge,
  .item-header-text {
    display: table-cell;
    vertical-align: middle;
  }

  .item-header-badge {
    width: 1px;
  }

  .badge {
    $badge-size: 30px;

    position: relative;
    display: block;
    width: $badge-size;
    height: $badge-size;
    border-radius: 50%;
    background-color: $c-action;
    color: $c-text-on-action;

    &:before {
      @include transform(translate(-50%, -50%));
      @include font-medium;
      content: counter(mapList);
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 0.75rem;
    }
  }

  .item-header-text {
    padding-left: $gutter-half;
    padding-right: $gutter-half;
  }

  .item-header-heading {
    @include font-medium;
    display: block;
  }

  .item-header-subheading {
    display: block;
    font-size: 0.875em;
    color: var(--c-text-muted);
  }

  .item-body {
    height: 0;
    overflow: hidden;
    transition: height 150ms ease;
  }

  .item-body-inner {
    padding: $gutter-half;
    background-color: $c-accent;
    border-top: 1px solid var(--c-border-main);
  }
}
