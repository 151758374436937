[data-am-lightbox] {
  $lightbox-max-width: 1440px;
  $lightbox-nav-width: 200px;

  @include animation(fadeIn 400ms ease);
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 100%;
  background-color: var(--c-bg-main);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; // sass-lint:disable-line no-misspelled-properties

  &.is-open {
    display: block;
  }

  .lightbox {
    @include flex-display;
    @include flex-direction(row-reverse);
    position: relative;
    width: 100%;
    height: 100%;
    max-width: $lightbox-max-width;
    margin-left: auto;
    margin-right: auto;
    padding-left: $gutter-half;
    padding-right: $gutter-half;
  }

  .lightbox-media-container,
  .lightbox-nav-container {
    padding: $gutter-half;
  }

  .lightbox-media-container {
    overflow-y: auto;
    overflow-x: hidden;
    width: calc(100% - #{$lightbox-nav-width});
  }

  .lightbox-nav-container {
    @include flex-display;
    @include flex-direction(column);
    width: $lightbox-nav-width;
    height: 100%;
    overflow: auto;
  }

  // Item

  .lightbox-item {
    position: relative;
    margin-bottom: $gutter-half;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .lightbox-heading {
    @include font-medium;
    margin-bottom: $gutter;
    margin-top: $gutter-double;
    font-size: 1.125rem;
  }

  .lightbox-item-action {
    @include transform(translateX(-50%));
    position: absolute;
    bottom: gutter(1);
    left: 50%;
  }

  .lightbox-item-360-badge {
    @include transform(translateX(-50%));
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    height: 100px;
    pointer-events: none;
  }

  // Navigation

  .lightbox-nav-upper {
    @include flex(1 0 auto);
    margin-bottom: $gutter;
  }

  .lightbox-nav-lower {
    margin-top: auto;
  }

  .lightbox-nav-item {
    &:first-child {
      .lightbox-nav-action {
        padding-top: 0;
      }
    }

    &:last-child {
      .lightbox-nav-action {
        padding-bottom: 0;
      }
    }

    &.is-current {
      .lightbox-nav-action {
        color: $c-action;
      }
    }
  }

  .lightbox-nav-action {
    @include font-medium;
    display: block;
    width: 100%;
    text-align: left;
    color: var(--c-text-main);
    padding: $gutter-half 0;
  }

  // Close Button

  .lightbox-close-btn-container {
    text-align: right;
    margin-bottom: $gutter;
  }

  .lightbox-close-btn {
    white-space: nowrap;

    &:enabled:hover {
      .lightbox-close-btn-icon {
        &::before {
            opacity: 1;
        }
      }
    }
  }

  .lightbox-close-btn-title {
    display: inline-block;
    vertical-align: middle;
    font-size: 0.875rem;
    transition: color 200ms ease;
  }

  .lightbox-close-btn-icon {
    $icon-size: 34px;

    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: $icon-size;
    height: $icon-size;
    border-radius: 50%;
    background-color: $c-action;
    color: $c-text-on-primary;
    margin-left: $gutter-half;
    transition: background-color 200ms ease;

    [class^='icon-'],
    [class*=' icon-'] {
      @include transform(translate(-50%, -50%));
      position: absolute;
      top: 50%;
      left: 50%;

      &:before {
        display: block;
        line-height: 1;
      }
    }

    &::before {
        content: "";
        position: absolute;
        inset: 1px;
        background-color: var(--c-overlay);
        border-radius: 50%;
        mix-blend-mode: soft-light;
        opacity: 0;
        transition: opacity 200ms ease;
    }
  }

  // Media: Image

  .lightbox-image {
    display: block;
    max-width: 100%;
    //max-height: 100vh;
    margin-left: auto;
    margin-right: auto;
  }

  // Media: Video

  .lightbox-video {
    width: 100%;
  }

  .lightbox-spehere {
    display: block;
    width: 100%;
    aspect-ratio: 3 / 2;
    object-fit: cover;
  }
}
