[data-wx-gallerylist] {
  .gallery-item {
    width: 20%;
  }
}

[data-wx-gallerylist="full-width"] {
  .gallery-item {
    width: 100%;
  }
}
