[data-wx-tabs] {
  $tabs-active-border-size: 3px;

  .tab-list {
    @include flex-display;
    @include align-items(center);
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 0;
    white-space: nowrap;

    > * {
      font-size: 1rem;
    }
  }

  .tab-item {
    --_tab-item-spacing: #{gutter(1)};

    display: inline-block;
    vertical-align: bottom;

    &.is-active {
      .tab-action {
        opacity: 1;

        &:after {
          display: block;
        }
      }

      .tab-action-indicator {
        background-color: $c-ui-danger-light;
        color: $c-ui-danger;
      }
    }

    &.separator-right {
        display: flex;
        align-items: center;
        gap: var(--_tab-item-spacing);

        &::after {
            content: "";
            flex-shrink: 0;
            display: block;
            width: 1px;
            height: gutter(1.25);
            background-color: var(--c-border-main);
        }
    }

    & + .tab-item {
      margin-left: var(--_tab-item-spacing);
    }
  }

  .tab-action {
    @include font-medium;
    @include flex-display;
    @include align-items(center);
    gap: gutter(0.25);
    position: relative;
    padding: gutter(0.5) 0;
    font-size: 0.875rem;
    color: var(--c-text-main);
    opacity: 0.5;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: $tabs-active-border-size;
      background-color: $c-action;
      display: none;
    }

    &:hover {
      opacity: 0.75;
    }

    // Style icons placed inside
    svg {
        font-size: gutter(1.5);
        margin: gutter(-0.75) 0; // Add block bleed to icon larger than the text
    }
  }

  .tab-action-label {
    display: block;
  }

  .tab-action-indicator {
    @include font-bold;
    display: block;
    padding: gutter(0.25) gutter(0.5);
    margin-left: gutter(0.5);
    background-color: $c-accent-dark;
    color: var(--c-text-main);
    border-radius: 999px;
    font-size: 0.65rem;
  }
}

[data-wx-tabs~='boxed'] {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: var(--c-border-main);
    z-index: -1;
  }

  .tab-item {
    &.is-active {
      .tab-action {
        color: var(--c-text-main);
      }
    }

    &:not(.show-more).is-hidden {
      display: none;
    }
  }

  .tab-action {
    @include font-medium;
    padding: gutter(0.5) gutter(0.75);
    margin-bottom: gutter(1);
    background-color: $c-accent;
    color: var(--c-text-muted);
    border-radius: 1px;
    opacity: 1;
    font-size: var(--font-size-regular);

    &:after {
      bottom: gutter(-1);
    }

    &:hover {
      opacity: 1;
      color: var(--c-text-main);
    }
  }
}
