@mixin alert-color($alert-color, $alert-color-light, $alert-color-extra-light) {
  background-color: $alert-color-extra-light;

  .alert-icon {
    background-color: $alert-color-light;
    color: $alert-color;
  }
}

[data-wx-alert] {
  --_icon-size: 24px;
  --_text-size: var(--font-size-regular);

  @include flex-display;
  @include alert-color(var(--c-text-muted), $c-accent-dark, $c-accent);
  border-radius: $g-border-radius-small;
  padding: gutter(0.75);

  // Icon

  .alert-icon-section {
    @include flex-shrink(0);
    padding-right: gutter(0.75);
  }

  .alert-icon {
    position: relative;
    display: block;
    width: var(--_icon-size);
    height: var(--_icon-size);
    border-radius: 50%;
    font-size: 1rem;

    > * {
      @include transform(translate(-50%, -50%));
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }

  .icon-cart {
    font-size: 0.75rem; // Custom size on this specific icon
  }

  // Content

  .alert-content {
    @include flex(1 1 auto);
    @include font-light;
    font-size: var(--_text-size);
    margin-top: 1px; // Magic number used to align text to icon
  }

  .alert-heading {
    @include font-medium;
    margin-bottom: gutter(0.5);
  }

  // Close

  .alert-close {
    @include flex-shrink(0);
  }

  .alert-close-btn {
    display: block;
    padding: gutter(0.75);
    margin-top: gutter(-0.75);
    margin-right: gutter(-0.75);
    line-height: 1;
  }
}

// Color

[data-wx-alert~='info'] {
  @include alert-color($c-ui-info, $c-ui-info-medium, $c-ui-info-light);
}

[data-wx-alert~='success'] {
  @include alert-color($c-ui-success, $c-ui-success-medium, $c-ui-success-light);
}

[data-wx-alert~='warning'] {
  @include alert-color($c-ui-warning, $c-ui-warning-medium, $c-ui-warning-light);
}

[data-wx-alert~='danger'] {
  @include alert-color($c-ui-danger, $c-ui-danger-medium, $c-ui-danger-light);
}

// Size

[data-wx-alert~='small'] {
    --_text-size: var(--font-size-small);

  padding: gutter(0.375) gutter(0.75);

  .alert-content {
    margin-top: 1px; // Magic number used to align text to icon
  }
}
