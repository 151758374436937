[data-wx-filtercontainer] {
  $animation-speed: $g-filter-container-animation-speed;

  &.filter-list-is-open {
    .filter-container-section-nav {
      display: block;
    }
  }

  .filter-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .filter-container-section-nav {
    @include animation(filterModalInMobile $animation-speed ease);
    display: none;
    height: 100%;
  }

  .filter-container-section-content {
    height: 100%;
  }

  .filter-container-content {
    @include flex-display;
    @include flex-direction(column);
    @include justify-content(flex-end);
    @include animation(modalInMobile $animation-speed ease);
    position: relative;
    height: 100%;
    pointer-events: none;

    > * {
      pointer-events: auto;
    }
  }
}
