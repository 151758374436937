.hidden-lt-md {
  display: none !important;
}

.overflow-hidden-hard-lt-md {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.text-center-lt-md {
  text-align: center;
}
